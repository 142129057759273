.st_switches_parents {
    margin-top: 50px;
}
.st_switches {
    display: flex;
    position: relative;
    border-bottom: 1px solid black;
}
.st_caret {
    max-width: 303px;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 0px;
    background-color: black;
}

.st_caret.stock {
    left: 0px;
    transition: left 0.8s ease 0s;
}

.st_caret.trends {
    left: 50%;
    transition: left 0.8s ease 0s;
}

.switches_stock, .switches_trends {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: black;
    height: 60px;
    width: 303px;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
    cursor: pointer;
}
.switches_stock.active, .switches_trends.active {
    font-weight: 700;
    transition: font-weight 0.4s ease 0s;
}

@media (max-width: 1024px)  {
    .switches_stock, .switches_trends {
        font-size: 36px;
        width: 200px;
    }
    .st_switches_parents {
        max-width: 400px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .st_caret {
        max-width: 200px;
        height: 2px;
    }
}
@media (max-width: 767px) {

    .st_switches_parents {
        margin-top: 40px;
        max-width: 320px;
    }
    .switches_stock, .switches_trends {
        height: 35px;
        font-size: 22px;
        width: 160px;
    }
    .st_caret {
        max-width: 160px;
    }
}
@media (max-width: 427px) {
    .st_switches_parents {
        margin-top: 30px;
        max-width: 265px;
    }
    .switches_stock, .switches_trends {
        font-size: 20px;
        width: 125px;
    }
    .st_caret {
        max-width: 125px;
    }
}