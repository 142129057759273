.one_cards_contacts_adm {
    width: 45%;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
}
.delete_cars_contacts_relative {
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.delete_cars_contacts {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.delete_cars_contacts::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.delete_cars_contacts::after{
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.left_fields_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.contacts_field_label_name {
    font-weight: 600;
    font-size: 12px;
}
.contacts_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.contacts_field.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
}