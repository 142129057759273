.landry_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}
.landry_title {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;

    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.landry_parrent_wrapper {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
}
.landry_tables {
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
}
.add_new_landry {
    display: flex;
    margin-top: 20px;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #999999;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}
.no_landry {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    font-weight: 600;
    font-size: 29px;
}
.image_drop_landry {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
}
.div_del_landry_img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60px;
    height: 100%;
    cursor: pointer;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
}
.div_del_landry_img > div {
    max-width: 30px;
    width: 100%;
}
.div_del_landry_img:hover {
    background-color: #c9cccf;
}

.image_drop_landry img {
    max-width: 100%;
    max-height: 100%;
    width: 60px;
    height: 40px;
    display: flex;
    object-fit: contain;
}
.images_container_landry {
    max-height: 100px;
}