.stocks-trends__breadcrumbs {
    display: flex;
    flex-wrap: wrap;
}

.stocks-trends__breadcrumbs-item {
    display: flex;
    padding-right: 25px;
    position: relative;
    cursor: pointer;
    color: #000000;
    opacity: 0.8;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
}

.stocks-trends__breadcrumbs-item:first-child {
    margin-left: 0;
}

.stock-trends__header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

}

.stocks-trends__breadcrumbs-item::after {
    content: "";
    height: 1px;
    width: 11px;
    background: #6C757D;
    opacity: 0.7;
    position: absolute;
    right: 7px;
    top: 6px;
    transform: rotate(-70deg);
}

.stocks-trends__breadcrumbs-item:last-child {
    text-decoration-line: none;
    color: #000000;
    padding-right: 0;
    pointer-events: none;
}

.stocks-trends__breadcrumbs-item:last-child::after {
    content: none;
}

.stocks-trends__nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 11px;
}

.stocks-trends__nav-btn {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.stocks-trends__container {
    padding: 0 40px;
}

.stocks-trends__section {
    margin-bottom: 60px;
}

.stocks-trends__main {
    display: flex;
    align-items: center;
    gap: 40px;
}

.stocks-trends__main-img {
    flex-basis: 680px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stocks-trends__main-img img {
    display: block;
    max-width: 100%;
    max-height: 816px;
    width: auto;
    height: auto;
    object-fit: contain;
}

.stocks-trends__main-content {
    flex-basis: 640px;
    flex-grow: 1;
}

.stocks-trends__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
    margin: 0 0 20px;
    max-width: 567px;
}

.stocks-trends__main-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    margin: 0 0 21px;
}

.stocks-trends__main-text:last-child {
    margin-bottom: 0;
}

.stocks-trends__recs {
    display: flex;
    gap: 20px;
    overflow: auto hidden;
    margin-left: -40px;
    margin-right: -40px;
    padding: 0 40px;
    scroll-behavior: smooth;
}

.stocks-trends__recs::-webkit-scrollbar {
    height: 26px;
}

.stocks-trends__recs::-webkit-scrollbar-track {
    background-color: #cecece;
    border-top: 24px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.stocks-trends__recs::-webkit-scrollbar-thumb {
    background: rgb(25, 25, 25);
    border-top: 23px solid #FFFFFF;
}

.stocks-trends__recs-item {
    width: 325px;
    flex-shrink: 0;
    display: block;
    text-decoration: none;
    margin-inline:auto;
}

.stocks-trends__recs-img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 433px;
    object-fit: cover;
    object-position: center;
    max-height: 100%;
    margin-bottom: 14px;
}

.stocks-trends__recs-title {
    margin: 0 0 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    text-align: center;
    text-transform: none;
}

.stocks-trends__recs-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1A1A1A;
    margin: 0;
    text-transform: none;
}

.price_and_oldprice_stock_slider{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.sell_price_stock_slider {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* text-align: center; */
    color: #1A1A1A;
    text-transform: none;
}

.price_stock_slider {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #1A1A1A;
}
.price_stock_slider.old_price {
    text-decoration-line: line-through;
    margin-left: 18px;
    color: #D7D7D7;
    font-weight: 300;
    font-size: 14px;
}

.stocks-trends__gallery {
    display: flex;
    gap: 40px;
    overflow: auto;
    margin-left: -40px;
    margin-right: -40px;
    padding: 0 40px;
    scroll-behavior: smooth;
}

.stocks-trends__gallery::-webkit-scrollbar {
    height: 26px;
}

.stocks-trends__gallery::-webkit-scrollbar-track {
    background-color: #cecece;
    border-top: 24px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.stocks-trends__gallery::-webkit-scrollbar-thumb {
    background: rgb(25, 25, 25);
    border-top: 23px solid #FFFFFF;
}

.stocks-trends__gallery-item {
    width: 574px;
    flex-shrink: 0;
    display: block;
    text-decoration: none;
    margin-inline:auto;
}

.stocks-trends__gallery-img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: 657px;
    object-fit: contain;
    object-position: center;
    max-height: 100%;
}

@media (max-width: 1023px) {

    .stocks-trends__section {
        margin-bottom: 40px;
    }

    .stocks-trends__main {
        gap: 20px;
    }

    .stocks-trends__main-img {
        flex-basis: 337px;
    }

    .stocks-trends__main-img img {
        max-height: 404px;
    }

    .stocks-trends__main-content {
        flex-basis: 377px;
    }

    .stocks-trends__title {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 18px;
    }

    .stocks-trends__recs {
        gap: 10px;
    }

    .stocks-trends__recs-item {
        width: 245px;
    }

    .stocks-trends__recs-img {
        height: 337px;
    }

    .stocks-trends__recs-title {
        font-size: 12px;
        line-height: 16px;
    }

    .stocks-trends__recs-price {
        font-size: 12px;
        line-height: 16px;
    }

    .sell_price_stock_slider {
        font-size: 12px;
        line-height: 16px;
    }

    .price_stock_slider {
        font-size: 14px;
    }
    .price_stock_slider.old_price {
        font-size: 12px;
        margin-left: 10px;
    }

    .stocks-trends__gallery {
        gap: 20px;
    }

    .stocks-trends__gallery-item {
        width: 481px;
    }

    .stocks-trends__gallery-img {
        height: 550px;
    }
}

@media (max-width: 768px) {
    .stocks-trends__breadcrumbs-item {
        padding-right: 20px;
    }

    .stocks-trends__nav {
        justify-content: flex-start;
    }

    .stocks-trends__container {
        padding: 0 20px;
    }

    .stocks-trends__main {
        flex-direction: column;
        gap: 40px;
    }

    .stocks-trends__main-img {
        flex-basis: auto;
    }

    .stocks-trends__main-img img {
        max-height: 468px;
    }

    .stocks-trends__main-content {
        flex-basis: auto;
    }

    .stocks-trends__title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    .stocks-trends__main-text {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 18px;
    }

    .stocks-trends__recs {
        margin-left: -20px;
        margin-right: -20px;
        padding: 0 20px;
    }

    .stocks-trends__recs::-webkit-scrollbar {
        height: 11px;
    }

    .stocks-trends__recs::-webkit-scrollbar-track {
        border-top: 9px solid #FFFFFF;
    }

    .stocks-trends__recs::-webkit-scrollbar-thumb {
        border-top: 8px solid #FFFFFF;
    }

    .stocks-trends__recs-item {
        width: 100vw;
    }

    .stocks-trends__recs-img {
        height: auto;
        margin-bottom: 12px;
    }

    .stocks-trends__recs-title {
        margin-bottom: 5px;
    }

    .stocks-trends__gallery {
        gap: 10px;
        padding: 0 20px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .stocks-trends__gallery::-webkit-scrollbar {
        height: 11px;
    }

    .stocks-trends__gallery::-webkit-scrollbar-track {
        border-top: 9px solid #FFFFFF;
    }

    .stocks-trends__gallery::-webkit-scrollbar-thumb {
        border-top: 8px solid #FFFFFF;
    }

    .stocks-trends__gallery-item {
        width: 100vw;
    }

    .stocks-trends__gallery-img {
        height: auto;
    }
}

@media (max-width: 428px) {
    .stocks-trends__breadcrumbs-item {
        padding-right: 12px;
    }

    .stocks-trends__breadcrumbs-item::after {
        width: 7px;
        right: 4px;
        top: 8px;
    }
}