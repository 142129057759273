.returns-list-item {
    display: flex;
    width: 100%;
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    padding: 16px 18px 16px 30px;
    margin-top: 5px;
    cursor: pointer;
    color: black;
    text-decoration: none;
    transition: background-color 0.8s ease 0s;
}
.returns-list-item a {
    display: flex;
    width: 100%;
    text-decoration: none;
}
.returns-list-item:hover {
    background-color: #e3e5e7;
}

.returns-list-item__field {
    max-width: 172px;
    /*width: 100%;*/
    width: 13%;
    text-align: start;
    padding: 5px;
    font-size: 12px;
    white-space: pre-line;
}

.returns-list-item__field:first-child {
    width: 4%;
}
.trash_box {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.trash_box:hover svg path {
    stroke: black;
    transition: stroke 0.4s ease 0s;
}
