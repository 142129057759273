.dolyami_settings_page {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 25px;
}

.dolyami_settings_page_content_wrap {
    display: flex;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    margin-top: 5px;
}