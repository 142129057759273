.info-pagination-bullet {
  &.swiper-pagination-bullet {
    width: 40px;
    height: 3px;
    opacity: 0.5;
    transition: .3s;
    border-radius: 0;
    background-color: #fff;
  }

  &.swiper-pagination-bullet-active {
    width: 40px;
    height: 3px;
    opacity: 1;
    pointer-events: none;
  }
}

@media (max-width: 768px) {
  .stocks-trends__container .swiper-pagination {
    bottom: 48px;
    font-size: 0;
  }
}