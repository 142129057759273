.child_items_menu {
}
.subcategory_catalog_item {
    display: flex;
    flex-direction: column;
    /*text-transform: uppercase;*/
    /*margin-top: 25px;*/
    padding: 9px 40px;
}
.subcategory_and_arrows {
    display: flex;
    justify-content: space-between;
}
.subcategory_catalog {
    text-decoration: unset;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    /*margin-left: 15px;*/
    width: 100%;
}
.subcategory_subcategories {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-decoration: unset;
    /*margin-left: 15px;*/
    margin-top: 15px;
}
.subcategories_arrows {
    width: 100%;
    height: 20px;
    position: relative;
    margin-left: 10px;
    cursor: pointer;
    /*background: #E69191;*/

}
.subcategories_arrows.active:after {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 9px;
    right: 0px;
}
.subcategories_arrows.active:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 9px;
    right: 7px;
}
.subcategories_arrows.hide:after {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 9px;
    right: 0px;
}
.subcategories_arrows.hide:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 9px;
    right: 7px;
}
.subcategory_child_container {
    display: flex;
    flex-direction: column;
}


@media (max-width: 768px) {
    .mobile_menu_item {
        padding: 18px 20px 19px 40px;
    }
}