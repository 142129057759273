.reuse_big_block {
    max-width: 100%;
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
}
.reuse_big_block:first-child {
    margin-top: 0px;
}
.reuse_big_block img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.reuse_big_block video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.flying_block {
    position: absolute;
    max-width: 780px;
    width: 100%;
    height: auto;
    z-index: 5;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*padding: 20px;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
}
.bigWords {
    text-align: center;
    display: flex;
    font-family: 'Druk Cyr';
    font-style: italic;
    font-weight: 400;
    font-size: 130px;
    width: max-content;
}
.bigWordsText {
    font-style: normal;
    font-weight: 1000;
    font-family: 'Druk Cyr';
    font-style: italic;
    font-size: 50px;
    color: #FFFFFF;
    margin-top: 10px;
}
.big_block_naming {
    position: absolute;
    padding: 40px;
    bottom: 0px;
    left: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #3A3A3A;
}

@media (max-width: 1324px) {
    .bigWords {
        font-size: 110px;
    }
}

@media (max-width: 1124px) {
    .bigWords {
        font-size: 90px;
    }
}

@media (max-width: 900px) {
    .bigWords {
        font-size: 70px;
    }
}

@media (max-width: 768px) {
    .bigWords {
        font-size: 60px;
    }

    .bigWordsText {
        font-size: 50px;
    }
}

@media (max-width: 624px) {
    .bigWords {
        font-size: 50px;
    }

    .bigWordsText {
        font-size: 40px;
    }
}

@media (max-width: 570px) {
    .bigWords {
        font-size: 38px;
    }

    .bigWordsText {
        font-size: 30px;
    }
}

@media (max-width: 428px) {
    .bigWords {
        font-size: 35px;
    }

    .bigWordsText {
        font-size: 25px;
    }
}

@media (max-width: 380px) {
    .bigWords {
        font-size: 30px;
    }

    .bigWordsText {
        font-size: 20px;
    }
}
