
.shadow_abs {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    z-index: -1;
}
.shadow_abs.active {
    background-color: #000000c9;
    color: white;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 25;
    text-align: center;
    transition: background-color 0.8s ease 0s;
}

.complite_remove {
    padding: 5px 10px;
    border: 1px solid white;
    cursor: pointer;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.back_prev_state  {
    padding: 5px 10px;
    border: 1px solid white;
    cursor: pointer;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.abs_wrapper_btn {
    max-width: 250px;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.anim_btn:hover{
    background-color: white;
    color: black;
}