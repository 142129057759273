.midle_bar_wrapper {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: center;
    max-width: 660px;
    width: 100%;
}
.one_column {
    /*width: 150px;*/
    max-width: 390px;
    width: 100%;

    margin-left: 60px;
    display: flex;
    flex-direction: column;
}
.heading_one_column {
    /*text-transform: uppercase;*/
    color: #1A1A1A;
    padding-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}
.one_column a {
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    margin-top: 16px;
    /*text-transform: uppercase;*/
    color: #1A1A1A;
    font-size: 14px;
    width: max-content;
    border-bottom: 1px solid transparent;
    padding-bottom: 2px;
    transition: border-color 0.8s ease 0s;
}

.one_column a:hover {
    border-bottom: 1px solid #1A1A1A;
    transition: border-color 0.8s ease 0s;
}


@media (max-width: 1400px) {
    .one_column {
        margin-left: 40px;
    }
    .one_column a {
        width: auto;
        border-bottom: unset;
    }
    .one_column a:hover {
        border-bottom: unset;
    }
}

@media (max-width: 1024px) {
    .midle_bar_wrapper {
        margin-top: 40px;
        width: 100%;
        /*justify-content: space-between;*/
        max-width: 100%;
        justify-content: flex-start;
    }
    .one_column {
        margin-left: 30px;
        max-width: 250px;
        width: 100%;
    }
    .one_column:first-child {
        margin-left: 0px;
    }
}

@media (max-width: 767px) {
    .midle_bar_wrapper {
        margin-top: 10px;
        flex-wrap: wrap;
    }
    .one_column {
        max-width: 200px;
        margin-top: 25px;
        margin-left: 0px;
    }
}
@media (max-width: 400px)  {
    .midle_bar_wrapper {
        margin-top: 5px;
    }
    .one_column {
        max-width: 120px;
    }
}