.subWrapper {
    margin-top: 60px;
    width: 100%;
    display: flex;
    padding: 0px 40px;
}
.Sub_Form_Container {
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
}
.left_Sub_Container {
    max-width: 640px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.right_Sub_Container {
    max-width: 616px;
    width: 100%;
    position: relative;
}

.right_Sub_Container:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(87.39deg, #000000 -0.68%, rgba(0, 0, 0, 0) 100.95%);
    z-index: 5;
}
.right_Sub_Container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.sub_form_home {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.heading_SubForm {
    font-size: 36px;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
    color: #1A1A1A;
}
.politic_sub_home_wrap {
    display: flex;
    flex-direction: column;
}
.error_mess_sub_home{
    font-size: 14px;
    color: #CE3F3F;
    margin-top: 5px;
    font-weight: 500;
    line-height: 16px;
    height: 30px;
    width: 100%;
}
.confirm_selector_home {
    width: 15px;
    height: 14px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 3px;
}
.child_selector_home {
    width: 8px;
    height: 8px;
    background: #1A1A1A;
    opacity: 0;
    transition: opacity 0.4s ease 0s;
    position: relative;
}
.confirm_selector_home.active .child_selector_home {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}
.policy_form {
    color: #C7C7C7;
    font-size: 14px;
    margin-top: 40px;
    text-align: center;
    display: flex;
}
.policy_form p {
    color: #C7C7C7;
    font-size: 14px;
    margin-top: 0px;
}
.policy_form a {
    text-decoration: none;
    color: #000000;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.4s ease 0s;
}
.policy_form a:hover {
    border-bottom: 1px solid black;
    transition: border-bottom 0.4s ease 0s;
}
.policy_form span {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}


@media (max-width: 1024px) {
    .heading_SubForm {
        font-size: 18px;
        line-height: 23px;
        max-width: 300px;
    }
    .policy_form {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .subWrapper {
        margin-top: 40px;
        padding: 0px 20px;
    }
    .sub_form_home {
        margin-top: 20px;
    }
    .sub_Form_button {
        margin-top: 20px;
    }
    .policy_form p {
        font-size: 12px;
    }

}
