.infoDetail {
    position: relative;
    width: 100%;
    // height: 100%;

    &Img {
        position: relative;
        /* min-height: 1025px; */
        aspect-ratio: 1440 / 1025;
        width: 100%;
        max-height: 100dvh;
        flex-basis: 680px;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #00000059;
        margin-bottom: 80px;

        @media (max-width: 1023px) {
            aspect-ratio: 834 / 1194;
            margin-bottom: 60px;
        }
        
        @media (max-width: 768px) {
            aspect-ratio: 39 / 52;
            margin-bottom: 40px;
        }

        & img {
            display: block;
            z-index: -1;
            max-width: 100%;
            /* max-height: 816px; */
            width: auto;
            /* height: 1025px; */
            height: 100%;
            object-fit: contain;
            object-position: top;
        }
    }

    &Title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: "Druk Cyr", sans-serif;
        font-weight: 700;
        font-size: 150px;
        line-height: 1.27;
        color: #fff;
        font-style: italic;
        margin: 0;
        width: max-content;
        z-index: 1;
        width: calc(100dvw - 80px);
        text-align: center;

        font-size: 130px;
        font-style: italic;
        font-weight: 400;

        @media (max-width: 1023px) {
            font-size: 100px;
        }
        
        @media (max-width: 768px) {
            font-size: 70px;
        }
    }

    &Section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        margin-bottom: 80px;

        @media (max-width: 1023px) {
            margin-bottom: 60px;
        }

        @media (max-width: 768px) {
            margin-bottom: 40px;
        }
    }

    &Content {
        width: 100%;
        max-width: 844px;
        flex-grow: 1;

        & h2 {
            // max-width: 600px;
            // text-align: center;
            margin: 0 auto 80px;
            font-size: 36px;
            font-weight: 500;
            line-height: 1.3;

            @media (max-width: 1023px) {
                margin-bottom: 60px;
            }
        
            @media (max-width: 768px) {
                margin-bottom: 40px;
                font-size: 24px;
            }
        }

        & h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 1.3;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            @media (max-width: 1023px) {
                font-size: 24px;
            }

            @media (max-width: 768px) {
                font-size: 18px;
            }
        }

        & p {
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #1A1A1A;
            margin: 0 0 21px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &Slider {
        padding-top: 80px;

        @media (max-width: 1023px) {
            padding-top: 60px;
        }

        @media (max-width: 768px) {
            padding-top: 40px;
        }
        
        p + &  {
            margin-top: -16px;
        }

        &:not(:last-child) {
            padding-bottom: 80px;

            @media (max-width: 1023px) {
                padding-bottom: 60px;
            }
            @media (max-width: 768px) {
                padding-bottom: 40px;
            }
        }

        & img {
            display: block;
            width: 100%;
        }
    }
}

.stocks-trends__nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 11px;
    margin-bottom: 30px;
}

.stocks-trends__nav-btn {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.stocks-trends__container {
    padding: 0 40px;
}

.stocks-trends__section {
    
}

.stocks-trends__main {
    
}





.stocks-trends__main-content>div>h2 {
    max-width: 600px;
    text-align: center;
    margin: 0 auto 80px;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.3;
}

// .stocks-trends__main-content>div>

.stocks-trends__main-content>div>p {
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.02em;
}

.stocks-trends__main-content>div>img {
    padding-top: 80px;
}

.stocks-trends__main-content>div>img:not(:last-child) {
    padding-bottom: 80px;
}

.stocks-trends__main-content>div>h3

.stocks-trends__main-content .swiper-slide {
    max-width: calc(50% - 24px);
}








@media (max-width: 1023px) {


    .stocks-trends__section {
        margin-bottom: 40px;
    }

    .stocks-trends__main {
        gap: 20px;
    }

    .stocks-trends__main-img {
        flex-basis: 337px;
    }

    .stocks-trends__main-img img {
        max-height: 404px;
    }

    .stocks-trends__main-content {
        flex-basis: 377px;
    }

    .stocks-trends__title {
        font-size: 24px;
        line-height: 31px;
        margin-bottom: 18px;
    }

    .stocks-trends__recs {
        gap: 10px;
    }

    .stocks-trends__recs-item {
        width: 245px;
    }

    .stocks-trends__recs-img {
        height: 337px;
    }

    .stocks-trends__recs-title {
        font-size: 12px;
        line-height: 16px;
    }

    .stocks-trends__recs-price {
        font-size: 12px;
        line-height: 16px;
    }

    .sell_price_stock_slider {
        font-size: 12px;
        line-height: 16px;
    }

    .price_stock_slider {
        font-size: 14px;
    }
    .price_stock_slider.old_price {
        font-size: 12px;
        margin-left: 10px;
    }

    .stocks-trends__gallery {
        gap: 20px;
    }

    .stocks-trends__gallery-item {
        width: 481px;
    }

    .stocks-trends__gallery-img {
        height: 550px;
    }
}

@media (max-width: 768px) {
    .stocks-trends__breadcrumbs-item {
        padding-right: 20px;
    }

    .stocks-trends__nav {
        justify-content: flex-start;
    }

    .stocks-trends__container {
        padding: 0 20px;
    }

    .stocks-trends__main {
        flex-direction: column;
        gap: 40px;
    }

    .stocks-trends__main-img {
        flex-basis: auto;
    }

    .stocks-trends__main-img img {
        max-height: 468px;
    }

    .stocks-trends__main-content {
        flex-basis: auto;
    }

    .stocks-trends__title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 10px;
    }

    .stocks-trends__main-text {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 18px;
    }

    .stocks-trends__recs {
        margin-left: -20px;
        margin-right: -20px;
        padding: 0 20px;
    }

    .stocks-trends__recs::-webkit-scrollbar {
        height: 11px;
    }

    .stocks-trends__recs::-webkit-scrollbar-track {
        border-top: 9px solid #FFFFFF;
    }

    .stocks-trends__recs::-webkit-scrollbar-thumb {
        border-top: 8px solid #FFFFFF;
    }

    .stocks-trends__recs-item {
        width: 100vw;
    }

    .stocks-trends__recs-img {
        height: auto;
        margin-bottom: 12px;
    }

    .stocks-trends__recs-title {
        margin-bottom: 5px;
    }

    .stocks-trends__gallery {
        gap: 10px;
        padding: 0 20px;
        margin-left: -20px;
        margin-right: -20px;
    }

    .stocks-trends__gallery::-webkit-scrollbar {
        height: 11px;
    }

    .stocks-trends__gallery::-webkit-scrollbar-track {
        border-top: 9px solid #FFFFFF;
    }

    .stocks-trends__gallery::-webkit-scrollbar-thumb {
        border-top: 8px solid #FFFFFF;
    }

    .stocks-trends__gallery-item {
        width: 100vw;
    }

    .stocks-trends__gallery-img {
        height: auto;
    }
}

@media (max-width: 768px) {
    .stocks-trends__breadcrumbs-item {
        padding-right: 12px;
    }

    .stocks-trends__breadcrumbs-item::after {
        width: 7px;
        right: 4px;
        top: 8px;
    }
}



