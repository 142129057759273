.pp_content {
    max-width: 1890px;
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
}
.pp_title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    color: #1A1A1A;
}
.wrapper_switch {
    margin-top: 20px;
}
.pp_conteiner_text {
    width: 100%;
    padding: 20px 20px 25px 20px;
    border: 1px solid #000000;
    box-sizing: border-box;
    margin-top: 50px;
    margin-bottom: 110px;
}
.pp_conteiner_text.politica {
    margin-top: 10px;
}
.pp_child_text {
    overflow: scroll;
    overflow-x: hidden;
    height: 608px;
    padding-right: 107px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}
.pp_child_text::-webkit-scrollbar {
    background: #F4F4F4;
    /*border-radius: 20px;*/
    height: 563px;
    width: 10px;
}
.pp_child_text::-webkit-scrollbar-thumb {
    background: #000000;
    /*border-radius: 20px;*/
}
.policy_subline {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}
.policy_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
}



@media (max-width: 1024px) {
    .pp_content {
        margin-top: 45px;
    }
    .pp_conteiner_text {
        margin-top: 30px;
        padding: 15px;
        margin-bottom: 60px;
    }
    .pp_child_text {
        height: 280px;
        padding-right: 50px;
    }
    .policy_text {
        line-height: 24px;
    }
    .pp_child_text::-webkit-scrollbar {
        width: 6px;
    }
}
@media (max-width: 768px) {
    .pp_content {
        margin-top: 35px;
        padding: 0px 5px 0px 15px;
    }
    .pp_title {
        font-size: 26px;
    }
    .pp_conteiner_text {
        margin-top: 25px;
        padding: 0px;
        border: unset;
        margin-bottom: 30px;
    }
    .pp_child_text {
        padding-right: 5px;
        height: 264px;
    }

}
@media (max-width: 320px) {
    .pp_content {
        margin-top: 30px;
    }
    .pp_title {
        font-size: 24px;
    }
    .pp_conteiner_text {
        margin-top: 20px;
    }
    .policy_subline {
        font-size: 14px;
    }
    .policy_text {
        line-height: 22px;
        font-size: 14px;
    }
    .pp_child_text::-webkit-scrollbar {
        width: 4px;
    }
}



@media (max-width: 1024px) {
    .pp_content {
        margin-bottom: 80px;
    }
}


@media (max-width: 768px) {
    .pp_content {
        margin-bottom: 60px;
    }
    .wrapper_switch {
        flex-direction: column;
        margin-top: 10px;
        padding-right: 10px;
    }
    .policy_text {
        font-size: 14px;
    }
}

@media (max-width: 320px) {
    .pp_content {
        margin-bottom: 40px;
    }
}