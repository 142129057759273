.one_item_hierarhy {
    display: flex;
    flex-direction: column;
    width: 200px;
    text-align: center;
    align-items: center;
    padding: 10px 0px;
    margin: 10px;
    min-height: 200px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
}
.one_item_hierarhy_heading {
    background: rgba(201, 204, 207, 0.16);
    display: flex;
    width: 100%;
    justify-content: center;
    height: 30px;
    align-items: center;
    cursor: pointer;
    padding: 0px 10px;
    font-weight: 600;
}
.one_item_hierarhy_heading_id_container {
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 10px;
}
.child_hierarhy_items_container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 10px;

}
.one_child_item_hierarhy {
    display: flex;
    width: 100%;

    margin-top: 15px;
    justify-content: flex-start;
    padding: 5px 10px;
    text-align: left;
    align-items: center;
    background: rgba(245, 245, 245, 0.35);
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 2px;
}