.bulka {
    max-width: 417px;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    background: white;
    /*filter:"invert(100%)";*/
}
.bulka.hide {
    opacity: 0;
    transition: opacity 0.6s ease 0s;
    z-index: -1;
    right: -250%;
}
.bulka.active {
    opacity: 1;
    transition: opacity 0.4s ease 0.2s;
    z-index: -1;
    left: 0px;
}
.parents_categories_mobile {
    border-top: 1px solid #000000;
    overflow-y: scroll;
    padding-bottom: 60px;
}
.parents_categories_mobile::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    border-radius: 10px;
}
.parents_categories_mobile::-webkit-scrollbar-thumb {
    background-color: #000000;
    /*border-radius: 10px;*/
}
.child_categories_mobile {

}
.mob_version_menu_lk.open_menu {
    position: absolute;
    top: 0;
    background: white;
    width: 100%;
    height: 100%;
}


/*дочерние категори*/
.mobile_menu_wrapper {

}
.mobile_menu_item {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    width: 100%;
    /*margin-top: 25px;*/
    padding: 20px 40px;
    cursor: pointer;
    border-bottom: 1px solid black;
}
.mobile_menu_item_child {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.mobile_menu_item_child.open {
    flex-direction: row-reverse;
    max-width: 217px;
}

.categories_item_menu {
    width: 100%;
    /*margin-left: 15px;*/
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /*text-transform: uppercase;*/
    text-decoration: unset;
    color: #1A1A1A;
    position: relative;
    /*border-bottom: 1px solid transparent;*/
}
.categories_item_menu.active {
    /*border-bottom: 1px solid black;*/
    font-weight: 600;
}
.categories_arrows {
    width: 100%;
    height: 20px;
    position: relative;
    /*margin-left: 10px;*/
    cursor: pointer;
}
.categories_arrows.active:after {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0px;
}
.categories_arrows.active:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 5px;
    right: 0px;
}
.categories_arrows.hide:after {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 13px;
    left: 5px;
}
.categories_arrows.hide:before {
    content: " ";
    position: absolute;
    width: 12px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 5px;
    left: 5px;
}

.smooth_appearance_categories.active {
    opacity: 1;
    transition: opacity 0.2s ease 0.2s;
}
.smooth_appearance_categories {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
}

.open_menu_lk {
    width: 100px;
    height: 88px;
    background: #0b4a0b;
}



@media (max-width: 768px) {
    .bulka {
        width: 100%;
        max-width: unset;
    }
    .parents_categories_mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0px;
        position: relative;
    }
}
@media (max-width: 380px) {
    .parents_categories_mobile {
        justify-content: flex-start;
    }
}
