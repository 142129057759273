
.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0, 0%);
    transition: background 0.4s ease 0s;
}
.shadow.light {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background: rgba(0,0,0, 30%);
    transition: background 0.4s ease 0s;
}
.shadow:hover  {
    background: rgba(0,0,0, 60%);
    transition: background 0.4s ease 0s;
}

.OneAndFour .shadow:hover  {
    background: rgba(0,0,0, 90%);
    transition: background 0.4s ease 0s;
}


@media (max-width: 768px) {
    /*.double_block_st {*/

    /*    height: 100vh;*/
    /*}*/
    /*.double_block_st img {*/
    /*    height: 100%;*/
    /*    object-fit: cover;*/
    /*}*/
}