.ReviewsModal {
    position: fixed;
    max-width: 645px;
    width: 100%;
    background: #FFFFFF;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.0s ease 0s;
    /*padding: 30px;*/
    border: 1px solid #000000;
    box-sizing: border-box;
    /*box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);*/
    left: -100%;
}
.ReviewsModal.active {
    z-index: 15;
    opacity: 1;
    transition: opacity 0.6s ease 0.4s;
    left: 50%;
}
.reviews_modal_close_parents {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.reviews_modal_close {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.reviews_modal_close::after {
    content: " ";
    position: absolute;
    width: 28px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 15px;
    right: 1px;
}
.reviews_modal_close::before{
    content: " ";
    position: absolute;
    width: 28px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 15px;
    right: 1px;
}
.reviews_modal_title_and_close {
    display: flex;
    justify-content: space-between;
    padding: 18px 18px 18px 40px;
    border-bottom: 1px solid black;
}
.reviews_modal_title {
    display: flex;
    font-weight: 500;
    font-size: 24px;
    color: #1A1A1A;
}
.wrapper_modal_reviews_fields {
    padding: 40px;
    display: flex;
    justify-content: center;
}
.reviews_grade_conteiner {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*margin-top: 40px;*/
    max-width: 519px;
    width: 100%;
    justify-content: center;
}

.reviews_grade_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.reviews_your_mark {
    display: flex;
}
.recall_score {
    width: 30px;
    height: 30px;
    background-color: #EFEFEF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    transition: 0.4s ease 0s;
}
.recall_score:first-child{
    margin-left: 0px;
}
.recall_score.black {
    background-color: black;
    color: white;
    transition: 0.4s ease 0s;
}
.reviews_fields {
    max-width: 520px;
    width: 100%;
}
.reviews_name_fields {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    position: relative;
}
.user_fields_input {
    height: 44px;
    padding-bottom: 4px;
    padding-left: 5px;
    width: 100%;
    border: 1px solid #D7D6D6;
    margin-top: 7px;
}

.user_fields_textarea {
    line-height: 180%;
    height: 90px;
    padding-bottom: 4px;
    padding-left: 5px;
    max-width: 520px;
    width: 100%;
    resize: none;
    border: 1px solid #D7D6D6;
    margin-top: 7px;
}
.user_fields_textarea::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
}
.user_fields_textarea::-webkit-scrollbar-thumb {
    background-color: black;
}

.loading_files_modal_wrapper {
    width: 100%;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    margin-top: 40px;
    padding: 16px 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.loading_files_modal_wrapper:hover {
    background: #eeeeee;
    transition: background 0.4s ease 0s;
}
.img_load_reviews {

}
.text_wrap_load_reviews {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}
.load_black_text {
    font-weight: 400;
    font-size: 13px;
    color: #000000;
}
.load_grey_text {
    font-weight: 400;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 12px;
}
.load_button_reviews {
    max-width: 120px;
    padding: 12px;
    height: 40px;
    background: black;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    margin-left: 24px;
}
.reviews_submit_button {
    margin-top: 40px;
    /*max-width: 479px;*/
    width: 100%;
    padding: 18px;
    background-color: black;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.4s ease 0s;
}
.reviews_submit_button:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
    transition: 0.8s ease 0s;
}
.reviews_span_policy {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #C7C7C7;
}
.reviews_style_policy {
    color: black;
    text-decoration: none;
}
.reviews_style_policy:hover {
    border-bottom: 1px solid black;
}
.error_text {
    padding: 5px;
    color: #CE3F3F;
    font-weight: 500;
    text-align: center;
}




@media (max-width: 1024px) {
    .reviews_name_fields {
        margin-top: 20px;
    }
    .loading_files_modal_wrapper {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {

    .ReviewsModal.active {
        width: 90%;
    }
    .reviews_modal_title_and_close {
        padding: 18px 20px;
    }
    .reviews_modal_title {
        font-size: 16px;
        line-height: 21px;
    }
    .reviews_modal_close {
        width: 15px;
        height: 15px;
    }
    .reviews_modal_close::before {
        width: 15px;
        height: 2px;
        top: 9px;
        right: 0px;
    }
    .reviews_modal_close::after {
        width: 15px;
        height: 2px;
        top: 9px;
        right: 0px;
    }
    .wrapper_modal_reviews_fields {
        padding: 20px;
    }


}
@media (max-width: 428px) {
    .reviews_modal_title_and_close {
        padding: 10px;
    }

    .reviews_grade_title {
        font-size: 14px;
    }
    .recall_score {
        width: 24px;
        height: 24px;
        margin-left: 8px;
        font-size: 12px;
        margin-top: 5px;
    }
    .reviews_name_fields {
        margin-top: 10px;
        font-size: 12px;
    }
    .user_fields_input {
        height: 30px;
        margin-top: 5px;
        font-size: 12px;
    }
    .user_fields_textarea {
        margin-top: 5px;
        height: 50px;
        font-size: 12px;
    }
    .loading_files_modal_wrapper {
        margin-top: 10px;
        padding: 5px;
    }
    .img_load_reviews svg {
        width: 37px;
        height: 28px;
    }
    .text_wrap_load_reviews {
        margin-left: 10px;
    }
    .load_black_text {
        font-size: 10px;
    }
    .load_grey_text {
        font-size: 8px;
        margin-top: 5px;
    }
    .load_button_reviews {
        margin-left: 10px;
        padding: 10px;
        height: 30px;
        font-size: 10px;
    }
    .reviews_submit_button {
        margin-top: 20px;
        padding: 10px;
    }
    .reviews_span_policy {
        margin-top: 10px;
        font-size: 10px;
    }
    .error_text {
        font-size: 12px;
        padding: 3px;
    }
}

@media (max-width: 380px) {
    .wrapper_modal_reviews_fields {
        padding: 10px;
    }
    .recall_score {
        width: 23px;
        height: 23px;
        margin-left: 5px;
        font-size: 12px;
        margin-top: 5px;
    }
}
