.page_look_wrapper {
    max-width: 2440px;
    width: 100%;
    padding: 0px 60px 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.slider_inst_look {
    display: flex;
    justify-content: space-between;
}
.arrows_insta_wrap {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrows_insta {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.arrows_insta.left {
    margin-right: 35px;
}
.arrows_insta.right {
    margin-left: 35px;
}
.arrows_insta.block {
    pointer-events: none;
}
.arrows_insta.block svg path {
    fill: #b4b4b4;
}
.scroll_limiter {
    overflow-x: scroll;
    display: flex;
    scroll-behavior: smooth;
}
.scroll_limiter::-webkit-scrollbar {
    background: transparent;
    height: 0px;
    width: 0px;
}
.scroll_limiter::-webkit-scrollbar-thumb {
    background: transparent;
}
.one_circle_wrap {
    max-width: 90px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.one_circle_wrap:last-child {
    margin-right: 0px;
}
.link_look {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title_highlights {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #1A1A1A;
}
.insta_circle {
    width: 90px;
    height: 90px;
    padding: 3px;
    border: 2px solid black;
    border-radius: 50%;
}
.insta_circle img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.big_img_look {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.look_big_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    text-decoration: none;
}
.look_big_wrapper:first-child {
    margin-top: 40px;
}
.img_preview_look {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.img_preview_look img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.preview_look_title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #1A1A1A;
    margin-top: 20px;
}
.to_page_look {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    text-decoration-line: underline;
    color: #1A1A1A;
    margin-top: 7px;
}
.to_page_look:hover {
    font-weight: 500;
    transition: font-weight 0.4s ease 0s;
}
.lookbook_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 40px 20px;
}

@media (max-width: 1024px) {
    .page_look_wrapper {
        padding: 0px 40px 40px 40px;
    }
    .scroll_limiter {
        max-width: 600px;
    }
    .arrows_insta.right {
        margin-left: 11px;
    }
    .arrows_insta.left {
        margin-right: 11px;
    }
    .look_big_wrapper {
        margin-top: 40px;
    }
    .preview_look_title {
        font-size: 24px;
        line-height: 31px;
    }
    .to_page_look {
        margin-top: 10px;
    }
}

@media (max-width: 768px)  {
    .page_look_wrapper {
        padding: 0px 20px 20px 20px;
    }
    .scroll_limiter {
        position: relative;
        z-index: 1;
        padding: 0px 10px;
    }
    .arrows_insta_wrap {
        position: absolute;
        z-index: 2;
    }
    .arrows_insta_wrap.left {
        left: 0px;
        background: linear-gradient(271.04deg, #FFFFFF 11.79%, rgba(255, 255, 255, 0) 99.39%);
        transform: rotate(-180deg);
        padding-right: 20px;
    }
    .arrows_insta.left {
        transform: rotate(180deg);
    }
    .arrows_insta_wrap.right {
        right: 0px;
        background: linear-gradient(271.04deg, #FFFFFF 11.79%, rgba(255, 255, 255, 0) 99.39%);
        padding-right: 20px;
    }
    .arrows_insta.left {
        margin-right: 0px;
    }
    .arrows_insta.right {
        margin-left: 0px;
    }
    .look_big_wrapper {
        margin-top: 20px;
    }
    .look_big_wrapper:first-child {
        margin-top: 20px;
    }
    .preview_look_title {
        margin-top: 10px;
        font-size: 18px;
        line-height: 23px;
    }
    .to_page_look {
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
    }
}

