.filterBtn {
    display: flex;
    align-items: center;
    gap: 5px;

    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    cursor: pointer;
}

.filterBtn span {
    border-bottom: 1px solid black;
}

@media all and (max-width: 1024px) {
    .filters {
        padding: 72px 20px !important;
    }
}