.exit_user_block {
    /*width: 25px;*/
    /*height: 24px;*/
    cursor: pointer;
    padding: 14px;
    border: 1px solid black;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1A1A1A;
}

.exit_user_block svg {
    cursor: pointer;
    height: 100%;
    width: 100%;
}