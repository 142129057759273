
.one_order_page_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}
.one_order_page_sub_wrapper {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
}
.one_order_page_content_container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 15px;
    padding: 10px;
}

.one_order_page_top_line_for_inform {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px;
}

.one_order_page_uuid_line_for_inform {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 0 0 30px;
}

.one_order_page_top_line_left {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #2C2C2C;
}

.one_order_page_top_line_right {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: right;
    color: #2C2C2C;
}

.one_order_page_ident_container {
    padding: 20px;
    display: flex;
    width: 100%;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    margin-top: 50px;
    flex-direction: column;
}

.one_order_page_ident_container_top_line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
}

.one_order_page_ident_container_top_line_item_left {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #2C2C2C;
}


.one_order_page_status_indicator {
    padding: 12px;
    background: #CE3F3F;
    border-radius: 10px;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    min-width: 154px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.one_order_page_data_wrapper {
    display: flex;
    margin-top: 44px;
}

.one_order_page_data_one_item {
    display: flex;
    margin-left: 16px;
    margin-top: 16px;
    flex-direction: column;
    max-width: 320px;
    width: 100%;
}
.one_order_page_data_one_item_params {
    display: flex;
    width: 100%;
    min-height: 400px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 0px 0px 10px 10px;
    border-top: unset;
    flex-direction: column;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
}
.one_order_page_no_data_block {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    width: 100%;
    height: 100%;
}


.one_order_page_data_one_item_params.center {
    justify-content: center;
    align-items: center;
    font-weight: 600;
}


.one_order_page_data_one_item:first-child {
    margin-left: 0px;
}

.one_order_field_container {
    display: flex;
    min-height: 38px;
    margin-top: 10px;
    align-items: center;
}

.one_order_field_container.column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.one_order_field_container.column .one_order_field_container_value {

    margin-left: 0px;
    margin-top: 5px;
}

.one_order_field_container_name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    display: flex;
    width: 100px;
}
.one_order_field_container_name.no_fix {
    width: 100%;
}

.one_order_page_data_one_item_heading {
    display: flex;
    padding: 16px;
    background: #2C2C2C;
    border-radius: 10px 10px 0px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
}
.one_order_field_container input {
    margin-left: 10px;
    flex-grow: 1;
    height: 38px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    padding-left: 5px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.one_order_field_container_value {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    margin-left: 10px;
}
.one_order_field_container_value.pay_indicator {
    padding: 7px 9px;
    display: flex;
    background: #EFEFEF;
    border: 1px solid #DADADA;
    border-radius: 5px;
}

.one_order_one_block_response {
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: 35px;
    align-items: center;
    color: #CE3F3F;
    font-weight: 600;
}

.one_order_page_custom_span {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 16px;
}

.one_order_page_ident_dop_info {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 10px;
}

.one_order_page_wrapper_for_all_status {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-top: 30px;
}


.one_item_to_change_wrapper {
    padding: 7px 10px;

}

.one_item_to_change_status {
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 20px;
    padding: 9px 18px;
    cursor: pointer;
    transition: color 0.4s ease 0s, box-shadow 0.4s ease 0s;
    display: flex;
    flex-direction: column;

}
.one_item_to_change_status:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    color: #CE3F3F;
    transition: color 0.8s ease 0s, box-shadow 0.8s ease 0s;;
}
.one_item_to_change_status.active {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    color: #CE3F3F;
}
.no_switch_color {
    color: black;
}
.horizontal_det_line {
    display: flex;
    height: 1px;
    width: 100%;
    margin-top: 40px;
    background-color: #DADADA;
    border-radius: 5px;
}
.one_order_page_save_and_notification_line {
    display: flex;
    align-items: center;
    margin-top: 40px;
}
.notification_save_changer_line {
    display: flex;
    margin-left: 40px;
    color: #CE3F3F;
    font-weight: 600;
}
.one_order_page_save_and_notification_line .save_change_status {
    width: 152px;
}

.wrapper_delivery_change_for_fields {
    display: flex;
    margin-top: 30px;
    margin-left: -15px;
}
.change_delivery_field_wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}
.change_delivery_field_wrapper_name_field {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #A5A5A5;
}
.reminder_order_fields {
    font-size: 11px;
    color: #CE3F3F;
}

.change_delivery_field_wrapper input {
    border: 1px solid #DADADA;
    border-radius: 5px;
    height: 44px;
    min-width: 200px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    padding-left: 15px;
    color: #000000;
    margin-top: 10px;
}

.container_for_change_delivery_fields {
    margin-top: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.add_comment_detail_order_input {
    width: 557px;
    height: 88px;
    left: 283px;
    top: 2595px;
    border: 1px solid #DADADA;
    border-radius: 5px;
    resize:none;
    margin-top: 10px;
    padding: 10px   ;
}
.order_detail_comments_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.one_comment_wrapper {
    display: flex;
    padding: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: calc(50% - 30px);
    margin-top: 35px;
    flex-direction: column;
}
.delete_comment_hand {
    color: #CE3F3F;
    background: #FFFFFF;
    border: 1px solid #CE3F3F;
    border-radius: 5px;
    padding: 7px 9px;
    font-weight: 600;
    cursor: pointer;
}
.detail_page_id_wrapper {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.detail_page_comment_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;
}

.delete_comment_hand:hover {
    color: white;
    background: #CE3F3F;
}

.detail_order_one_comment_dop_field_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detail_page_comment_time {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #A5A5A5;
    margin-top: 20px;
}
/*.one_order_page_heading_block {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    padding: 20px;*/
/*    font-weight: 600;*/
/*    font-size: 25px;*/
/*}*/

/*.top_order_information {*/
/*    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);*/
/*    border-radius: 10px;*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    margin-top: 30px;*/
/*    padding: 30px;*/
/*}*/
/*.two_order_information_first_column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    max-width: 350px;*/
/*}*/

/*.name_order_and_status {*/
/*    display: flex;*/
/*    height: 40px;*/
/*    width: 100%;*/
/*    align-items: center;*/
/*}*/
/*.name_order_and_status_name_line {*/
/*    display: flex;*/
/*    font-weight: 600;*/
/*    font-size: 16px;*/
/*}*/
/*.name_order_status_line {*/
/*    display: flex;*/
/*    margin-left: 10px;*/
/*    padding: 0 10px 1px;*/
/*    vertical-align: middle;*/
/*    color: #fff;*/
/*    border-radius: 3px;*/
/*    background: #a1bf41;*/
/*    font-weight: 600;*/
/*}*/
/*.two_order_information {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    margin-top: 20px;*/

/*}*/

/*.order_header_notifikator {*/
/*    display: flex;*/
/*    font-weight: 600;*/
/*    font-size: 18px;*/
/*    height: 20px;*/
/*    vertical-align: middle;*/
/*    color: black;*/

/*    border-bottom: 2px solid black;*/
/*    padding-bottom: 22px;*/
/*    width: max-content;*/
/*    margin-top: 50px;*/
/*}*/

/*.order_header_notifikator.margin_top {*/
/*    margin-top: 30px;*/
/*}*/
/*.value_indicator_span {*/
/*    background: #a1bf41;*/
/*    display: flex;*/
/*    font-weight: 600;*/
/*    font-size: 16px;*/
/*    height: 20px;*/
/*    padding: 0 10px 1px;*/
/*    vertical-align: middle;*/
/*    color: black;*/
/*    border-radius: 3px;*/
/*    margin-left: 20px;*/
/*}*/
/*.value_indicator_span.red {*/
/*    background-color: grey;*/
/*    color: white;*/
/*}*/

/*.order_vertical_container_user_info {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-top: 10px;*/
/*}*/
/*.wrapper_for_data_span {*/
/*    display: flex;*/
/*    margin-top: 10px;*/
/*}*/

/*.span_name_data_order {*/
/*    display: flex;*/
/*    font-weight: 600;*/
/*}*/
/*.span_value_data {*/
/*    display: flex;*/
/*    font-weight: 500;*/
/*    margin-left: 20px;*/
/*}*/
/*.two_order_information_two_column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-left: 40px;*/
/*    max-width: 350px;*/
/*}*/

/*.two_order_information_three_column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-left: 40px;*/
/*    max-width: 350px;*/
/*}*/
/*.two_order_information_fo_column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-left: 40px;*/
/*    max-width: 350px;*/
/*}*/
/*.to_order_changer {*/
/*    display: flex;*/
/*    height: 200px;*/
/*    margin-left: 30px;*/
/*}*/
/*.to_order_changer.big {*/
/*    flex-grow: 1;*/
/*    height: 450px;*/
/*}*/
/*.to_order_changer_left {*/
/*    display: flex;*/
/*    height: 100%;*/
/*    align-items: flex-start;*/
/*    justify-content: center;*/
/*    flex-direction: column;*/
/*    max-width: 450px;*/
/*    width: 100%;*/
/*}*/
/*.span_inform_changer {*/
/*    display: flex;*/
/*    margin-top: 15px;*/
/*    font-weight: 500;*/
/*    color: grey;*/
/*    max-width: 300px;*/
/*}*/
/*.to_order_changer_right {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    padding: 0px 10px;*/
/*    width: 430px;*/
/*    overflow-y: scroll;*/
/*    margin-left: 20px;*/
/*}*/
/*.to_order_changer_right.big {*/
/*    width: 425px;*/
/*}*/
/*.container_for_change_delivery_fields {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    flex-grow: 1;*/
/*    margin-left: 30px;*/
/*}*/
/*.change_delivery_field_wrapper {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin-top: 10px;*/

/*}*/
/*.change_delivery_field_wrapper input {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    height: 24px;*/
/*    border: 1px solid lightgrey;*/
/*    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);*/
/*    margin-top: 5px;*/
/*    font-weight: 600;*/
/*    padding-left: 5px;*/
/*}*/
/*.change_delivery_field_wrapper_name_field {*/
/*    font-size: 12px;*/
/*    display: flex;*/
/*    font-weight: 600;*/
/*}*/

/*.to_order_changer_right.big  .one_item_to_change_status {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    min-height: 50px;*/
/*    justify-content: center;*/
/*}*/
/*.delivery_explanation {*/
/*    color: #df7272;*/
/*}*/

/*.one_item_to_change_status {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    min-height: 35px;*/
/*    height: auto;*/

/*    margin-top: 5px;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*    font-weight: 500;*/
/*}*/
/*.one_item_to_change_status:hover {*/
/*    background-color: lightgrey;*/
/*    transition: background-color 0.4s ease 0s;*/
/*}*/
.save_change_status {
    display: flex;
    margin-top: auto;
    width: 100%;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    cursor: pointer;
    max-width: 300px;
    font-weight: 600;
    border-radius: 5px;
    background-color: #2C2C2C;
    color: white;
}

.save_change_status:hover {
    background-color: white;
    color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}


.save_change_status.trig {
    animation: trigSave 2s ease infinite;
}
.detail_order_page_add_new_comment {
    display: flex;
    margin-top: 40px;
    flex-direction: column;
}

.save_comment_detail_order {
    width: 152px;
    height: 44px;
    background: #2C2C2C;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    border: 1px solid #2C2C2C;
}

.save_comment_detail_order:hover {
    background-color: white;
    color: #2C2C2C;

}
.one_order_page_ident_container_top_line.column {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
}

.detail_comment_open_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #CE3F3F;
    cursor: pointer;
    margin-top: 10px;
}
.hey_you_can_add_new_comment {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #A5A5A5;
}

.detail_order_page_items_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.detail_order_page_items_wrapper_refound {
    display: flex;
    width: 500px;
}
.detail_order_page_items_wrapper_refound:hover {
    background-color: whitesmoke;
    cursor: pointer;
}
.one_item_detail_order_page {
    width: 100%;
    display: flex;
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid #DADADA;
    padding-top: 40px;
}

.one_item_detail_order_page_refound {
    width: 100%;
    display: flex;
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid #DADADA;
}
.dolyami_refound_money {
    display: flex;
    height: 40px;
    width: 300px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    color: black;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}
.dolyami_refound_money:hover {
    background-color: black;
    color: white;
}
.wrapper_items_dolyami_order {
    display: flex;
    width: 100%;
    min-height: 200px;
    margin-top: 10px;
}
.count_inp_dolyami {
    width: 60px;
    padding-left: 10px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border: 1px solid black;
}
.span_doly_flex {
    display: flex;
}
.containder_for_return_items {
    display: flex;
    flex-direction: column;
    padding: 30px;
    min-height: 200px;
    border: 1px solid black;
}
.containder_for_return_items span {
    margin-top: 10px;
    font-weight: 600;
}
.dolyami_add_to_return {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 40px;
    border: 1px solid black;
    cursor: pointer;
}
.dolyami_add_to_return:hover {
    background-color: black;
    color: white;
}

.one_item_detail_order_page_img {
    display: flex;
    height: 200px;
    width: 145px;
}

.one_item_detail_order_page_img img {
    display: flex;
    width: 100%;
    height: 100%;
}

.one_item_detail_order_page_content {
    margin-left: 35px;
    display: flex;
    flex-direction: column;
}
.span_for_one_product_order {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.span_for_one_product_articul {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #D7D7D7;
    margin-top: 10px;
}

.colors_container_one_order {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.one_order_size_check {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #CFCFCF;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50%;
    color: #000000;
    cursor: pointer;
    margin-left: 15px;
}
.one_order_size_check:first-child {
    margin-left: 0px;
    margin-top: 10px;
}

.one_order_size_check:hover {
    background-color: black;
    color: white;
}
.one_order_size_check.active {
    background-color: black;
    color: white;
}

.return_doly_items {
    display: flex;
    width: 240px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 40px;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    cursor: pointer;
}
.return_doly_items:hover {
    background-color: black;
    color: white;
}

.one_order_color_check {
    width: 22px;
    height: 22px;
    transition: height 0.4s ease 0s, width 0.4s ease 0s;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    margin-left: 10px;
    border: 2px solid lightgrey;
}
.one_order_color_check:first-child {
    margin-left: 0px;
}
.one_order_color_check.active {
    height: 25px;
    width: 25px;
    transition: height 0.4s ease 0s, width 0.4s ease 0s,border-color 0.4s ease 0s;
    border-color: #c0b8b8;
}


.detail_order_page_link_to_product {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #CE3F3F;
    margin-top: auto;
}
.one_order_page_color_current_inform {
    margin-top: 5px;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    color: gray;
}

.color_you_can_take_this {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.one_order_detail_one_product {
    display: flex;
    margin-left: auto;
    width: 50%;
    height: 100%;
    justify-content: space-between;
}

.one_order_detail_one_product_first_mb {
    display: flex;
    gap: 5px;
    flex-direction: row;
    align-items: center;
}
.one_order_detail_one_mb_count {
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.one_order_trash_one_line {
    padding: 8px;
    cursor: pointer;
    border: black solid 1px;
    border-radius: 5px;
}
.one_order_trash_one_line:hover {
    background-color: black;
    color: white;
}


.count_order_page_adm_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.one_order_detail_one_product_two {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.nest_price {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #2C2C2C;
}
.nest_sell_price {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-decoration-line: line-through;
    color: #000000;
    margin-top: 10px;
}
.one_order_trash_one {
    margin-top: auto;
    display: flex;
    width: 30px;
    height: 30px;
}
.one_order_page_ident_add_new_product {
    border: 1px solid #2C2C2C;
    border-radius: 5px;
    width: 198px;
    display: flex;
    height: 44px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    cursor: pointer;
}

.one_order_page_ident_add_new_product:hover {
    background-color: black;
    color: white;

}

.add_new_product_to_order_area {
    display: flex;
    width: 100%;
    height: 0px;
    overflow: hidden;
    opacity: 0;
    padding: 20px 0px;
}
.add_new_product_to_order_area.active {
    height: 700px;
    opacity: 1;
    transition: height 0.8s ease 0s, opacity 0.8s ease 0s;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    border-top: 1px solid #DADADA;
    border-bottom: 1px solid #DADADA;
}
.one_order_add_area_top_line {
    display: flex;
    justify-content: space-between;

}
.one_order_add_area_top_line_notif {
    display: flex;
    padding-right: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #A5A5A5;
    align-items: center;
}

.one_order_add_close_area {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #CE3F3F;
    cursor: pointer;
    margin-top: 10px;
}

.one_order_add_area_search_block {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.one_order_this_search {
    border: 1px solid #DADADA;
    border-radius: 5px;
    height: 44px;
    max-width: 50%;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    padding-left: 15px;
    color: #000000;
}

.one_order_search_result_area {
    display: flex;
    width: 100%;
    height: 530px;
    margin-top: 10px;
}

.no_search_area_notif {
    display: flex;
    width: 100%;
    height: 530px;
    font-weight: 600;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}
.no_search_and_select_detail {
    display: flex;
    width: 100%;
    height: 100%;

}
.one_order_search_result_container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border: 1px solid #DADADA;
    padding: 5px;
    overflow-y: scroll;
}
.one_search_item_for_add_area {
    height: 90px;
    border: 1px solid #c9cccf;
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    transition: opacity 0.8s ease 0s, background-color 0.8s ease 0s;
    cursor: pointer;
}

.one_search_item_for_add_area:hover {
    background-color: #e3e5e7;
}

.one_item_search_id {
    display: flex;
    width: 80px;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.one_item_search_img {
    display: flex;
    max-width: 80px;
    height: 100%;
    width: 100%;
}
.one_item_search_name {
    display: flex;
    width: 440px;
    font-weight: 600;
    align-items: center;
    height: 100%;
}
.one_item_search_count {
    display: flex;
    height: 100%;
    font-weight: 600;
    align-items: center;
}
.add_new_product_to_order_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.one_item_config_title {
    display: flex;
    font-weight: 600;
    font-size: 17px;
    line-height: 29px;
    color: #A5A5A5;
    margin-top: 20px;
}
.one_item_configurator {
    display: flex;
    width: 100%;
    margin-top: 30px;
}
.one_item_configurator_img_container {
    display: flex;
    height: 200px;
    width: 145px;
}
.one_item_configurator_img_container img {
    display: flex;
    width: 100%;
    height: 100%;
}
.add_new_item_to_order_configurator_panel {
    display: flex;
    margin-top: 30px;
    width: 100%;
    flex-direction: column;
}
.conf_panel_text {
    font-weight: 500;
    color: #CE3F3F;
    max-width: 50%;
}
.conf_panel_controll_buttons {
    display: flex;
    margin-top: 30px;
}
.btn_style_conf_panel {
    display: flex;
    padding: 7px 14px;
    margin-left: 30px;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    border-radius: 5px;
}
.btn_style_conf_panel:first-child {
    margin-left: 0px;
}
.btn_style_conf_panel:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
