.filter_and_breadcrumbs {
    align-items: center;
    padding: 0px 40px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    max-width: 1812px;
    min-height: 64px;
}
.filter_and_breadcrumbs.active {
    box-shadow:
            1px 0 0 0 #000,
            0 1px 0 0 #000,
            1px 1px 0 0 #000,
            1px 0 0 0 #000 inset,
            0 1px 0 0 #000 inset;
}

.sort_catalog {
    display: flex;
    align-items: center;
    position: relative;
    gap: 40px;
}

.title_sort {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    padding-right: 15px;

    border-bottom: 1px solid black;
    cursor: pointer;
}
.title_sort:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 8px;
    right: 0px;
    top: 10px;
    background-color: #000000;
    transform: rotate(-45deg);
    transition: transform 0.4s ease 0s;
}
.title_sort:before {
    position: absolute;
    content: "";
    height: 1px;
    width: 8px;
    right: 5px;
    top: 10px;
    background-color: #000000;
    transform: rotate(45deg);
    transition: transform 0.4s ease 0s;
}
.title_sort.active:after {
    transform: rotate(45deg);
    transition: transform 0.4s ease 0s;
}
.title_sort.active:before {
    transform: rotate(-45deg);
    transition: transform 0.4s ease 0s;
}
.list_sorting_options {
    opacity: 0;
    position: absolute;
    border: 2px solid black;
    padding: 18px;
    background: white;
    top: 36px;
    right: 0;
    width: max-content;
    z-index: -1;
}
.list_sorting_options.active {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
    z-index: 1;
}
.one_sort {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 18px;
    cursor: pointer;
}
.one_sort:first-child {
    margin-top: 0px;
}

.h1_catalog_breadcrmbs {
    display: flex;
    flex-grow: 1;
    font-size: 10px;
}
@media (max-width: 1908px) {
    .filter_and_breadcrumbs {
        max-width: 1359px;
    }
}
@media (max-width: 1438px) {
    .filter_and_breadcrumbs {
        max-width: 906px;
    }
}
@media (max-width: 1024px) {
    .filter_and_breadcrumbs {
        max-width: 756px;
        min-height: 44px;
    }
    .h1_catalog_breadcrmbs {
        font-size: 8px;
    }
}
@media (max-width: 835px) {
    .filter_and_breadcrumbs {
        padding: 0px;
        flex-direction: column-reverse;
        max-width: 500px;
        align-items: flex-end;
        margin-top: 20px;
    }
    .filter_and_breadcrumbs.active {
        box-shadow: unset;
    }
    .sort_catalog {
        margin-top: 20px;
        gap: 16px 0;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
    }
    .title_sort {
        margin-left: auto;
    }
    .h1_catalog_breadcrmbs {
        margin-top: 10px;
    }

}
@media (max-width: 768px) {
    .filter_and_breadcrumbs {
        max-width: 100%;
        padding: 0px 20px;
        margin-top: 0px;
    }
}


