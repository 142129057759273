@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-ThinItalic.eot');
    src: local('IBM Plex Mono Thin Italic'), local('IBMPlexMono-ThinItalic'),
        url('IBMPlexMono-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-ThinItalic.woff2') format('woff2'),
        url('IBMPlexMono-ThinItalic.woff') format('woff'),
        url('IBMPlexMono-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Light.eot');
    src: local('IBM Plex Mono Light'), local('IBMPlexMono-Light'),
        url('IBMPlexMono-Light.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-Light.woff2') format('woff2'),
        url('IBMPlexMono-Light.woff') format('woff'),
        url('IBMPlexMono-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono.eot');
    src: local('IBM Plex Mono'), local('IBMPlexMono'),
        url('IBMPlexMono.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono.woff2') format('woff2'),
        url('IBMPlexMono.woff') format('woff'),
        url('IBMPlexMono.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-LightItalic.eot');
    src: local('IBM Plex Mono Light Italic'), local('IBMPlexMono-LightItalic'),
        url('IBMPlexMono-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-LightItalic.woff2') format('woff2'),
        url('IBMPlexMono-LightItalic.woff') format('woff'),
        url('IBMPlexMono-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-MediumItalic.eot');
    src: local('IBM Plex Mono Medium Italic'), local('IBMPlexMono-MediumItalic'),
        url('IBMPlexMono-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-MediumItalic.woff2') format('woff2'),
        url('IBMPlexMono-MediumItalic.woff') format('woff'),
        url('IBMPlexMono-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Thin.eot');
    src: local('IBM Plex Mono Thin'), local('IBMPlexMono-Thin'),
        url('IBMPlexMono-Thin.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-Thin.woff2') format('woff2'),
        url('IBMPlexMono-Thin.woff') format('woff'),
        url('IBMPlexMono-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Bold.eot');
    src: local('IBM Plex Mono Bold'), local('IBMPlexMono-Bold'),
        url('IBMPlexMono-Bold.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-Bold.woff2') format('woff2'),
        url('IBMPlexMono-Bold.woff') format('woff'),
        url('IBMPlexMono-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Medium.eot');
    src: local('IBM Plex Mono Medium'), local('IBMPlexMono-Medium'),
        url('IBMPlexMono-Medium.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-Medium.woff2') format('woff2'),
        url('IBMPlexMono-Medium.woff') format('woff'),
        url('IBMPlexMono-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-SemiBold.eot');
    src: local('IBM Plex Mono SemiBold'), local('IBMPlexMono-SemiBold'),
        url('IBMPlexMono-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-SemiBold.woff2') format('woff2'),
        url('IBMPlexMono-SemiBold.woff') format('woff'),
        url('IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-ExtraLight.eot');
    src: local('IBM Plex Mono ExtraLight'), local('IBMPlexMono-ExtraLight'),
        url('IBMPlexMono-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-ExtraLight.woff2') format('woff2'),
        url('IBMPlexMono-ExtraLight.woff') format('woff'),
        url('IBMPlexMono-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-BoldItalic.eot');
    src: local('IBM Plex Mono Bold Italic'), local('IBMPlexMono-BoldItalic'),
        url('IBMPlexMono-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-BoldItalic.woff2') format('woff2'),
        url('IBMPlexMono-BoldItalic.woff') format('woff'),
        url('IBMPlexMono-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-Italic.eot');
    src: local('IBM Plex Mono Italic'), local('IBMPlexMono-Italic'),
        url('IBMPlexMono-Italic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-Italic.woff2') format('woff2'),
        url('IBMPlexMono-Italic.woff') format('woff'),
        url('IBMPlexMono-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-SemiBoldItalic.eot');
    src: local('IBM Plex Mono SemiBold Italic'), local('IBMPlexMono-SemiBoldItalic'),
        url('IBMPlexMono-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-SemiBoldItalic.woff2') format('woff2'),
        url('IBMPlexMono-SemiBoldItalic.woff') format('woff'),
        url('IBMPlexMono-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMono-ExtraLightItalic.eot');
    src: local('IBM Plex Mono ExtraLight Italic'), local('IBMPlexMono-ExtraLightItalic'),
        url('IBMPlexMono-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('IBMPlexMono-ExtraLightItalic.woff2') format('woff2'),
        url('IBMPlexMono-ExtraLightItalic.woff') format('woff'),
        url('IBMPlexMono-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

