.result_modal_subscriptions {
    display: flex;
    width: 100%;
    max-width: 657px;
    height: max-content;
    min-height: 437px;
    background-color: white;
    position: fixed;
    opacity: 0;
    z-index: -10;
    top: 50%;
    left: -250%;
    transform: translate(-50%,-50%);
    border: 2px solid black;
}
.result_modal_subscriptions.active {
    z-index: 11;
    opacity: 1;
    transition: opacity 1s ease 0s;
    border: 1px solid black;
    left: 50%;
}
.result_modal_subscriptions_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 30px;
    flex-direction: column;
}
.result_modal_subscriptions_close {
    display: flex;
}
.subscriptions_modal_exit_div {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    margin-left: auto;
}
.subscriptions_modal_exit_div::after {
    content: "";
    width: 39px;
    height: 2px;
    position: absolute;
    left: -4px;
    top: 15px;
    transform: rotate(45deg);
    background-color: black;
}
.subscriptions_modal_exit_div::before {
    content: "";
    width: 39px;
    height: 2px;
    position: absolute;
    left: -4px;
    top: 15px;
    transform: rotate(-45deg);
    background-color: black;
}
.subscriptions_result_content {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: 30px;
}
.subscriptions_result_content_chilld {
    display: flex;
    max-width: 536px;
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
}
.subsc_success_check_mark {
    border: 3px solid;
    display: flex;
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 50%;
}
.subsc_success_check_mark::after {
    content: "";
    display: flex;
    height: 52px;
    width: 4px;
    background-color: #000000;
    position: absolute;
    top: 22px;
    left: 58px;
    transform: rotate(45deg);
    border-radius: 10px;
}
.subsc_success_check_mark::before {
    content: "";
    display: flex;
    height: 26px;
    width: 4px;
    background-color: #000000;
    position: absolute;
    top: 44px;
    left: 33px;
    transform: rotate(-45deg);
    border-radius: 10px;
}
.subsc_successfully_sent {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    color: #1A1A1A;
    margin-top: 48px;
}
.container_sent_button {
    display: flex;
    justify-content: center;
    width: 100%;
}
.subsc_successfully_sent_button {
    max-width: 461px;
    width: 100%;
    min-height: 50px;
    background: #1A1A1A;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    /*text-transform: uppercase;*/
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    text-decoration: none;
}


@media (max-width: 768px) {
    .result_modal_subscriptions.active {
        max-width: 300px;
        min-height: 300px;
    }
    .subscriptions_modal_exit_div::before {
        width: 30px;
        left: 0;
    }
    .subscriptions_modal_exit_div::after {
        width: 30px;
        left: 0;
    }
    .result_modal_subscriptions_wrapper {
        padding: 10px;
    }
    .subscriptions_result_content {
        margin-top: 20px;
    }
    .subsc_success_check_mark {
        width: 80px;
        height: 80px;
    }
    .subsc_success_check_mark::before {
        height: 21px;
        top: 34px;
        left: 24px;
    }
    .subsc_success_check_mark::after {
        height: 40px;
        top: 18px;
        left: 44px;
    }
    .subsc_successfully_sent {
        margin-top: 15px;
        font-size: 22px;
        line-height: 26px;
    }
    .subsc_successfully_sent_button {
        margin-top: 25px;
    }



}