.reviews_cards_container_parent {
    padding: 18px 40px;
    border:  1px solid #000000;
    background: white;
    margin-top: 40px;
}
.reviews_cards_container_parent:first-child {
    margin-top: 0px;
}
.reviews_cards_container {
    /*max-width: 920px;*/
    /*width: 100%;*/
    /*margin: 0 auto;*/
    /*margin-top: 50px;*/
    /*border-bottom:  1px solid #E0E0E0;*/
}
.reviews_name_and_city {
    display: flex;
    align-items: baseline;
}
.reviews_name {
    font-weight: 500;
    font-size: 24px;
    color: #000000;
}
.reviews_city {
    font-weight: 400;
    font-size: 14px;
    color: #8B8B8B;
    margin-left: 10px;
}
.reviews_cards_child_border {
    /*padding-bottom: 50px;*/
    margin-top: 20px;
}
.reviews_cards_child {
    /*min-height: 255px;*/
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /*background: #FFFFFF;*/
    /*border: 1px solid #E0E0E0;*/
    /*box-sizing: border-box;*/
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
}
.reviews_cards_child_left {
    /*max-width: 683px;*/
    /*width: 100%;*/
    /*padding: 20px 0px 30px 28px;*/
    /*border-right: 1px solid #E0E0E0;*/
    /*display: flex;*/
    /*flex-direction: column;*/
}
.reviews_cards_data {
    font-weight: 400;
    font-size: 14px;
    color: #8B8B8B;
    margin-left: 18px;
}
.reviews_cards_review {
    max-width: 521px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #757575;
}
.reviews_right_alignment {
    margin-top: 10px;
    display: flex;
}
.reviews_cards_border {
    display: flex;
    /*justify-content: flex-end;*/
    /*align-items: center;*/
    flex-direction: column;
    max-width: 541px;
    margin-left: auto;
    margin-top: 18px;
    padding-left: 10px;
    border-left: 1px solid #E0E0E0;
}
.reviews_cards_border_left {
    /*max-width: 54px;*/
    /*width: 100%;*/
    /*height: 1px;*/
    /*background-color: #E0E0E0;*/
}
.answer_name_shop {
    font-weight: 500;
    font-size: 14px;
    color: #1A1A1A;
    /*max-width: 157px;*/
    /*width: 100%;*/
}
.reviews_cards_border_right {
    /*max-width: 433px;*/
    /*width: 100%;*/
    /*height: 1px;*/
    /*background-color: #E0E0E0;*/
}
.reviews_cards_answer {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #757575;
}
.reviews_grade_and_data {
    /*max-width: 237px;*/
    /*width: 100%;*/
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    margin-top: 18px;
}
.reviews_grade_container {
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
}
.reviews_cards_grade {
    font-weight: 400;
    font-size: 14px;
    color: #757575;
}
.reviews_cards_grade_points {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
    margin-left: 10px;
}


@media (max-width: 1440px) {


}

@media (max-width: 1024px) {

    .reviews_cards_child_border {
        margin-top: 18px;
    }

}

@media (max-width: 768px) {

    .reviews_cards_container_parent {
        padding: 20px;
        margin-top: 20px;
    }
    .reviews_name {
        font-size: 18px;
    }
    .reviews_city {
        font-size: 12px;
    }
    .reviews_grade_and_data {
        margin-top: 10px;
    }
    .reviews_cards_grade {
        font-size: 12px;
    }
    .reviews_cards_grade_points {
        font-size: 14px;
    }
    .reviews_cards_data {
        margin-left: 20px;
        font-size: 12px;
    }
    .reviews_cards_child_border {
        margin-top: 20px;
    }
    .reviews_cards_review {
        font-size: 12px;
    }
    .reviews_cards_border {
        margin-top: 10px
    }
    .reviews_right_alignment {
        margin-top: 10px;
    }
    .reviews_cards_answer {
        font-size: 12px;
    }
}

