.user-bonuses {
    max-width: 1000px;
}
.user-bonuses__top {
    display: flex;
    gap: 39px;
    margin-bottom: 70px;
}

.user-bonuses__title {
    color: #1A1A1A;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.8px;
}

.user-bonuses__top-image {
    display: block;
}

.user-bonuses__top-image._mobile {
    display: none;
}

.user-bonuses__top-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.user-bonuses__top-title {
    margin-top: 0;
    color: #1A1A1A;
    font-size: 20px;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.user-bonuses__top-count {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1A1A1A;
    font-size: 66px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.98px;
}

.user-bonuses__top-soon {
    margin-top: 9px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(26, 26, 26, 0.50);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
}

.user-bonuses__top-info {
    margin-top: auto;
    opacity: 0.5;
}

.user-bonuses__top-infoText {
    margin-bottom: 0;
    margin-top: 7px;
    color: #1A1A1A;
    font-size: 14px;
    max-width: 207px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 22.4px */
}

.user-bonuses__history {
    
}

.user-bonuses__history-title {
    margin-bottom: 20px;
}

.user-bonuses__history-wrapper {
    max-width: 764px;
}

.user-bonuses__history-table {
    padding: 17px 24px 37px 24px;
    border: 1px solid #E0E0E0;
    border-bottom: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.user-bonuses__history-table._collapsed {
    border-bottom: 1px solid #E0E0E0;
}

.user-bonuses__history-head {
    display: flex;
    gap: 10px;
    opacity: 0.3;
    margin-bottom: 24px;
    align-items: flex-start;
}


.user-bonuses__history-body {
    color: #757575;
}

.user-bonuses__history-row {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.user-bonuses__history-row + .user-bonuses__history-row {
    margin-top: 19px;
}

.user-bonuses__history-row._replenishment {
    color: #000000;
}

.user-bonuses__history-row._replenishment:not(._pending) div:nth-child(3) {
    color: #55B104;
}

.user-bonuses__history-row._debited {
    color: #000000;
}

.user-bonuses__history-row._debited:not(._pending) div:nth-child(3) {
    color: #EB001B;
}

.user-bonuses__history-row._unusable {
    opacity: 0.3;
}

.user-bonuses__history-row._pending {
    color: #757575;
}

.user-bonuses__history-head div {
    
}

.user-bonuses__history-head div:nth-child(1) {
    flex-basis: 115px;
}

.user-bonuses__history-head div:nth-child(2) {
    flex-basis: 371px;
}

.user-bonuses__history-head div:nth-child(3) {
    flex-basis: 85px;
}

.user-bonuses__history-head div:nth-child(4) {
    flex-basis: 146px;
}

.user-bonuses__history-head div:last-child {
    text-align: right;
}

.user-bonuses__history-row div {
    display: flex;
    align-items: center;
    gap: 2px;
    flex-wrap: wrap;
}

.user-bonuses__history-row div:nth-child(1) {
    flex-basis: 115px;
}

.user-bonuses__history-row div:nth-child(2) {
    flex-basis: 371px;
}

.user-bonuses__history-row div:nth-child(3) {
    flex-basis: 85px;
}

.user-bonuses__history-row div:nth-child(4) {
    flex-basis: 146px;
}

.user-bonuses__history-row div:last-child {
    text-align: right;
    justify-content: flex-end;
}

.user-bonuses__history-head div:last-child span:nth-child(2) {
    display: none;
}

.user-bonuses__history-button {
    background: #1A1A1A;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    transition: .3s;
    line-height: normal;
}

.user-bonuses__history-button:hover {
    background: #FFFFFF;
    box-shadow: inset 0 0 0 1px #1A1A1A;
    color: #1A1A1A;
}

@media all and (max-width: 1024px) {
    .user-bonuses__title {
        font-size: 18px;
        letter-spacing: 0.72px;
    }
    
    .user-bonuses__top {
        margin-bottom: 60px;
        gap: 20px;
    }
    
    .user-bonuses__top-content {
        
    }
    
    .user-bonuses__top-title {
        
    }
    
    .user-bonuses__top-count {
        font-size: 46px;
        white-space: nowrap;
        letter-spacing: -1.38px;
    }
    
    .user-bonuses__top-soon {
        margin-top: 11px;
        font-size: 15px;
    }
    
    .user-bonuses__top-info {
        
    }
    
    .user-bonuses__top-infoText {
        font-size: 14px;
    }
    
    .user-bonuses__history {
        
    }
    
    .user-bonuses__history-title {
    
    }
    
    .user-bonuses__history-wrapper {
        
    }
    
    .user-bonuses__history-table {
        font-size: 12px;
        padding: 11px 16px 32px;
    }
    
    .user-bonuses__history-head {
        
    }
    
    .user-bonuses__history-body {
        
    }
    
    .user-bonuses__history-row {
        
    }
    
    .user-bonuses__history-body div {
        
    }
    
    .user-bonuses__history-row div {
        
    }
    
    .user-bonuses__history-button {
        
    }
    
    .user-bonuses__history-head div:nth-child(1),
    .user-bonuses__history-row div:nth-child(1) {
        flex-basis: 97px;
        flex-grow: 1;
    }
    
    .user-bonuses__history-head div:nth-child(2),
    .user-bonuses__history-row div:nth-child(2) {
        flex-basis: 182px;
        flex-grow: 1;
    }
    
    .user-bonuses__history-head div:nth-child(3),
    .user-bonuses__history-row div:nth-child(3) {
        flex-basis: 67px;
        flex-grow: 1;
    }
    
    .user-bonuses__history-head div:nth-child(4),
    .user-bonuses__history-row div:nth-child(4) {
        flex-basis: 144px;
        flex-grow: 1;
    }
}

@media all and (max-width: 767px) {
    .user-bonuses__title {
        font-size: 20px;
        line-height: normal;
    }
    
    .user-bonuses__top {
        margin-bottom: 56px;
        display: block;
    }
    
    .user-bonuses__top-image {
        display: none;
    }
    
    .user-bonuses__top-image._mobile {
        display: block;
        width: 100%;
        height: auto;
        margin: 26px auto 0;
        max-width: 350px;
    }
    
    .user-bonuses__top-content {
        display: block;
    }
    
    .user-bonuses__top-title {
        margin-bottom: 3px;
    }
    
    .user-bonuses__top-count {
        font-size: 60px;
        letter-spacing: -1.8px;
    }
    
    .user-bonuses__top-soon {
        font-size: 16px;
    }
    
    .user-bonuses__top-info {
        margin-top: 25px;
    }
    
    .user-bonuses__top-infoText {
        margin-top: 7px;
    }
    
    .user-bonuses__history {
        
    }
    
    .user-bonuses__history-title {
        
    }
    
    .user-bonuses__history-wrapper {
        
    }
    
    .user-bonuses__history-table {
        font-size: 11px;
        padding: 9px 10px 24px;
    }
    
    .user-bonuses__history-head {
        
    }
    
    .user-bonuses__history-body {
        
    }
    
    .user-bonuses__history-row {
        
    }
    
    .user-bonuses__history-body div {
        
    }
    
    .user-bonuses__history-row div {
        
    }
    
    .user-bonuses__history-button {
        
    }
    
    .user-bonuses__history-head div:nth-child(1),
    .user-bonuses__history-row div:nth-child(1) {
        flex-basis: 72px;
    }
    
    .user-bonuses__history-head div:nth-child(2),
    .user-bonuses__history-row div:nth-child(2) {
        flex-basis: 139px;
    }
    
    .user-bonuses__history-head div:nth-child(3),
    .user-bonuses__history-row div:nth-child(3) {
        flex-basis: 55px;
    }
    
    .user-bonuses__history-head div:nth-child(4),
    .user-bonuses__history-row div:nth-child(4) {
        flex-basis: 64px;
    }
    
    .user-bonuses__history-head div:last-child span:nth-child(1) {
        display: none;
    }
    .user-bonuses__history-head div:last-child span:nth-child(2) {
        display: block;
    }
}

.user-bonuses__level {
    margin-bottom: 110px;
}

.user-bonuses__level-title {
    margin-bottom: 40px;
}

.user-bonuses__level-wrapper {
    display: grid;
    grid-template-columns: 55px 1fr;
    gap: 24px;
}

.user-bonuses__level-name {
    display: flex;
    flex-direction: column;
    min-height: 0;
    justify-content: space-between;
    color: #1A1A1A;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 20.8px */
    opacity: 0.5;
}

.user-bonuses__level-name span:first-child {
    margin-top: 5px;
}

.user-bonuses__level-progressWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
}

.user-bonuses__level-progressItem {

}
.user-bonuses__level-progressItem._fill .user-bonuses__level-progress::before {
    content: "";
    width: 100%;
}
.user-bonuses__level-progressItem._active .user-bonuses__level-progress::before {
    content: '';
}
.user-bonuses__level-progressItem._active .user-bonuses__level-progress span {
    display: flex;
}
.user-bonuses__level-progressItem._finish .user-bonuses__level-progress::before {
    background: #D3FF24;
}
.user-bonuses__level-progressItem._finish .user-bonuses__level-progress span:first-child {
    opacity: 0.5;
}
.user-bonuses__level-progressItem._finish .user-bonuses__level-progress span {
    mix-blend-mode: unset;
    color: #1A1A1A;
    position: relative;
}
.user-bonuses__level-progressItem._active .user-bonuses__level-progress-cashback {
    width: auto;
}
.user-bonuses__level-progressItem._active .user-bonuses__level-info {
    opacity: 1;
}


.user-bonuses__level-info {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    color: #1A1A1A;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.3;
}

.user-bonuses__level-info svg {
    display: block;
    margin-right: 5px;
}

.user-bonuses__level-info svg + span + * {
    margin-left: 13px;
}

.user-bonuses__level-progress-cashback {
    margin-right: 5px;
    opacity: 0.4;
    width: 0;
    overflow: hidden;
}

.user-bonuses__level-progress-cashback + span {
    color: #1A1A1A;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.user-bonuses__level-progress {
    border-radius: 120px;
    height: 35px;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    padding: 14px;
    gap: 7px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    position: relative;
}

.user-bonuses__level-progress span {
    mix-blend-mode: difference;
    display: none;
}

.user-bonuses__level-progress::before {
    position: absolute;
    content: none;
    left: 0;
    top: 0;
    height: 100%;
    background: #1A1A1A;
    border-radius: 120px;
    width: var(--progress);
}

.user-bonuses__level-progressPrice {
    color: #FFF;
}

.user-bonuses__level-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1A1A1A;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.user-bonuses__level-wrapper._tablet {
    display: none;
}

.user-bonuses__level-wrapper._mobile {
    display: none;
}

.user-bonuses__level-wrapper._tablet {
    width: auto;
    height: auto;
    max-width: 100%;
}

.user-bonuses__level-wrapper._mobile {
    width: auto;
    height: auto;
    max-width: 100%;
}

@media all and (max-width: 1390px) {
    .user-bonuses__level-wrapper {
        display: none;
    }
    
    .user-bonuses__level-wrapper._tablet {
        display: block;
    }
}

@media all and (max-width: 1024px) {
    .user-bonuses__level {
        margin-bottom: 100px;
    }
    
    .user-bonuses__level-title {
        margin-bottom: 45px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    
    .user-bonuses__top-count svg {
        width: 52px;
        height: auto;
    }
}

@media all and (max-width: 767px) {
    .user-bonuses__level {
        margin-bottom: 90px;
    }
    
    .user-bonuses__level-title {
    
    }
    
    .user-bonuses__level-wrapper._tablet {
        display: none;
    }
    
    .user-bonuses__level-wrapper._mobile {
        display: block;
        width: 100%;
    }
}