.for_sostav_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1142px;
    width: 100%;
}
.one_sostav_wrapper_item {
    padding: 0px 10px;
}
.one_sostav {
    border: 1px solid #c9cccf;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    max-width: 250px;
    padding: 10px;
}

.controll_panel_sostav_wrapper {
    display: flex;
}
.name_sost {
    font-size: 12px;
    font-weight: 600;
    display: flex;
    margin-top: 10px;
}
.field_sost_name {
    display: flex;
    align-items: center;
}
.sostav_field {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-left: 5px;


}
.add_params {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.10);
    border: 1px solid rgba(128, 128, 128, 0.32);
}
.field_value_container {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.value_field_sost {
    max-width: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-left: 5px;
}
.del_sost {
    width: 20px;
    height: 20px;
    border: 1px solid black;
    cursor: pointer;
    position: relative;
    background-color: rgba(208, 9, 9, 0.23);
}
.del_sost:after{
    content: "";
    position: absolute;
    top: 8px;
    left: 2px;
    height: 2px;
    width: 15px;
    background-color: black;
    transform: rotate(-45deg);
}
.del_sost:before{
    content: "";
    position: absolute;
    top: 8px;
    left: 2px;
    height: 2px;
    width: 15px;
    background-color: black;
    transform: rotate(45deg);
}
.del_sost:hover {
    background-color: black;
    border-color: white;
}
.del_sost:hover:after {
    background-color: white;
}
.del_sost:hover:before {
    background-color: white;
}
.add_params:hover {
    background-color: rgba(128, 128, 128, 0.32)
}
.delete_block {
    background-color: rgba(208, 9, 9, 0.23);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    border: 1px solid black;
    cursor: pointer;
    height: 30px;
    line-height: 10px;
    user-select: none;
}

.delete_block:hover {
    color: white;
    background-color: black;
    border-color: white;
}