.logo_conteiner {

}
.logo_conteiner img {
    max-width: 177px;
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 1024px) {
    .logo_conteiner img {
        max-width: 100px;
    }
}