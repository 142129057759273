.c_one_contacts {
    margin-right: 40px;
    margin-top: 20px;
}
.c_one_contacts_child {
    max-width: 384px;
    min-height: 216px;
    width: 100%;
    height: 100%;
    border: 1px solid #000000;
    /*background: #FFFFFF;*/
    display: flex;
    flex-direction: column;
}
.c_one_contacts_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    padding: 18px 40px;
    border-bottom: 1px solid #000000;
}
.wrap_cards_contact {
    padding: 18px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.c_one_contacts_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
}
.c_one_contacts_button {
    padding: 14px;
    max-width: 143px;
    width: 100%;
    border: 1px solid black;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #1A1A1A;
    cursor: pointer;
}
.c_one_contacts_button:hover {
    color: white;
    background-color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}



@media (max-width: 1024px)  {
   .c_one_contacts {
       margin-top: 40px;
   }
}

@media (max-width: 768px) {

    .c_one_contacts {
        margin-right: 0px;
    }
    .c_one_contacts_title {
        padding: 18px 20px;
    }
    .wrap_cards_contact {
        padding: 18px 20px;
    }
}

@media (max-width: 427px) {
    /*.c_one_contacts {*/
    /*    min-height: 217px;*/
    /*}*/

    /*.c_one_contacts_child {*/
    /*    padding: 25px 19px;*/
    /*}*/
    /*.c_one_contacts_title {*/
    /*    font-size: 18px;*/
    /*}*/
    /*.c_one_contacts_text {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.c_one_contacts_button {*/
    /*    margin-top: 20px;*/
    /*    max-width: 180px;*/
    /*    padding: 14px;*/
    /*    font-size: 12px;*/
    /*}*/

}
