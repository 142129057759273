.root {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #FFFFFF;
    z-index: 9;

}

.list {
    list-style: none;
    width: max-content;
    padding: 0 0 24px;
    margin: -50px 0 0;
}

@media (max-width: 1024px) {
    .list {
        margin-top: -43px;
    }
}

@media (max-width: 1024px) {
    .list {
        margin-top: -10px;
    }
}

.item {
    margin-bottom: 8px;
    transition: .3s;
    background-position: 100% 4px;
    background-repeat: no-repeat;

    display: block;
    padding-bottom: 5px;
    padding-right: 22px;

    border-bottom: 1px solid rgba(26, 26, 26, 0.3);

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    text-decoration: none;

    color: #1A1A1A;
}

.item:hover {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='12' viewBox='0 0 7 12' fill='none'%3E%3Cpath d='M1 1L5.64733 5.6708C5.84211 5.86655 5.84131 6.18314 5.64555 6.3779L1 11' stroke='%231A1A1A' stroke-width='1.32847' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.item:last-child {
    margin-bottom: 0;
}