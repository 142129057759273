.adm_page_delivery {
    padding: 20px;
}
.adm_title_delivery {
    display: flex;
    width: 100%;
    height: 76px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}


.seo_content_delivery {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.one_field_delivery_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
}
.one_field_delivery {
    font-weight: 600;
    font-size: 12px;
}
.delivery_field_adm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.delivery_field_adm.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
}
.delivery_field_adm.textarea_big {
    min-height: 400px;
}

.save_fields_delivery {
    display: flex;
    height: 40px;
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.save_fields_delivery:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_fields_delivery.trig {
    animation: trigerSaveDelivery 2s ease infinite;
}

@keyframes trigerSaveDelivery{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}

.content_delivery_adm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.delivery_block_content{

}
.parents_delivery_region {

}
.button_new_block_del_and_ref {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    max-width: 200px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    margin-top: 10px;
}
.delivery_region_wrapper {

}
.one_delivery_block {
    margin: 20px 0px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid black;
}
.delete_block_delivery {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.del_delivery_child {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
}
.del_delivery_child::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 4px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.del_delivery_child::after {
    content: " ";
    position: absolute;
    width: 24px;
    height: 4px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.del_text_delivery {
    writing-mode: vertical-lr;
    text-orientation: upright;
    cursor: pointer;
    position: relative;
    padding: 5px;
}
.text_repeater {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.block_and_fields_text {
    display: flex;
}
.dop_text_parrent_wrapper{
    display: flex;
    flex-direction: column;
    border: 1px solid #bababa;
    max-width: 350px;
    width: 100%;
    margin: 5px;
    padding: 10px 5px;
}
.msk_fields_text_wrap {
    display: flex;
    flex-direction: column;
}
.dop_text_msk_wrapper {
    border: 1px solid #bababa;
    display: flex;
    width: 100%;
}
.conditions_array {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
}
.delivery_moscow_wrapper {

}
.text_and_del_msk {
    display: flex;
    width: 100%;
}
.dop_text_msk {
    width: 100%;
}
.text_msk_child {
    width: 100%;
    margin-right: 30px;
}
.add_block_delivery {
    width: 100%;
    display: flex;
    justify-content: center;
}
.add_fields_delivery {
    display: flex;
    width: 150px;
    margin-left: 30px;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
}
.add_fields_delivery:hover {
    background-color: rgba(128, 128, 128, 0.32);
    transition: background-color 0.8s ease 0s;
}
.file_return_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 0px 10px;
    min-height: 85px;
}
.loaded_pdf_file {
    margin-top: 10px;
    font-weight: 600;
    font-size: 12px;
}
.name_file_return {
    display: flex;
    align-items: center;
}
.opportunities_upload_file {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.button_view_file {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    max-width: 200px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    text-decoration: none;
    color: black;
}
.button_view_file:hover {
    background: black;
    color: white;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.deleted_file_return {
    display: flex;
    padding: 5px 10px;
    border: 1px solid #CE3F3F;
    border-radius: 5px;
    max-width: 200px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    text-decoration: none;
    color: #CE3F3F;
    margin-left: 20px;
}
.deleted_file_return:hover {
    background: #CE3F3F;
    color: white;
    transition: background 0.4s ease 0s, color 0.4s ease 0s;
}
.not_loaded_file {
    font-weight: 600;
    font-size: 12px;
    margin-top: 10px;
}

.custom_button_wrapper {
    display: flex;
}
.return_buttons_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    min-height: 85px;
}
.activation_return_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}