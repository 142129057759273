.reviews_title {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.workspace_area_reviews {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
}

.wrapper_page_reviews {
    display: flex;
    height: 100%;
    width: 100%;
}
.reviews_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.workspace_area_content {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    align-items: flex-start;
    margin-top: 40px;
    flex-direction: column;
    height: 100%;
}
.controll_btn_area_reviews {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
.add_reviews {
    display: flex;
    padding: 10px 20px;
    background-color: white;
    border: 1px solid black;
    font-weight: 600;
    color: black;
    cursor: pointer;
}
.add_reviews:hover {
    background-color: black;
    color: white;
}
.reviews_area_cont {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sort_btn {
    height: 30px;
    padding: 0px 10px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.left_div {
    display: flex;
}
.id_reviews  {
    display: flex;
    width: 40px;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border: 1px solid black;
}


.delete_me_button {
    display: flex;
    width: 200px;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: white;
    border: 4px solid red;
    cursor: pointer;
    font-weight: 600;
    background-color: black;
    display: none;
}

.content_manager_reviews {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.left_fields_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    /*background-color: pink;*/
}

.left_wrapper_reviews {
    max-width: 50%;
    display: flex;
    width: 100%;
    flex-direction: column;
}

.right_wrapper_riviews {
    position: relative;
    display: flex;
    width: 300px;
    max-height: 250px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
}
.save_button_reviews_content {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 42px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
}

.save_button_reviews_content:hover {
    color:white;
    background-color: black;
    transition: color 0.8s ease 0s, background-color 0.8s ease 0s;
}
