.one_item_controll_tab_adm {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    box-shadow: 0 0 5px rgba(23, 24, 24, 0.05), 0 1px 2px rgba(0, 0, 0, 0.15);
    margin-left: 15px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    font-weight: 600;
    position: relative;

}
.change_container_product_tab.red {
    background-color: #E69191;

}
.one_item_controll_tab_adm.active {
    background-color: #BCBCBC;
    transition: background-color 0.4s ease 0s;
}

.one_item_controll_tab_adm:hover {
    background-color: #BCBCBC;
    transition: background-color 0.4s ease 0s;
}
.one_item_controll_delete {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: rgba(254, 2, 45, 0.27);
    top: -5px;
    right: -5px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    transform: rotate(45deg);
    user-select: none;
}

.one_item_controll_delete:hover {
    background-color: red;
}

.change_container_product_tab {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 7px 11px;
}