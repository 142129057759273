.page_adm_payment {
    padding: 20px;
}
.adm_title_payment {
    display: flex;
    width: 100%;
    height: 76px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.seo_content_payment {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.one_field_payment_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.one_field_payment {
    font-weight: 600;
    font-size: 12px;
}
.payment_field_adm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.payment_field_adm.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
}
.payment_field_adm.textarea_big {
    min-height: 400px;
}
.step_payment_wrapper {

}
.save_seo_payments {
    display: flex;
    height: 40px;
    width: 500px;
    margin: 0 auto;
    margin-top: 20px;
    background: white;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    font-weight: 600;
    cursor: pointer;
}
.save_seo_payments:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_seo_payments.trig {
    animation: trigerSavePayments 2s ease infinite;
}

@keyframes trigerSavePayments{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}
.container_payment_content_adm {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.button_new_step {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
    margin-top: 10px;
}
.parents_pay_step {
    display: flex;
}
.one_step_payment {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
}

.onestep_pay_delete {
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.child_pay_delete {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.child_pay_delete::before {
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.child_pay_delete::after {
    content: " ";
    position: absolute;
    width: 24px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #000000;
    top: 12px;
    right: 0;
}
.step_pay_wrapper {
    margin-top: 20px;
}
.container_img_pay {
    height: 250px;
}
.pay_img_wrapper {
    display: flex;
    flex-wrap: wrap;
}