.about_Us_wrapper {
    max-width: 1440px;
    padding: 0px 40px;
    width: 100%;
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
}
.left_about_us {
    max-width: 660px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 44px 60px 20px;
    border: 1px solid black;
}

.left_about_us h1 {
    font-weight: 500;
    font-size: 30px;
    color: #000000;
}
.adv_wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    justify-content: space-between;
}
.left_bottom_text {
    margin-top: 92px;
}
.left_bottom_text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #7C7C7C;
    white-space: pre-line;
}

.right_about_us {
    max-width: 660px;
    width: 100%;
    padding: 40px 25px 47px 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    margin-right: 40px;
}

.heading_span {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
    margin-top: 85px;
}
.heading_span:first-child {
    margin-top: 0px;
}
.right_about_us_first p{
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #7C7C7C;
    white-space: pre-line;
}



@media (max-width: 1024px) {
    .about_Us_wrapper {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 80px;
        margin-bottom: 80px;
        margin-right: 0px;
    }
    .adv_wrapper {
        max-width: 560px;
        justify-content: center;
    }
    .left_about_us {
        align-items: start;
        max-width: unset;
        margin-top: 30px;
        padding: 20px;
    }
    .left_about_us h1 {
        font-size: 18px;
        width: 100%;
        text-align: start;
    }
    .adv_wrapper {
        justify-content: space-between;
        margin-top: 0px;
    }
    .left_bottom_text {
        margin-top: 25px;
    }
    .left_bottom_text {
        text-align: start;
        width: 100%;
    }
    .right_about_us {
        max-width: unset;
        margin-top: 20px;
        text-align: start;
        margin-right: 0px;
        padding: 20px;
    }
    .right_about_us_two {
        margin-top: 20px;
    }
    .heading_span {
        font-size: 18px;
        line-height: 23px;
    }


}

@media (max-width: 768px) {
    .about_Us_wrapper {
        margin-top: 20px;
        margin-bottom: 60px;
        padding: 0px 20px;
    }
    .adv_wrapper {
        max-width: 100%;
        flex-direction: column;
    }
    .left_bottom_text {
        margin-top: 25px;
    }
    .left_bottom_text p{
        font-size: 14px;
        line-height: 18px;
    }
    .right_about_us_first p{
        font-size: 14px;
        line-height: 18px;
    }
    .right_about_us_two p {
        font-size: 14px;
        line-height: 18px;
    }
}
