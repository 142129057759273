.one_step_basket_container {
    display: flex;
    flex-direction: column;
    border-top: 2px solid black;
}
.wrapper_price_basket {
    padding:  24px;
}
.one_step_choose_product{
    font-size: 16px;
font-weight: 500;
line-height: 21px;
margin-bottom: 16px;
}
.one_step_order_price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
}
.one_step_price_string {
    font-weight: 600;
}
.basket_price_info {
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #CE3F3F;
}
.one_step_order_price.sales {
    margin-top: 18px;
}
.one_step_result_price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}
.one_step_result_price_text {
    font-weight: 400;
    font-size: 30px;
    color: #757575;
}
.one_step_result_price_sum {
    font-weight: 500;
    font-size: 30px;
    text-align: right;
    letter-spacing: 0.04em;
    color: #000000;
}
.button_next_step_basket {
    padding: 18px;
    /* border-top: 2px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.one_step_split_wrapper{
margin-top: 16px;
display: flex;
flex-direction: column;
color: #757575;
gap:16px;
}
.one_step_dot{
    
}

.one_step_btns_block{
    display: flex;
    align-items: center;
}
.one_step_btns_block button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.one_step_btn_first {
    margin-right: 4px;
}

.one_step_btns_black{
    display: inline-block;
    margin: 0 13px 0 8px;
font-weight: 500;
color: #000;
}

.one_step_description{
    font-size: 14px;
    line-height: 145%;
    max-width: 304px;
    text-align: right;
    margin-left: auto;
}
.one_step_split_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.floating_button_wrapper {
    padding: 18px;
    border-top: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    background: white;
    opacity: 1;
}
.floating_button_wrapper.hide {
    opacity: 0;
}
.floating_button {
    width: 100%;
    min-height: 50px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.one_step_go_order_page {
    max-width: 640px;
    width: 100%;
    min-height: 50px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
.one_step_go_order_page.disabled {
    background: #757575;
    cursor: default;
    justify-content: center;
}
.one_step_go_order_page:hover {

}

.used_promotions {
    margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    color: #757575;
}

.used_promotions .info {
    font-size: 14px;
    margin-top: 10px;
    color: green;
}

@media (max-width: 1024px) {
    .basket_price_info {
        text-align: start;
    }
    .one_step_result_price_text {
        font-size: 24px;
    }
    .one_step_result_price_sum {
        line-height: 31px;
    }
    .button_next_step_basket {
        padding: 18px 40px;
    }
    .floating_button {
        max-width: 470px;
    }
}


@media (max-width: 768px)  {
    .floating_button_wrapper {
        padding: 0px;
    }
    .floating_button {
        max-width: 100%;
        height: 100%;
    }
    .wrapper_price_basket {
        padding: 20px 10px;
    }
    .one_step_order_price {
        font-size: 14px;
    }
    .one_step_order_price.sales {
        margin-top: 20px;
    }
    .one_step_result_price {
        margin-top: 20px;
    }
    .one_step_result_price_text {
        font-size: 18px;
    }
    .one_step_result_price_sum {
        font-size: 18px;
    }
    .button_next_step_basket {
        padding: 20px 10px;
    }
}


@media (max-width: 380px)  {

}
