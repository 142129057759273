.wrapper_request {
    width: 100%;
    height: 100%;
}
.result_request {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.wrap_result_request {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1030px;
    padding: 40px;
}
.wrap_result_request svg {
    margin-top: 25px;
}
.result_request_title {
    font-weight: 500;
    font-size: 26px;
    text-align: center;
    color: #000000;
}

@media (max-width: 768px) {
    .wrapper_request {
        height: auto;
    }
    .result_request_title {
        font-size: 16px;
    }
    .wrap_result_request {
        padding: 20px;
    }
    .wrap_result_request svg {
        max-width: 100px;
        max-height: 100px;
    }

}