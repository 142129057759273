
.app_telephone_auth {
    position: fixed;
    transition: right 1s ease 0s;
    z-index: 12;
    padding: 20px 0px;
    max-width: 559px;
    width: 100%;
    /*height: 100vh;*/
    height: 100%;
    top: 0;
}

.app_telephone_auth.hide {
    right: -250%;
    transition: right 1s ease 0s;
}


.wrapper_modal {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    border: 1px solid black;
}

.closeModal {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}
.closeModal_child {
    width: 36px;
    height: 36px;
    position: relative;
    cursor: pointer;
}
.closeModal_child:after {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background: #1A1A1A;
    transform: rotate(45deg);
    top: 18px;
    left: 9px;
}

.closeModal_child:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background: #1A1A1A;
    transform: rotate(-45deg);
    top: 18px;
    left: 9px;
}

.wrap_step_components {
    /*max-width: 461px;*/
    width: 100%;
    margin: 0 auto;
    height: 100%;
    /*min-height: 461px;*/
    position: relative;
    display: flex;
}
.registration_form {
    position: absolute;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /*border: 1px solid black;*/
    width: 100%;
    margin: 0 auto;
    left: 0%;
    opacity: 1;
    transition: left 0.3s ease 0s, opacity 0.5s ease 0.3s;
}
.registration_form.unactive {
    /*opacity: 0;*/
    left: 240%;
    transition: left 0.5s ease 0.5s, opacity 0.2s ease 0s;
    opacity: 0;
}
.registration_title_wrapper {
    border-bottom: 1px solid black;
    padding: 18px;
    padding-left: 40px;
}
.big_registration_text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #1A1A1A;
}
.fields_register_and_politic{
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fields_register_wrapper {
    margin-top: 45px;
}
@media (max-width: 600px) {
    .fields_register_wrapper {
        margin-top: 75px;
    }
    .fields_register_and_politic {
        justify-content: flex-start;
    }
}
.title_fields_phone {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.small_text_registration {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}
.registration_select_class {
    position: relative;
    display: flex;
    align-items: center;
    height: 44px;
    border: 1px solid #D7D6D6;
    margin-top: 7px;
}
.pre_order_fields_wrapper .registration_select_class {
    border: none;
}

.pre_order_fields_wrapper .registration_select_class input{
    height: 100%;
}

.phone_and_city_wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #D7D6D6;
    padding: 0px 18px;
}

.pre_order_fields_wrapper .phone_and_city_wrap{
    border-right: none;
    border-bottom: 1px solid #000000;
}

.searchResults {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 140px;
    overflow-y: scroll;
    opacity: 0;
    user-select: none;
    z-index: -1;
    padding-right: 10px;
}

.searchResults::-webkit-scrollbar {
    background-color: #F4F4F4;
    width: 5px;
    border-radius: 10px;
}
.searchResults::-webkit-scrollbar-thumb {
    background-color: #B5B5B5;
    border-radius: 10px;
}


.searchResults.active {
    z-index: 1;
    user-select: auto;
    opacity: 1;
    background: white;
    transition: opacity 0.3s ease 0s;
    padding: 0px 5px;
    border: 1px solid #D7D6D6;
}


.countryItem {
    display: flex;
    align-items: center;
    padding: 5px 2px;
    cursor: pointer;
}

.countryItem:hover {
    background: lightgray;
}

.countryImage {
    width: 29px;
    height: 20px;
}

.selected_input_wrapper {
    width: 100%;
    /*padding-bottom: 20px;*/
    /*border-bottom: 1px solid black;*/
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-right: 20px;
}

.selected_input_wrapper:after{
    content: "";
    width: 2px;
    height: 13px;
    background: #D7D6D6;
    border-radius: 15px;
    position: absolute;
    top: 5px;
    right: 0px;
    transform: rotate(45deg);
    transition: top 1s ease 0s;
}

.selected_input_wrapper:before {
    content: "";
    width: 2px;
    height: 13px;
    background: #D7D6D6;
    border-radius: 15px;
    position: absolute;
    top: 5px;
    right: 8px;
    transform: rotate(-45deg);
    transition: top 1s ease 0s;
}

.selected_input_wrapper:hover {
    border-color: gray;
    transition: border-color 0.8s ease 0s;
}

.selected_input_wrapper:hover::before {
    top: 12px;
    transition: top 1s ease 0s;
}

.selected_input_wrapper:hover::after {
    top: 12px;
    transition: top 1s ease 0s;
}

.selected_input_wrapper span {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    margin-left: 30px;
}
.phoneInput {
    /*margin-top: 40px;*/
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    /*padding-bottom: 19px;*/
    /*border-bottom: 1px solid #000000;*/
    outline: none;
    margin-left: 13px;
}
.countryName {
    margin-left: 30px;
    font-size: 14px;
}

.send_code {
    width: 100%;
    height: 50px;
    color: #FFFFFF;
    font-size: 13px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    background: #1A1A1A;
    user-select: auto;
    cursor: pointer;
    margin-top: 18px;
    transition: background 0.4s ease 0s;

}
.send_code.unactive {
    background: #757575;
    transition: background 0.4s ease 0s;
    user-select: none;
    pointer-events: none;
}
.wrapSearchInput {
    width: 100%;
    height: 42px;
    border-bottom: 2px solid lightgray;
    position: relative;
    margin-bottom: 5px;
}
.searchInput {
    width: 100%;
    height: 100%;
    line-height: 40px;
    text-align: left;
    letter-spacing: 0.03em;
    font-weight: 600;
    font-size: 15px;
    position: relative;
}

.searchInput:focus-visible {
    outline: none;
}
.wrapSearchInput:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0px;
    height: 2px;
    width: 0px;
    background: black;
    transition: width 0.4s ease 0s;
}

.wrapSearchInput:hover:after {
    width: 100%;
    transition: width 0.4s ease 0s;
}
.wrapSearchInput:focus-visible:after {
    width: 100%;
    transition: width 0.4s ease 0s;
}



.auth_step {
    position: absolute;
    width: 100%;
    height: 100%;
    /*background: #af4545;*/
    left: 0px;
    /*transition: left 0.5s ease 0.5s;*/
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: left 0.3s ease 0s, opacity 0.5s ease 0.3s;
}

.auth_step.unactive {
    left: 240%;
    transition: left 0.5s ease 0.5s, opacity 0.2s ease 0s;
    opacity: 0;
}
.number_step_fields {
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.number_and_step {
    display: flex;
    /*margin-top: 37px;*/
    flex-direction: column;
}
.user_info_number {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.1em;
}
.user_info_number_underline {
    padding-bottom: 1px;
    margin-left: 10px;
    border-bottom: 1px solid black;
}
.goRegistrationStep {
    margin-top: 5px;
    color: gray;
    font-size: 14px;
    letter-spacing: 0.03em;
    font-weight: 400;
    cursor: pointer;
    transition: color 0.4s ease 0s;
    display: flex;
}
.goRegistrationStep:hover {
    color: black;
    transition: color 0.4s ease 0s;
}
.title_input_masc_auth {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
}
.auth_sms_code {
    width: 100%;
    margin-top: 7px;
    border: 1px solid #D7D6D6;
    padding-bottom: 1px;
    font-size: 32px;
    letter-spacing: 0.4em;
    caret-color: lightgray;
    padding-left: 5px;
}

.auth_sms_code:focus-visible {
    outline: none;

}
.auth_sms_code::placeholder {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #757575;
    letter-spacing: 0.03em;
}
.counter_sms {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    /*max-width: 320px;*/
    margin-top: 25px;
}
.error_message_sms {
    height: 55px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #CE3F3F;
}

.counter_sms_get_code {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    opacity: 0.3;
    cursor: pointer;
    margin-top: 18px;
    display: flex;
    transition: opacity 0.4s ease 0s;
    user-select: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #757575;
}
.counter_sms_get_code.active {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}

.auth_code_button {
    margin-top: 18px;
    display: flex;
    width: 100%;
    height: 57px;
    background: #757575;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    user-select: none;
    pointer-events: none;
    transition: background 0.4s ease 0s;
}

.auth_code_button.active {
    background: #1A1A1A;
    user-select: auto;
    pointer-events: auto;
    transition: background 0.4s ease 0s;
}



.welcome_step {
    position: absolute;
    width: 100%;
    height: 100%;
    /*height: 200px;*/
    /*background: #af4545;*/
    left: 0px;
    opacity: 1;
    transition: left 0.3s ease 0s, opacity 0.5s ease 0.3s;
}

.welcome_step.unactive {
    opacity: 0;
    left: 240%;
    transition: left 0.3s ease 0s, opacity 0.5s ease 0.3s;
}
.container_text_sale {
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.add_profile_info {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 20px;
    display: flex;
    margin-left: 2px;
}
.welcome_banner {
    max-width: 371px;
    width: 100%;
    height: 189px;
    display: flex;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);*/
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.2s ease 0s;
    margin: 0 auto;
    padding: 18px;
    margin-bottom: 20px;
}

.welcome_banner img {
    max-width: 260px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fix_size_wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.container_title_sale {
    padding: 18px 40px;
    border-bottom: 1px solid black;
}
.go_profile_info {
    display: flex;
    width: 100%;
    height: 50px;
    background: #1A1A1A;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 100px;
    transition: transform 0.1s ease 0s;
}
.go_profile_info:hover {
    transform: scale(1.02);
    transition: transform 0.1s ease 0s;
}

.full_modal_wrapper {
    display: flex;
    height: 100%;
    width: 100%;
}
.modal_auth_politic {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #757575;
}
.modal_auth_politic.hide {
    display: none;
}
.modal_auth_politic_link {
    color: black;
    text-decoration: none;
}
.modal_auth_politic_link:hover {
    border-bottom: 1px solid black;
    transition: border 0.4s ease 0s;
}

@media (max-width: 1024px) {
    .app_telephone_auth {
        right: 50%;
        transform: translate(50%, 0%);
    }
    .closeModal {
        top: 20px;
        right: 17px;
    }
}

@media (max-width: 768px) {
    .app_telephone_auth {
        padding: 20px;
    }
    .phoneInput {
        font-size: 12px;
    }
    .wrapper_modal {

    }
    .registration_title_wrapper {
        padding: 20px;
    }
    .big_registration_text {
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
    }
    .closeModal {
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
    }
    .closeModal_child:after {
        position: absolute;
        content: "";
        width: 16px;
        height: 2px;
        border-radius: 10px;
        background: #1A1A1A;
        transform: rotate(45deg);
        top: 6px;
        left: 0px;
    }

    .closeModal_child:before {
        position: absolute;
        content: "";
        width: 16px;
        height: 2px;
        border-radius: 10px;
        background: #1A1A1A;
        transform: rotate(-45deg);
        top: 6px;
        left: 0px;
    }
    .fields_register_and_politic {
        padding: 20px;
    }
    .small_text_registration {
        font-size: 14px;
    }
    .phone_and_city_wrap {
        padding: 0px 13px;
    }
    .number_step_fields {
        padding: 20px;
    }
    .user_info_number {
        flex-direction: column;
        font-size: 14px;
    }
    .user_info_number_underline {
        margin-left: 0px;
        width: max-content;
        margin-top: 5px;
    }
    .goRegistrationStep {
        font-size: 12px;
    }
    .counter_sms {
        font-size: 14px;
    }
    .auth_sms_code {
        font-size: 28px;
    }
    .auth_sms_code::placeholder {
        font-size: 16px;
    }
    .auth_code_button {
        height: 50px;
    }
    .container_title_sale {
        padding: 20px;
    }
    .big_registration_text {
        font-size: 16px;
        line-height: 21px;
    }
    .container_text_sale {
        padding: 20px;
    }
    .add_profile_info {
        margin-top: 0px;
        font-size: 14px;
        line-height: 26px;
    }
    .welcome_banner {
        height: 158px;
    }

    .countryName {
        font-size: 12px;
        margin-left: 15px;
    }

}


@media (max-width: 428px)  {
    .registration_title_wrapper {
        padding: 15px;
    }
    .closeModal {
        top: 5px;
        right: 5px;
    }
    .closeModal_child {
        width: 15px;
        height: 15px;
    }
    .number_step_fields {
        padding: 10px;
    }
    .counter_sms {
        margin-top: 15px;
        line-height: 16px;
        font-size: 12px;
    }
    .title_input_masc_auth {
        font-size: 14px;
    }
    .auth_sms_code {
        font-size: 24px;
    }
    .auth_code_button {
        height: 40px;
    }
    .counter_sms_get_code {
        margin-top: 5px;
        font-size: 14px;
    }
    .error_message_sms {
        height: 40px;
        font-size: 12px;
    }
}

