.select_pay_type_page {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: 25px;
    padding: 0px 60px 40px 40px;
    flex-direction: column;
    justify-content: space-between;
}
.select_pay_description{
    background: rgba(0, 0, 0, 0.05);
    padding: 16px;
    line-height: 145%;
   max-width: 445px;
    margin-top: 18px;
}
.select_pay_description p{
     color: rgba(0, 0, 0, 0.3);
    margin: 0;
}
.select_pay_description span{
  color:#000;
}
.select_pay_type_heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.payment_methods_wrap {
    display: flex;
    margin-top: 40px;
}

.select_pay_inform_line {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;

}
.all_pay_methods_for_select {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 63px;
    padding: 10px;
    /*border: 2px solid #E0E0E0;*/
}
.all_pay_methods_for_select.active{
    /*border: 2px solid #000000;*/
    /*transition: border 0.2s ease 0s;*/
}
.payment_content {
    display: flex;
    align-items: center;
}
.indicator_active_payment {
    display: flex;
    width: 22px;
}
.indicator_payment_circle {
    display: flex;
    width: 22px;
    height: 22px;
    border: 1px solid #B6B6B6;
    margin-top: 2px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}
.active_payment_circle {
    display: flex;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 50%;
}
.payment_select_button:hover .active_payment_circle {
    background-color: gray;
}
.payment_select_button.active .active_payment_circle {
    background-color: black;
}

.title_option_pay {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    margin-left: 10px;
}
.title_option_pay--ysplit {
    width: 51px;
    margin-left: 5px;
}
.dop_text_payment {
    max-width: 730px;
    font-size: 16px;
    text-decoration: none;
    margin-top: 7px;
    margin-left: 27px;
}
.dop_text_grey {
    font-weight: 400;
    color: #757575;
}
.dop_text_bold {
    font-weight: 500;
    color: #1A1A1A;
    text-decoration: none;
}
.dop_text_link {
    font-weight: 500;
    color: #1A1A1A;
    text-decoration: none;
}
.dop_text_link:hover {
    text-decoration: underline;
}
.dop_text_black {
    font-weight: 400;
    color: #1A1A1A;
}

.payment_select_button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;
    margin-top: 18px;
}

.payment_select_button:first-child {
    margin-top: 0px;
}
.payment_select_button.active {
    border-color: #1A1A1A;
}
.select_payment_type_error {
    color: #CE3F3F;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}
.select_politic_type_error {
    color: #CE3F3F;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px;
    height: 30px;
    display: flex;
}


@media (max-width: 1024px) {
    .select_pay_inform_line {
        font-size: 16px;
    }
    .dop_text_payment {
        max-width: 440px;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 768px)  {
    .select_pay_type_page {
        margin-top: 0px;
        padding: 0px 20px 40px 20px;
    }
    .payment_methods_wrap{
        flex-direction: column;
    }
    .all_pay_methods_for_select {
        margin-left: 0px;
    }
    .payment_select_button {
        justify-content: flex-start;
    }
    .payment_select_button {
        margin-top: 18px;
    }
    .payment_select_button:first-child {
        margin-top: 20px;
    }
    .select_payment_type_error {
        margin-top: 5px;
    }
    .dop_text_payment {
        margin-left: 33px;
    }
}
@media (max-width: 428px) {

}

