.link_politic {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /*text-align: center;*/
    color: #C7C7C7;
    display: flex;
    align-items: center;
}
.link_politic span{
    color: #C7C7C7;
    margin-left: 15px;
    text-align: left;
}
.link_politic a{
    color: black;
    text-decoration: none;
}
.link_politic a:hover{
    border-bottom: 1px solid black ;
}

.checkbox_selector {
    width: 24px;
    height: 24px;
    /*border-radius: 50%;*/
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 24px;
}
.child_checkbox_selector {
    width: 24px;
    height: 24px;
    background: #1A1A1A;
    /*border-radius: 50%;*/
    opacity: 0;
    transition: opacity 0.4s ease 0s;
    position: relative;
}
.child_checkbox_selector:before {
    position: absolute;
    content: "";
    width: 6px;
    height: 2px;
    background: #ffffff;
    transform: rotate(45deg);
    top: 12px;
    left: 6px;
}
.child_checkbox_selector:after {
    position: absolute;
    content: "";
    width: 9px;
    height: 2px;
    background: #ffffff;
    transform: rotate(-45deg);
    top: 11px;
    left: 9px;
}
.checkbox_selector.active .child_checkbox_selector {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}