.fashion {
  padding: 40px;
  background-color: #fff;
  width: 100%;
  max-width: 1376px;
  margin-inline: auto;
  position: relative;
  z-index: 10;

  .spin_container {
    position: relative;
    height: 100%;
  }

  &__wrapper {
    aspect-ratio: 1296/781;
    display: flex;
    gap: 17px;
  }

  &__img {
    position: relative;
    display: block;
    flex-basis: 781px;
    min-width: 0;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 100%;
    }
  }

  &__name {
    display: block;
    position: absolute;
    text-decoration: none;
    bottom: 20px;
    left: 20px;
    background: #000;
    color: #fff;
    padding: 16px;
    border-radius: 16px;
  }

  &__aside {
    min-width: 0;
    flex-basis: 498px;
    min-width: 420px;
    display: flex;
    flex-direction: column;

    &-count {
      margin-top: 0;
      padding-bottom: 18px;
      margin-bottom: 12px;
      border-bottom: 1px solid #000000;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.4;
      color: #000000;
    }
  }

  &__list {
    overflow-y: scroll;
    scrollbar-color: #191919 #cecece;
    scrollbar-width: thin;

    &--scroll {
      &::-webkit-scrollbar {
        background: #cecece;
        width: 19px;
        border-left: 17px solid #fff;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #191919;
        border-left: 17px solid #fff;
      }
    }
  }

  &__modal {
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
      outline: none;

      svg {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.one_product_wrapper.one_product_fashion {
  & + & {
    margin-top: 20px;
  }
  display: block;
  max-width: 100%;
  width: 100%;
  padding: 0px;
  margin: 0;

  .one_product {
    padding: 0;
    flex-direction: row;
    gap: 20px;
  }

  .image_container {
    display: block;
    aspect-ratio: 153/230;
    flex-basis: 153px;
    flex-shrink: 0;
  }

  .sale__label {
    // display: none;
  }

  .dop_controll_bar_product {
    display: none;
  }

  .container_wrapper_product {
    flex-basis: 306px;
    min-height: 0;
    justify-content: unset;
    padding: 0px;
  }

  .previews_info_product_wrap {
    justify-content: start;
  }

  .controll_panel_offers_and_colors {
    position: static;
    opacity: 1;
    height: auto;
  }

  .add_product_to_cart {
    display: flex;
    width: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 49px;
    margin-top: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: #ffffff;
    background: #1a1a1a;
  }

  .bottom_one_product_wrapper:last-child {
    margin-top: auto;
    flex-direction: column-reverse;
  }

  .name_product_text {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: #1a1a1a;
  }

  .name_color_hover {
    display: none;
  }

  .color_picker_product {
    justify-content: start;
    margin-left: -7px;
    margin-top: 16px;
  }

  .size_picker_product {
    justify-content: start;
    gap: 16px;
  }

  .one_color_selector {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
  }

  .circle_colorpiker_border {
    margin-inline: 7px;
  }

  .price_product {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: #1a1a1a;
  }

  .price_product_wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  .price_product.old_price {
    order: 1;
    text-decoration-line: line-through;
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    font-family: "IBM Plex Mono";
    margin-left: 0;
    margin-left: 16px;
  }

  .sell_price_product {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    color: #1a1a1a;
  }
}

@media (max-width: 1024px) {
  .fashion {
    max-width: 559px;

    &__wrapper {
      aspect-ratio: auto;
      display: block;
    }

    &__img {
      margin-bottom: 21px;
      aspect-ratio: 1/1;
    }

    &__aside {
      display: block;
      min-width: 0;
    }

    &__list {
      overflow-y: auto;
    }

    .spin_container {
      aspect-ratio: 1/1;
    }

    &__modal {
      &-close {
        top: 14px;
        right: 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  .fashion {
    padding: 32px 10px;

    &__img {
      margin-bottom: 20px;
    }

    &__aside {
      &-count {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #000000;
        font-size: 12px;
        line-height: 1.2;
      }
    }

    &__name {
      bottom: 8px;
      left: 8px;
      background: #000;
      color: #fff;
      padding: 6px 8px;
      border-radius: 8px;
      font-size: 10px;
    }

    &__modal {
      &-wrapper {
        padding: 20px;
      }

      &-close {
        width: 14px;
        height: 14px;
        top: 8px;
        right: 8px;
      }
    }
  }

  .one_product_wrapper.one_product_fashion {
    .one_product {
      gap: 10px;
      padding-bottom: 62px;
    }

    .image_container {
      flex-basis: 90px;
      // flex-grow: 1;
      aspect-ratio: 90/162;

      div {
        height: 100%;
      }
    }

    .container_wrapper_product {
      flex-basis: 220px;
      flex-grow: 1;
    }

    .name_product_text {
      font-size: 14px;
    }

    .price_product_wrapper {
      margin-top: 12px;
    }

    .price_product.old_price {
      font-size: 12px;
      margin-left: 25px;
    }

    .sell_price_product {
      font-size: 14px;
    }

    .one_color_selector {
      font-size: 14px;
    }

    .add_product_to_cart {
      height: 50px;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .color_picker_product {
      margin-top: 12px;
    }

    .size_picker_product {
      margin-top: 12px;
    }
  }
}
