.burger {
    display: flex;
    align-items: center;
    height: 100%;
    /*background: black;*/
    z-index: 11;
}
.container_burger {
    width: 28px;
    height: 28px;
    /*display: inline-block;*/
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bar1 {
    width: 27px;
    height: 2px;
    /*background-color: white;*/
    background-color: black;
    transition: .4s;
    border-radius: 10px;
}
.bar2 {
    width: 27px;
    height: 2px;
    /*background-color: white;*/
    background-color: black;
    margin: 5px 0 5px 0;
    transition: .4s;
    border-radius: 10px;
}
.bar3 {
    width: 27px;
    height: 2px;
    /*background-color: white;*/
    background-color: black;
    transition: .4s;
    border-radius: 10px;
}
.bar1.active {
    transform: rotate(45deg) translate(5px,5px);
    height: 2px;
    transition: transform .4s ease 0s, height .4s ease 0s;
    border-radius: 10px;
}
.bar2.active {
    opacity: 0;
    transition: opacity .4s ease 0s;
}
.bar3.active {
    transform: rotate(-45deg) translate(5px,-5px);
    height: 2px;
    transition: transform .4s ease 0s, height .4s ease 0s;
    border-radius: 10px;
}


@media (max-width: 427px) {
    .container_burger {
        width: 24px;
        height: 24px;
    }
}

