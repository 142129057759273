.root {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.30);
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1001;
}

.wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 40px;
}

.close {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;

    position: absolute;
    display: flex;
    right: 18px;
    top: 18px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}