.orders_page_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}
.heading_order_list_page {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 25px;
}
.orders_panel_wrapper {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
}
.orders_container {
    display: flex;
    height: 100%;
    width: 1500px;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    overflow-x: scroll;
}
.all_order_fields_name_container {
    display: flex;
    width: max-content;
    height: 50px;
    min-height: 50px;
    border-bottom: 1px solid #74827b42;
    background-color: rgba(211, 211, 211, 0.44);
}

.order_system_id {
    display: flex;
    height: 100%;
    width: 60px;
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    color: lightgrey;
}
.order_number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 135px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_id {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 135px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}

.order_time_to_create {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 155px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_user_info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 200px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 130px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_pay_status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 120px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_to_pay_summ {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 165px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_basket_items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 250px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_address_inf {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 220px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}

.order_email_address {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 170px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}
.order_mobile_number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 170px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
}

.wrapper_for_one_order {
    display: flex;
    min-width: 100%;
    height: 100%;
    width: max-content;
    flex-direction: column;
    overflow-y: scroll;
}

.wrapper_container_for_one_line {
    margin-top: 10px;
    display: flex;
    min-width: 100%;
    width: max-content;
    border: 1px solid #c9cccf;
    padding: 10px 0px;
    position: relative;
}

.wrapper_container_for_one_line:hover {
    background-color: rgba(211, 211, 211, 0.44);
    transition: background-color 0.4s ease 0s;
}

.order_one_line {

    display: flex;
    min-width: 100%;
    width: max-content;
    text-decoration: none;
}

.one_line_sys_id {
    display: flex;
    height: 100%;
    width: 60px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    color: #d3d3d352;
}

.one_line_order_number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 135px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    color: black;
}

.one_line_order_id {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 135px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    color: black;
}

.one_line_create_time {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 155px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 16px;
    color: black;
}

.one_line_user_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    width: 200px;
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
    color: black;
}
.one_line_user_info span {
    margin-top: 5px;
    font-weight: 500;
}
.one_line_user_info .one_line_strong_span {
    font-weight: 600;
}
.one_line_order_status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 130px;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: black;
    position: relative;
}
.pre_order_info_for_line {
    display: flex;
    position: absolute;
    top: 20px;
    left: 5px;
    color: #CE3F3F;
    font-weight: 600;
    text-transform: uppercase;
}

.one_line_order_credited {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    width: 120px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    color: black;
}

.one_line_order_to_pay_summ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    width: 165px;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
    color: black;
}
.one_line_low_text_span {
    font-size: 12px;
    color: grey;
}
.mrt_span {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: grey;
}
.one_line_items_basket {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    width: 250px;
    font-weight: 600;
    font-size: 12px;
    height: 110px;
    overflow-y: scroll;
}

.one_line_one_item {
    display: flex;
    flex-direction: column;
    color: black;
    width: 100%;
    height: 100%;
    margin-top: 10px;
}

.one_line_one_item:first-child {
    margin-top: 0px;
}
.one_line_one_item_name {
    font-weight: 600;
    font-size: 14px;
}

.one_line_one_item_support_container {
    display: flex;
    margin-top: 10px;
}
.one_line_one_item_support_container img {
    width: 48px;
    height: 65px;
}
.one_line_one_item_support_container_inform {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.one_line_delivery_address {
    display: flex;
    flex-direction: column;
    color: black;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 10px;
    width: 220px;
    font-weight: 500;
    font-size: 12px
}
.one_line_pvz_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.one_line_email_field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 170px;
    text-align: center;
    font-weight: 500;
    color: black;
    font-size: 14px;
}

.one_line_telephone {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    width: 170px;
    text-align: center;
    font-weight: 500;
    color: black;
    font-size: 14px;
}

.pagination_adm_wrapper {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
    padding-bottom: 25px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 25px;
    font-weight: 600;
}
.generate_excel_feed {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
}
.excel_dropdown_btn {
    margin-right: 35px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
}

.info_bord_adm_panel_add {
    position: relative;
}
.excel_dropdown_menu {
    display: flex;
    position: absolute;
    top: 50px;
    left: 50px;
    background: #f6f6f7;
    padding: 15px;
    border: 1px solid black;
    flex-direction: column;
}
.excel_dropdown_menu div {
    display: flex;
    gap: 10px;
}

.generate_excel_feed.disabled {
    cursor: pointer;
    background-color: black;
    color: white;
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
}

.generate_excel_feed:hover {
    background-color: black;
    color: white;
}
.downl {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 10px;
    border: 1px solid black;
    cursor: pointer;
    text-decoration: none;
    background-color: black;
    color: white;
    margin-right: 40px;
    user-select: none;
    pointer-events: none;
    opacity: 0.3;
}

.downl.active {
    pointer-events: auto;
    user-select: auto;
    opacity: 1;
    cursor: pointer;
}
.downl.active:hover {
    background-color: white;
    color: black;
}