
.preview_container_img {
    max-width: 150px;
    width: 100%;
    border: 1px solid #c9cccf;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px;
    border-radius: 5px;
}
.info_field_for_manager {
    width: 100%;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    z-index: 21;
    top: 0px;
    left: 0px;
    background-color: white;
    border-radius: 5px;
}
.wrapper_image_container_t {
    display: flex;
    height: 200px;
    width: 100%;
    background-color: rgba(201, 204, 207, 0.38);
    position: relative;
}
.one_img_field_input {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}
.system_info {
    position: absolute;
    max-width: 100%;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
    text-align: center;
    z-index: 0;
}
.img_wrapper_one_d {
    max-width: 100%;
    max-height: 100%;


}
.img_wrapper_one_d img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.img_wrapper_one_d video {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.remove_image_img_field {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(208, 9, 9, 0.33);
    z-index: 20;
    font-weight: 600;
    font-size: 13px;
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 10px;
}
.remove_image_img_field:hover {
    background-color: black;
    color: white;
}

