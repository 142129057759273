.goPageButton {
    max-width: 225px;
    width: 100%;
    min-height: 58px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    /*text-transform: uppercase;*/

    padding: 5px;
    border: 1px solid;
    border-color: #FFFFFF;
    background-color: #FFFFFF;
    color: #1A1A1A;
    margin: 0 auto;
    /*margin-top: 60px;*/
    margin-top: 30px;
    text-decoration: none;
    cursor: pointer;
    position: relative;
}
.goPageButton.black {
    border-color: #1A1A1A;
    background-color: #1A1A1A;
    color: #FFFFFF;
}


/* .goPageButton:hover {
    background: #1A1A1A;
    color: #FFFFFF;
    transition: background 0.2s ease 0s, border-color 0.2s ease 0s, color 0.2s ease 0s;
    border-color: #1A1A1A;
} */

@media (max-width: 1024px) {

    .goPageButton {
        margin-top: 50px;
    }

}

@media (max-width: 768px) {
    .goPageButton {
        margin-top: 40px;
    }
}
@media (max-width: 428px) {
    .goPageButton {
        margin-top: 20px;
        max-width: 180px;
        min-height: 45px;
        font-size: 12px;
    }
    .goPageButton.black {
        margin-top: 28px;
        font-size: 12px;
    }
}

