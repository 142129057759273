.homy_color_manager {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.colors_manager_title {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.colors_panel_wrapper {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
}
.table_colors_adm {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
}

.add_new_color_btn {
    display: flex;
    margin-top: 20px;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #999999;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
}
.add_new_color_btn:hover {
    background-color: whitesmoke;
}