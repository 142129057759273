.page_fashions_wrapper {
  flex-grow: 1;
  padding-bottom: 80px;
}

.container {
  padding: 0 40px;
}

.fashions__title {
  margin-top: 40px;
  margin-bottom: 53px;
  text-align: center;
  font-size: 30px;
  line-height: 39px;
  letter-spacing: 0.04em;
  color: #1a1a1a;
  font-weight: 700;
}

.fashions {
  &__list {
    list-style: none;
    padding: 0;
    margin-left: -40px;
    margin-top: -40px;
  }

  &__item {
    position: relative;
    margin-left: 40px;
    margin-top: 40px;
    width: calc(100% / 3 - 40px);

    &._vertical {
      .fashions__item {
        &-img {
          aspect-ratio: 426 / 566;
        }
      }
    }

    &._square {
      .fashions__item {
        &-img {
          aspect-ratio: 1 / 1;
        }
      }
    }

    &-img {
      object-fit: cover;
      display: block;
      width: 100%;
    }

    &-icon {
      width: 32px;
      height: auto;
      background-color: #ffffff;
      border-radius: 50%;
      transition: all .3s;

      @media (hover: hover) {
        &:hover {
          box-shadow: inset 0 0 0 2px #000;
        }
      }
    }
  }

  &__link {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}

@media (max-width: 1024px) {
  .page_fashions_wrapper {
    padding-bottom: 60px;
  }

  .fashions__title {
    font-size: 24px;
    line-height: 31px;
    font-weight: 500;
    margin-top: 32px;
    margin-bottom: 29px;
  }

  .fashions {
    &__list {
      margin-left: -20px;
      margin-top: -20px;
    }

    &__item {
      margin-left: 20px;
      margin-top: 20px;
      width: calc(100% / 3 - 20px);

      &-img {
      }

      &-icon {
        width: 24px;
      }
    }

    &__link {
      top: 20px;
      right: 20px;
    }
  }
}

@media (max-width: 768px) {
  .page_fashions_wrapper {
    padding-bottom: 75px;
  }

  .container {
    padding: 0 20px;
  }

  .fashions__title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: normal;
  }

  .fashions {
    &__list {
    }

    &__item {
      width: calc(100% / 2 - 20px);

      &-img {
      }

      &-icon {
        width: 16px;
      }
    }

    &__link {
      top: 10px;
      right: 10px;
    }
  }
}
