.oneLineColor {
    min-height: 60px;
    border: 1px solid #c9cccf;
    display: flex;
    width: 100%;
    margin-top: 5px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.color_items_wrap {
    display: flex;
}

.id_container_color {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    width: 50px;
    height: 35px;
    align-items: center;
    justify-content: center;
}
.colors_field_wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-left: 20px;
}
.params_color_input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 10px 5px;
    border-radius: 5px;
    margin-left: 10px;
}
.hex_preview {
    display: flex;
    width: 25px;
    min-width: 25px;
    margin-left: 10px;
    height: 25px;
    border-radius: 50%;
}

.save_color_controll {
    display: flex;
    width: 100px;
    height: 35px;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    margin-right: 30px;
    cursor: pointer;
}

.save_color_controll.need_save {
    background-color: orange;
    border-color: transparent;
    transition: background-color 0.4s ease 0s;
}

.save_color_controll:hover {
    background-color: #248F5C;
}
.right_control_wrapper {
    display: flex;

}
.delete_color {
    margin-right: 20px;
    height: 35px;
    color: #df7272;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid red;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.error_sup_message {
    font-size: 12px;
    font-weight: 600;
    color: #CE3F3F;
}

.delete_color:hover {
    color: black;
    background-color: #df7272;

}
.image_container_colors {
    /*background-color: black;*/
    width: 150px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.go_color_outp {
    max-width: 80px;
    display: flex;
    width: 100%;
    background-color: #c9c7c7;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.go_color_outp  input {
   position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.color_img_circle {
    max-width: 32px;
    max-height: 32px;
    margin-left: 20px;
    border-radius: 50%;
}
.one_line_wrapper_sup {
    height: 45px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.eror_div {
    display: none;
    width: 100%;
    height: 40px;
    align-items: center;
    padding: 0px 10px;
}
.eror_div.active {
    display: flex;
}
.to_product_link_color {
    display: flex;
    padding: 0px 10px;
    height: 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;
    font-weight: 600;
    border: 1px solid #c9cccf;
    border-radius: 5px;
    margin-left: 20px;
}
.to_product_link_color:hover {
    background-color: black;
    color: white;
}