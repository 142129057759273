.run_line {
    height: 30px;
    width: 100%;
    padding: 5px 10px;
    box-sizing: border-box;
    background: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
}
.run_text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #FFFFFF;
}
.runline_copy {
    margin-left: 35px;
    margin-right: 35px;
}

@media (max-width: 1300px) {
    .run_line {
        position: relative;
        z-index: 12;
    }
}

@media (max-width: 768px) {
    .run_text {
        font-size: 11px;
    }
}
