.faq_conteiner {
    height: 100%;
    /*max-width: 1920px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    position: relative;
    margin: 0 auto;
    background: linear-gradient(166.17deg, #F8F8FA 24.75%, #F5F6FA 86.2%);
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: flex;
}
.f_indent {
    max-width: 1860px;
    width: 100%;
    padding: 0px 40px;
    margin: 0 auto;
    position: relative;
}
.faq_container_wrapper {

}

.faq_banner {
    /*position: relative;*/
    /*min-height: 831px;*/
    width: 50%;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
}
.faq_background_img {
    position: absolute;
    max-width: 420px;
    width: 100%;
    height: auto;
    object-fit: contain;
    z-index: -1;
    bottom: 0px;
    right: 0;
}
.faq_content_wrapper {
    /*width: 37%;*/
    position: relative;
    display: flex;
    /*justify-content: center;*/
}
.faq_content {
    /*position: absolute;*/
    width: 100%;
    /*top: 250px;*/
    /*padding: 0px 10px;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}
.faq_title {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    /* identical to box height */
    color: #000000;
    /*text-align: center;*/
    /*max-width: 433px;*/
    /*margin: 0 auto;*/
}
.q_parents_content {
    margin-top: 40px;
    width: 100%;
}
.f_info_content {
    /*max-width: 919px;*/
    margin-top: 40px;
    /*margin-bottom: 100px;*/
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}
.q_border_block {
    /*border-bottom: 1px solid #E0E0E0;*/
    /*width: 100%;*/
}
.f_info {
    display: flex;
    flex-direction: column;
    /*margin-top: 87px;*/
}
    .f_title_info {
        font-weight: 500;
        font-size: 24px;
        /*text-align: center;*/
        color: #000000;
    }
    .f_text_info {
        font-weight: 400;
        font-size: 16px;
        /*text-align: center;*/
        color: #000000;
        margin-top: 18px;
    }
    .f_button_info {
        padding: 14px;
        max-width: 153px;
        width: 100%;
        border: 1px solid black;
        /*margin: 0 auto;*/
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        text-decoration: none;
        color: #1A1A1A;
        cursor: pointer;
        margin-top: 18px;
    }
    .f_button_info:hover {
        color: white;
        background-color: black;
        transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
    }


@media (max-width: 1440px) {
    .faq_banner {
        max-width: 525px;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .faq_conteiner {
        height: auto;
    }
    .faq_title {
        font-size: 24px;
    }
    .faq_background_img {
        max-width: 395px;
    }


}

@media (max-width: 768px) {
    .faq_conteiner {
        background: white;
    }
    .f_indent {
        padding: 0px 20px;
    }
    .faq_title {
        font-size: 18px;
    }
    .faq_banner {
        max-width: 100%;
    }
    .q_parents_content {
        margin-top: 0px;
    }
    .f_info_content {
        margin-top: 26px;
    }
    .f_title_info {
        font-size: 18px;
    }
    .f_text_info {
        margin-top: 10px;
    }
}


