.city_page_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    /*background-color: #df7272;*/
    padding: 20px;
    flex-direction: column;
}
.city_page_title {
    margin-top: 15px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 22px;
}

.city_page_container {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    padding: 10px;
    flex-direction: column;
}
.controll_horizontal_barr {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
}
.search_citys {
    max-width: 50%;
    width: 100%;
    display: flex;
    align-items: center;
}

.add_controll_block {
    max-width: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.input_city_st {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 5px;
    border-radius: 5px;
    margin-left: 20px;
}
.add_new_city_button {
    font-size: 13px;
    font-weight: 600;
    padding: 10px 10px;
    border: 1px solid black;
    margin-left: 20px;
    cursor: pointer;
}
.add_new_city_button:hover {
    background-color: black;
    color: white;
}
.error_message_city_page {
    display: flex;
    margin-right: 30px;
    font-weight: 600;
    color: #CE3F3F;
}

.city_list_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

}
.one_item_city_wr {
    display: flex;
    margin-top: 20px;
    align-items: center;
    width: 100%;
}
.name_sity_input {
    margin-left: 20px;
}
.id_city_container {
    display: flex;
    width: 50px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}

.save_city_btn {
    padding: 8px 10px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    font-weight: 600;
    cursor: pointer;

}
.save_city_btn:hover {
    box-shadow: 0px 0px 4px 0px rgb(34 60 80 / 80%);
}

.save_city_btn.active {
    animation: trigerSaveCity 2s ease infinite;
}



@keyframes trigerSaveCity {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}
.heading_sity_list_lable {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: powderblue;
    display: flex;

}
.sity_list_heading_id_container {
    display: flex;
    height: 100%;
    width: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}
.sity_list_heading_name_container {
    display: flex;
    height: 100%;
    width: 200px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}

.sity_list_heading_map_container {
    display: flex;
    height: 100%;
    width: 200px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}
.sity_list_heading_order_check_container {
    display: flex;
    height: 100%;
    width: 200px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    margin-left: 20px;
    text-align: center;
}

.sity_list_heading_order_filter_container {
    display: flex;
    height: 100%;
    width: 200px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}
.check_indicator_city {
    display: flex;
    height: 100%;
    width: 200px;
    background-color: lightgrey;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    text-align: center;
}
.one_city_line_sort_container {
    display: flex;
    height: 100%;
    width: 200px;
    background-color: lightgrey;
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    text-align: center;
}
.one_city_line_sort_container input {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0px 10px;
    font-size: 16px;
    font-weight: 600;
}
.check_indicator_city:hover {
    background-color: lightgreen;
}

.check_indicator_city:after {
    content: "";
    display: flex;
    position: absolute;
    height: 20px;
    width: 2px;
    top: 8px;
    left: 49%;
    background-color: indianred;
    transform: rotate(45deg);
}

.check_indicator_city:before {
    content: "";
    display: flex;
    position: absolute;
    height: 20px;
    width: 2px;
    top: 8px;
    left: 49%;
    background-color: indianred;
    transform: rotate(-45deg);

}

.check_indicator_city.active {
    background-color: lightgreen;

}

.check_indicator_city.active:after {
    content: "";
    display: flex;
    position: absolute;
    height: 20px;
    width: 2px;
    top: 8px;
    left: 51%;
    background-color: black;
    transform: rotate(45deg);
}

.check_indicator_city.active:before {
    content: "";
    display: flex;
    position: absolute;
    height: 13px;
    width: 2px;
    top: 14px;
    left: 46%;
    background-color: black;
    transform: rotate(-45deg)
}