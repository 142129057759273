.wrapper_indicator {
    width: 65px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.is_active_item {
    display: inline-block;
    width: 45px;
    height: 25px;
    border-radius: 19px;
    background: rgba(191, 191, 191, 0.57);
    z-index: 0;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    position: relative;
    transition-duration: 300ms;
}

.circle_active_item {
    content: "";
    height: 19px;
    width: 19px;
    border-radius: 17px;
    background: #fff;
    top: 3px;
    left: 3px;
    transition-duration: 300ms;
    position: absolute;
    z-index: 1;
}
.wrapper_indicator.on .is_active_item {
    background: rgba(102, 101, 101, 0.42);
    transition: background 0.8s ease 0s;
}

.wrapper_indicator.on .circle_active_item {
    /*background: #118c4e;*/
    left: 23px;
    background-color: black;
}





