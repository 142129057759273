.one_child_line_element {
    height: 60px;
    border: 1px solid #c9cccf;
    display: flex;
    width: 100%;
    margin-top: 5px;
    align-items: center;
    transition: opacity 0.8s ease 0s, background-color 0.8s ease 0s;
}

.one_child_line_item {
    display: flex;
    padding: 5px;
    align-items: center;
    height: 100%;
}
.one_child_line_item.id {
    width: 90px;
}
.one_child_line_item.img {
    width: 50px;
    display: flex;
}

.one_child_line_item.img img {
    height: 100%;
    display: flex;
}
.one_child_line_item.name {
    max-width: 550px;
    width: 100%;
    font-size: 14px;
}

.one_child_line_item.sort {
    width: 150px;
}
.one_child_line_item.sort input {
    border: 1px solid #DADADA;
    border-radius: 5px;
    height: 35px;
    font-size: 15px;
    color: black;
    font-weight: 600;
    padding: 5px;
    width: 100%;
}
.one_change_sort_btn_save {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 7px 14px;
    border: 1px solid black;
    cursor: pointer;
}
.one_child_line_item.color {
    width: 200px;
    font-size: 12px;
}
.one_change_sort_btn_save:hover {
    background: black;
    color: white;
}
.one_change_sort_btn_save.active {
    animation: trigerSocialManager 2s ease infinite;
}
.one_child_line_item.link {
    font-size: 13px;
    padding: 7px 14px;
    border: 1px solid black;
    height: 35px;
    text-decoration: none;
    text-transform: uppercase;
    color: black;
}
.one_child_line_item.link:hover {
    background: black;
    color: white;
}
.sort_container_test_wrapper {
    display: flex;
}
.search_and_sort_for_child {
    display: flex;
    flex-direction: column;
}

@keyframes triggerSaveSort {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }
}