.social_page_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    flex-direction: column;
}
.social_page_title {
    margin-top: 15px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 22px;
}
.social_page_container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.first_social_controller {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    display: flex;
    min-height: 200px;
    padding: 20px;
    flex-direction: column;
}
.heading_page_social {
    font-weight: 500;
    letter-spacing: 0.04em;
    font-size: 16px;
}
.container_one_block_social {
    display: flex;
}
.left_wrapper_social_controller {
    max-width: 50%;
    width: 100%;
}
.right_wrapper_social_controller {
    max-width: 50%;
    width: 100%;
    margin-left: 20px;
}
.primer_svg {
    font-size: 13px;
    margin-top: 10px;
    border: 1px solid #c9cccf;
    padding: 5px;
}
.social_manager_field_wrap {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.social_field_label {
    display: flex;
    font-weight: 500;
    font-size: 12px;
}
.social_fields_input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.social_fields_textarea {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
    resize: none;
    height: 100px;
    margin-right: 10px;
}
.addendum_social {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.add_new_social_button {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.add_new_social_button:hover {
    background: black;
    color: white;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.error_social_message {
    display: flex;
    margin-left: 20px;
    font-weight: 500;
    color: #CE3F3F;
}
.list_social {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    width: 100%;
    display: flex;
    min-height: 200px;
    padding: 20px;
    flex-direction: column;
    margin-top: 30px;
}

.one_social_redaction_wrapper {
    display: flex;
    position: relative;
    margin-top: 20px;
    background: white;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 2px;
    width: 100%;
    padding: 30px 10px;
    align-items: center;
}
.heading_page_social.red {
    color: #CE3F3F;
}
.one_social_wrap_field {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-width: 250px;
}
.one_social_wrap_field.id {
    width: 30px;
    justify-content: center;
    align-items: center;
}
.one_social_wrap_field.textarea {
    max-width: 350px;
    width: 100%;
}
.one_social_wrap_field.svg {
    max-width: 300px;
    width: 100%;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.one_social_heading_item {
    display: flex;
    color: #484747;
    font-size: 13px;
    font-weight: 600;
}
.id_social_one_item {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    margin-top: 7px;
}
.one_social_list_input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.one_social_list_textarea {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
    resize: none;
    height: 100px;
    margin-right: 10px;
}
.style_svg_conclusion {
    margin-top: 10px;
}
.style_svg_conclusion svg{
    width: 50px;
    height: 50px;
    padding: 5px;
}
.save_social_button {
    text-align: center;
    margin-left: 40px;
    padding: 7px 13px;
    border: 1px solid black;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.save_social_button:hover {
    background: black;
    color: white;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.delete_social_button {
    margin-left: 40px;
    padding: 7px 13px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid red;
    border-radius: 5px;
    color: #CE3F3F;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.delete_social_button:hover {
    background: red;
    color: white;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}

.save_social_button.active {
    animation: trigerSocialManager 2s ease infinite;
}


@keyframes trigerSocialManager {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}