.subs_Form_Input {
    width: 100%;
    border: 1px solid #D7D6D6;
    height: 39px;
    padding: 5px;
    outline: none;
    font-size: 16px;
    color: #757575;
    font-weight: 600;
    margin-top: 7px;
}
.input_contaner_sub {
    width: 100%;
    position: relative;
    margin-top: 60px;
}
.name_input {
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
}

.input_placeholder {
    font-size: 16px;
    color: #757575;
    position: absolute;
    bottom: 15px;
    left: 2px;
    transition: opacity 0.4s ease 0s;
    caret-color: black;
}

.input_contaner_sub:hover  .input_placeholder {
    opacity: 0;
}

.input_placeholder.active {
    opacity: 0;
    transition: opacity 0.2s ease 0s;
}

@media (max-width: 1024px) {
    .input_contaner_sub {
        margin-top: 0px;
    }
}