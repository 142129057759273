.breadcrumbs_block_catalog {
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbs_block_catalog a {
    display: flex;
    padding-right: 25px;
    position: relative;
    cursor: pointer;
    color: #000000;
    opacity:  0.8;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration: underline;
}

.breadcrumbs_block_catalog a:after {
    content: "";
    height: 1px;
    width: 11px;
    background: #6C757D;
    opacity:  0.7;
    position: absolute;
    right: 7px;
    top: 6px;
    transform: rotate(-70deg);
}
/*.breadcrumbs_block_catalog a:before {*/
/*    content: "";*/
/*    height: 1px;*/
/*    width: 7px;*/
/*    background: black;*/
/*    position: absolute;*/
/*    right: 15px;*/
/*    top: 10px;*/
/*    transform: rotate(-45deg);*/
/*}*/

.breadcrumbs_block_catalog a:last-child:before {
    display: none;
}
.breadcrumbs_block_catalog a:last-child:after {
    display: none;
}
.breadcrumbs_block_catalog a:first-child {
    margin-left: 0px;
    /*text-transform: uppercase;*/
}

.breadcrumbs_block_catalog a:last-child {
    text-decoration-line: none;
    color: #000000;
    padding-right: 0px;
    pointer-events: none;
}

@media (max-width: 768px) {
    .breadcrumbs_block_catalog a {
        padding-right: 20px;
    }
}

@media (max-width: 428px) {
    .breadcrumbs_block_catalog a {
        padding-right: 12px;
    }
    .breadcrumbs_block_catalog a:after {
        width: 7px;
        right: 4px;
        top: 8px;
    }
}
