.catalog_section {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
}

.products_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    max-width: 1812px;
}

.sale__label {
    display: inline-block;
    position: absolute;
    padding: 4px 11px 4px 9px;
    background: #1A1A1A;
    bottom: 10px;
    left: 9px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.one_product_wrapper {
    display: flex;
    max-width: 453px;
    width: 100%;
    padding: 1px;
    padding-right: 0px;
    text-decoration: none;
    position: relative;
}

/*.products_wrapper.standart .one_product_wrapper.favorite:hover  .controll_panel_offers_and_colors {*/
/*    opacity: 0;*/
/*    !*transition: opacity 0.4s ease 0s;*!*/
/*    position: absolute;*/

/*}*/
/*.products_wrapper.standart .one_product_wrapper.favorite:hover  .previews_info_product_wrap {*/
/*    opacity: 1;*/
/*    !*transition: opacity 0.4s ease 0s;*!*/
/*    position: relative;*/
/*}*/

.products_wrapper.standart .one_product_wrapper {
    box-shadow:
            1px 0 0 0 #000,
            0 1px 0 0 #000,
            1px 1px 0 0 #000,
            1px 0 0 0 #000 inset,
            0 1px 0 0 #000 inset;
}

.one_product {
    width: 100%;
    position: relative;
    padding: 1px;
    display: flex;
    flex-direction: column;
}
.dop_controll_bar_product {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 18px;
}
.sticker_product {
    padding-left: 1px;
}
.one_stiker {
    padding-left: 7px;
    display: flex;
    align-items: center;
    border-left: 3px solid black;
    margin-top: 8px;
    text-transform: capitalize;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 5px;
}
.one_stiker.one_stiker_new {
    background: #000000;
    color: #FFFFFF;
    padding: 4px;
    border: 0;
    display: flex;
    align-items: center;
    gap: 2px;
    width:fit-content;
}
.one_stiker .one_stiker_limited {
    width: 26px;
    height: auto;
}
.one_stiker._black_friday {
    background: #000000;
    color: #FFFFFF;
    padding: 4px 4px 4px 2px;
    border: 0;
    display: flex;
    align-items: center;
    gap: 2px;
}
.one_stiker:first-child {
    margin-top: 0;
}
.black_friday {
    max-width: 140px;
    padding: 6px 10px 6px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
}
.black_friday svg {
    margin-right: 10px;
}
.black_friday span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #FFFFFF;
}
.add_favorite_product {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 18px;
    cursor: pointer;
}

.image_container {
    width: 100%;
    position: relative;
}
.products_wrapper.standart .link_product_page {
    height: 680px;
}
.products_wrapper.small .link_product_page{
    height: 154px;
}
.image_container img {
    height: 100%;
    width: 100%;
    display: flex;
    object-fit: cover;
}
.image_container .standart_photo_product {
    display: flex;
    height: 100%;
    width: 100%;
}
.image_container .hover_photo_product {
    display: none;
}
.container_wrapper_product {
    min-height: 200px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 10px;
}

.products_wrapper.standart .container_wrapper_product.favorites {
    min-height: 145px;
    padding: 10px 10px 20px 10px;
}
.name_wrapper_product {
    width: 100%;
    /*padding-top: 20px;*/
    /*padding-bottom: 15px;*/
    max-width: 450px;
    display: flex;
    align-items: center;
}
.name_product_text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}
/*.price_and_color_product_wrapper {*/
/*    display: flex;*/
/*    margin-top: 10px;*/
/*    align-items: center;*/
/*    justify-content: space-between;*/
/*}*/
.price_product_wrapper {
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.price_product {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.price_product.old_price {
    font-family: 'Montserrat';
    margin-left: 30px;
    text-decoration-line: line-through;
    color: #D7D7D7;
    font-size: 12px;
    display: flex;
    align-items: center;
}
.sell_price_product {
    font-family: 'Montserrat';
    font-size: 14px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    color: #1A1A1A;
}




/*.one_product:hover .standart_photo_product {*/
/*    display: none;*/
/*}*/
/*.one_product:hover .hover_photo_product {*/
/*    display: flex;*/
/*}*/
/*.add_favorite_product:hover  svg path {*/
/*    fill: black;*/
/*}*/

.link_product_page{
    display: flex;
}
.controll_panel_offers_and_colors {
    position: absolute;
    opacity: 0;
    height: 0px;
}

.previews_info_product_wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.products_wrapper.standart .one_product_wrapper .controll_panel_offers_and_colors {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.products_wrapper.standart .one_product_wrapper:hover .controll_panel_offers_and_colors {
    opacity: 1;
    transition: opacity 0.8s ease 0s, height 0.4s ease 0s;
    position: relative;
    height: 100%;
}

/*.products_wrapper.standart .one_product_wrapper:hover .previews_info_product_wrap {*/
/*    opacity: 0;*/
/*    transition: opacity 0.4s ease 0s;*/
/*    position: absolute;*/
/*}*/


.color_picker_product {
    min-height: 25px;
    display: flex;
    flex-wrap: wrap;
    font-weight: 700;
    align-items: center;
    cursor: pointer;
}
.name_color_hover {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: end;
    min-height: 20px;
}
.color_palette_product{
    display: flex;
    flex-direction: column;
}
.color_picker_product.previews {
    justify-content: flex-end;
}
.size_picker_product {
    display: flex;
    justify-content: flex-end;
    font-weight: 700;
    flex-wrap: wrap;
}

.add_product_to_cart {
    padding: 10px;
    width: 100%;
    min-height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1A1A1A;
    margin-top: 5px;
    cursor: pointer;
}

.add_product_to_cart.not_active {
    background-color: lightgrey;
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
}
.products_wrapper.small {
    max-width: 1920px;
}
.products_wrapper.small .one_product_wrapper {
    max-width: 108px;
    max-height: 159px;
    padding: 6px;
    margin-top: 0px;
}
.products_wrapper.small .one_product {
    max-width: 214px;
    max-height: 286px;
}
.products_wrapper.small .image_container {
    height: 100%;
}

.products_wrapper.small .name_wrapper_product {
    display: none;
}
.products_wrapper.small .price_product_wrapper {
    display: none;
}
.products_wrapper.small .color_picker_product  {
    display: none;
}
.products_wrapper.small .size_picker_product  {
    display: none;
}
.products_wrapper.small .controll_panel_offers_and_colors {
    max-width: 100%;
    min-height: 150px;
    padding: 10px 0px;
    display: none;
}
.products_wrapper.small .add_product_to_cart {
    max-width: 150px;
    padding: 0px 10px;
    font-size: 10px;
    height: 35px;
    display: none;
}

.banner_container {
    max-width: 1812px;
    width: 100%;
    max-height: 450px;
    margin-bottom: 30px;
}

.banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.products_wrapper.small .color_picker_product {*/
/*    font-size: 12px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/
/*.products_wrapper.small .size_picker_product {*/
/*    font-size: 12px;*/
/*    width: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/
.small .dop_controll_bar_product {
    padding: 0px 10px;
}
.products_wrapper.small .add_favorite_product {
    height: 35px;
    width: 35px;
    display: none;
}
.products_wrapper.small .add_favorite_product svg {
    width: 20px;
    height: 18px;
}
.products_wrapper.small .dop_controll_bar_product {
   display: none;
}

.circle_colorpiker_border {
    /*margin-left: 10px;*/
    /*border: 1px solid rgba(0, 0, 0, 0.14);*/
    /*padding: 1px;*/
    margin: 2px;
    padding: 3px;
    transition: .2s ease;
    box-shadow:  0 0 0 1px transparent;
}
.circle_colorpiker_border.active {
    box-shadow:  0 0 0 1px black;
}

.circle_colorpiker_wrapper {
    display: flex;
    width: 20px;
    height: 20px;
    transition:  width 0.2s ease 0s, height 0.2s ease 0s;
    background-position: center;
    background-size: contain;
    box-shadow:  0 0 0 1px #717171;
}


.circle_colorpiker_wrapper.active {
    border: 1px solid rgba(0, 0, 0, 0.14);
    width: 11px;
    height: 11px;
    /*transition:  width 0.4s ease 0s, height 0.4s ease 0s;*/
}
.circle_colorpiker_border.active .circle_colorpiker_wrapper.active {
    border: unset;
    width: 10px;
    height: 10px;
    /*transition:  width 0.4s ease 0s, height 0.4s ease 0s;*/
}

.no_find_product {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
}
.size_picker_product {
    display: flex;
    /*justify-content: center;*/
}

.one_color_selector {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    margin: 5px;
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.6s ease 0s, color 0.6s ease 0s;
    cursor: pointer;
}
.one_color_selector:hover {
    color: #000000;
    transition: color 0.6s ease 0s;
}
.one_color_selector.active {
    border-bottom: 1px solid black;
    color: #000000;
}
.one_color_selector.disactive {
    text-align: center;
    text-decoration-line: line-through;
    text-transform: uppercase;
    color: #808080;
}
.one_color_selector.disactive.active {
    color: #000000;
    border-bottom: 1px solid transparent;
}
/*.one_color_selector.disactive:after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 35px;*/
/*    height: 1px;*/
/*    background-color: black;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%) rotate(45deg);*/

/*}*/

/*.one_color_selector.disactive:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    width: 35px;*/
/*    height: 1px;*/
/*    background-color: black;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%) rotate(-45deg);*/
/*}*/


.give_next_products {
    display: flex;
    max-width: 182px;
    width: 100%;
    min-height: 50px;
    margin: 0 auto;
    text-align: center;
    color: #ffffff;
    background-color: #000000;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #000000;
    text-decoration: none;
}
.give_next_products:hover {
    background-color: #ffffff;
    color: #000000;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.catalog_section {
    margin-bottom: 40px;
}
.filter_and_catalog {
    max-width: 2000px;
    padding: 0px 40px 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.breadcrmbs_catalog {
    display: flex;
    width: 100%;
    padding: 40px 0px;
}
.seo_text {
    margin-top: 40px;
    padding: 20px;
    width: 100%;
    max-width: 1800px;
}
.seo_text ul {
    padding-left: 20px;
}

@media (max-width: 1891px) {
    .products_wrapper.small {
        max-width: 1359px;
    }

    .seo_text {
        max-width: 1399px;
    }
}
@media (max-width: 1024px) {
    .sale__label {
        padding: 4px 8px 4px 6px;
        font-size: 14px;
    }
    .one_product_wrapper {
        max-width: 252px;
    }
    .one_product {
        justify-content: space-between;
    }
    .products_wrapper.standart .link_product_page {
        height: 377px;
    }
    .products_wrapper.small .link_product_page {
       width: 116px;
       height: 173px;
    }
    .products_wrapper.small .one_product_wrapper {
        max-width: 125px;
        max-height: 183px;
    }
    .products_wrapper.standart .name_product_text {
        min-height: 32px;
    }
    .products_wrapper.standart .price_product_wrapper {
        margin-top: 10px;
    }
    .name_product_text {
        font-size: 12px;
        line-height: 16px;
    }
    .price_product {
        font-size: 12px;
        line-height: 15px;
    }
    .price_product.old_price {
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px;
    }
    .give_next_products {
        margin-top: 40px;
    }
    .catalog_section {
        height: auto;
    }
    .filter_and_catalog {
        height: auto;
    }
    .products_wrapper.standart .container_wrapper_product {
        min-height: 120px;
        height: auto;
    }
    .products_wrapper.standart .add_product_to_cart {
        min-height: 40px;
        font-size: 12px;
    }

    .products_wrapper.standart .one_product_wrapper .controll_panel_offers_and_colors {
        justify-content: flex-end;
    }
    .controll_panel_offers_and_colors {
        position: relative;
        opacity: 1;
        height: 100%;
    }

    .breadcrmbs_catalog {
        padding: 20px 0px;
    }


    .circle_colorpiker_wrapper {
        width: 15px;
        height: 15px;
    }

    .black_friday {
        max-width: 115px;
        padding: 4px;
    }
    .black_friday svg {
        margin-right: 5px;
        width: 8px;
        height: 16px;
    }
    .black_friday span {
        font-size: 12px;
    }


    .name_color_hover {
        font-size: 12px;
    }
}
@media (max-width: 832px) {
   .products_wrapper {
       margin-top: 10px;
   }
}

@media (max-width: 768px) {
    .filter_and_catalog {
        padding: 0px;
        width: 100%;
    }
    .one_product_wrapper {
        max-width: 351px;
        margin: 10px;
    }

    .products_wrapper.standart .link_product_page {
        min-height: 310px;
        height: 100%;
    }
    .image_container img {
        object-fit: contain;
    }
    .price_product {
        font-size: 14px;
        line-height: 18px;
    }
    .price_product.old_price {
        font-size: 12px;
    }
    .sell_price_product {
        font-size: 14px;
        line-height: 18px;
    }
    .products_wrapper {
        padding: 0px 10px;
    }
    .products_wrapper.small .one_product_wrapper {
        max-width: 160px;
        min-height: 325px;
        max-height: unset;
        padding: 0px;
        margin: 5px 10px;
    }
    .products_wrapper.small .link_product_page {
        width: 100%;
        height: 100%;
    }
    .products_wrapper.small .one_product {
        border: 1px solid black;
        max-height: unset;
    }
    .products_wrapper.small .name_wrapper_product {
        display: flex;
    }

    .products_wrapper.small .name_product_text {
        display: flex;
        font-size: 12px;
        line-height: 16px;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .products_wrapper.small .price_product_wrapper {
        display: flex;
    }
    .products_wrapper.small .price_product {
        font-size: 12px;
        line-height: 13px;
    }
    .products_wrapper.small .price_product.old_price {
        font-size: 10px;
        line-height: 13px;
    }
    .products_wrapper.small .sell_price_product {
        font-size: 12px;
        line-height: 13px;
    }
    .products_wrapper.small .image_container {

    }
    .products_wrapper.small .link_product_page {
        height: 248px;
    }
    .products_wrapper.small .container_wrapper_product {
        padding: 10px;
        min-height: 54px;
        height: 100%;
    }
    .breadcrmbs_catalog {
        padding: 20px;
    }

    .products_wrapper.standart .name_product_text {
        min-height: auto;
    }



}

@media (max-width: 380px) {
    /*.products_wrapper.standart .link_product_page {*/
    /*    height: 415px;*/
    /*}*/
    .products_wrapper.small .one_product_wrapper {
        max-width: 130px;
        min-height: 250px;
    }
    .products_wrapper.small .link_product_page {
        height: 198px;
    }
    .products_wrapper.small .container_wrapper_product {
       padding: 5px;
    }
}

@media screen and (max-width: 1908px){
    .products_wrapper,
    div.products_wrapper.small {
        max-width: 1359px;
    }
    .banner_container {
        max-width: 1359px;
    }
}

@media screen and (max-width: 1438px){
    .products_wrapper,
    div.products_wrapper.small{
        max-width: 906px;
    }

    .banner_container {
        max-width: 906px;
    }
}

@media screen and (max-width: 1908px){
    .products_wrapper.small {
        max-width: 906px;
    }

    .products_wrapper.small .one_product_wrapper {
        max-width: 113px;
    }
}

@media screen and (max-width: 1024px){
    .products_wrapper,
    div.products_wrapper.small {
        max-width: 756px;
    }

    .products_wrapper.small .one_product_wrapper {
        max-width: 108px;
    }

    .banner_container {
        max-width: 756px;
    }
}

@media screen and (max-width: 835px){
    .products_wrapper,
    div.products_wrapper.small {
        max-width: 504px;
    }

    .products_wrapper.small .one_product_wrapper {
        max-width: 126px;
    }

    .banner_container {
        max-width: 504px;
    }
}

@media screen and (max-width: 768px){
    .products_wrapper,
    .products_wrapper.small {
        max-width: 100%;
        justify-content: flex-start;
    }

    .banner_container {
        max-width: 100%;
    }

    .one_product_wrapper {
        max-width: 46%;
    }

    .products_wrapper.small .one_product_wrapper {
        max-width: 29%;
    }
}

@media screen and (max-width: 520px){
    .products_wrapper {
        max-width: 100%;
        justify-content: center;
    }

    .products_wrapper.small {
        justify-content: flex-start;
    }

    .products_wrapper.small .one_product_wrapper {
        max-width: 43%;
    }

    .one_product_wrapper {
        max-width: 320px;
    }
    .breadcrmbs_catalog {
        padding: 10px 5px 20px 20px;
    }
}