.resultModal {
    max-width: 657px;
    width: 100%;
    min-height: 320px;
    position: fixed;
    background: white;
    z-index: -40;
    opacity: 0;
    transform: translate(-50%, -50%);
    left: 250%;
    top: 50%;
    transition: opacity 0.2s ease 0s;

}
.result_modal_wrapper {
    display: flex;
    flex-direction: column;
    padding: 80px;
}

.resultModal.active {
    z-index: 20;
    opacity: 1;
    transition: opacity 0.6s ease 0.4s;
    border: 2px solid black;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image_status {
    width: 70px;
    height: 70px;
    position: relative;
    margin: 0 auto;
}
.image_status.success:after {
    content: "";
    height: 7px;
    width: 36px;
    background: #248F5C;
    position: absolute;
    left: -4px;
    border-radius: 10px;
    transform: rotate(45deg);
    bottom: 11px;
}

.image_status.success:before {
    content: "";
    height: 7px;
    width: 69px;
    background: #248F5C;
    position: absolute;
    left: 13px;
    border-radius: 10px;
    transform: rotate(-45deg);
    bottom: 22px;
}

.image_status.error:after {
    content: "";
    height: 7px;
    width: 69px;
    background: #CE3F3F;
    position: absolute;
    left: 2px;
    border-radius: 10px;
    transform: rotate(-45deg);
    bottom: 22px;
}
.image_status.error:before {
    content: "";
    height: 7px;
    width: 69px;
    background: #CE3F3F;
    position: absolute;
    left: 2px;
    border-radius: 10px;
    transform: rotate(45deg);
    bottom: 22px;
}

.heading_result_modal_text {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #1A1A1A;
    margin-top: 48px;
}

.assist_result_modal_text {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
}

.assist_result_model_content {
    max-width: 370px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #000000;
}

.result_model_button {

    max-width: 461px;
    width: 100%;
    height: 50px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    background: #1A1A1A;
    margin-top: 65px;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;

}

@media (max-width: 768px) {
    .result_modal_wrapper {
        padding: 10px;
    }
    .heading_result_modal_text {
        margin-top: 30px;
        font-size: 26px;
    }
    .assist_result_model_content {
        margin-top: 15px;
    }

}