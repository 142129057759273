
.spin_container {
    height: 100vh;
}
.spin_wrapper_custom {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 1;
    width: 8vmax;
    height: 8vmax;
    transform: translate(-50%,-50%);
}

.spin_wrapper_custom.formActive {
    opacity: 0;
    transition: 0.3s ease 0s;
}



div.spinner_custom:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid black;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}



div.spinner_custom:before {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid black;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
}

div.spinner_custom:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid black;
    animation: none;
}




@keyframes spinLeft {
    from {transform:rotate(0deg);}
    to {transform:rotate(720deg);}
}

@keyframes spinRight {
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
}



div.spinner_custom {
    width: 8vmax;
    height: 8vmax;
    opacity: 1;
    border-radius: 100%;
    animation: spinRight 800ms linear infinite;
}
