.one_use_line_st {
    display: flex;
    width: 100%;
    height: 50px;
    border: 1px solid black;
    margin-top: 20px;
    cursor: pointer;
}

.one_use_line_st:hover {
    background-color: lightgrey;
}


.one_use_container {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 10px;
    padding: 5px 10px;
    font-weight: 600;
}

.one_use_container:first-child {
    margin-left: 0px;
}
.one_use_container.id {
    width: 120px;
}

.one_use_container.role {
    width: 140px;
}
.one_use_container.telephone {
    width: 180px;
}
 .one_use_container.name {
     width: 180px;
 }
.one_use_container.surname {
    width: 250px;
}
.one_use_container.email {
     width: 300px;
 }
