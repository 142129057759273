.slide_controller_panel_wrapper {
    display: flex;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #c9cccf;
    padding: 15px;
    margin-top: 50px;
    flex-direction: column;
    max-width: 1070px;

}
.slide_controller_settings_block {
    display: flex;
    margin-top: 20px;
}
.controll_slider_heading {
    display: flex;
    font-weight: 600;
    font-size: 16px;

}
.slide_controller_button {
    display: flex;
    padding: 15px;
    border: 1px solid gray;
    font-weight: 600;
    cursor: pointer;
}
.slide_controller_button.active{
    background-color: black;
    color: white;
}
.btn_wrapper_slide_controll {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;
    margin-left: 20px;
}
.btn_wrapper_slide_controll:first-child {
    margin-left: 0px;
}
.slide_controll_notif_text {
    display: flex;
    margin-top: 10px;
    color: grey;
    font-size: 12px;
    padding: 5px;
    font-weight: 600;
}
.one_order_add_area_top_line_notif_custom {
    width: 500px;
}

.products_in_slider {
    margin-top: 20px;
    display: flex;
    max-width: 1070px;
    padding: 15px;
    flex-wrap: wrap;
}
.one_slide_product_wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    max-width: 260px;
}
.one_slide_img_wrapper {
    width: 140px;
    height: 190px;
    display: flex;
}
.one_slide_img_wrapper img {
    display: flex;
    width: 100%;
    height: 100%;
}
.one_slide_product_wrapper span {
    font-weight: 600;
    margin-top: 10px;
    display: flex;
    height: 50px;
    align-items: center;
}
.destroy_one_slide {
    padding: 5px;
    cursor: pointer;
    border: 1px solid #CE3F3F;
    display: flex;
    align-items: center;
    justify-content: center;
}
.destroy_one_slide:hover {
    background-color: #CE3F3F;
}

.products_in_slider_heading {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    margin-top: 20px;
}
.categories_wrapper_slide {
    display: flex;
    width: 100%;
    max-width: 1070px;
}
.categories_slide_all {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 400px;
    overflow-y: scroll;
    margin-top: 30px;
}
.categories_slide_all.right {
    margin-top: 5px;
    height: 270px;
}
.one_item_slide_category {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 10px;
    border: 1px solid black;
    padding: 5px;
    font-weight: 600;
}
.categories_wrapper_slide {
    margin-top: 10px;
    padding: 10px 0px;

    border-bottom: 1px solid black;
    border-top: 1px solid gray;
    display: flex;
    justify-content: space-between;
}

.one_item_slide_category.go_select {
    position: relative;
}
.one_item_slide_category.go_select:after {
    content: "";
    height: 15px;
    width: 2px;
    display: flex;
    position: absolute;
    right: 10px;
    top: 3px;
    background-color: black;
    transform: rotate(135deg);
}
.one_item_slide_category.go_select:before {
    content: "";
    height: 15px;
    width: 2px;
    display: flex;
    position: absolute;
    right: 10px;
    top: 12px;
    background-color: black;
    transform: rotate(-135deg);
}

.one_item_slide_category:hover {
    background-color: black;
    color:white;
    transition: all 0.8s;
    cursor: pointer;
}

.one_item_slide_category:hover:after {
    background-color: white;
    transition: all 0.8s;
}
.one_item_slide_category:hover:before {
    background-color: white;
    transition: all 0.8s;
}

.one_item_slide_category.go_back {
    padding-left: 35px;
    position: relative;
}
.one_item_slide_category.go_back:after {
    content: "";
    height: 15px;
    width: 2px;
    display: flex;
    position: absolute;
    left: 10px;
    top: 3px;
    background-color: black;
    transform: rotate(-135deg);
}
.one_item_slide_category.go_back:before {
    content: "";
    height: 15px;
    width: 2px;
    display: flex;
    position: absolute;
    left: 10px;
    top: 12px;
    background-color: black;
    transform: rotate(135deg);
}
.wrapper_right_for_heading_slide_categories {
    max-width: 400px;
}
.heading_notificator_categories_slide {
    display: flex;
    margin-top: 10px;
    color: grey;
    font-size: 12px;
    padding: 5px;
    font-weight: 600;
}

