.wrapper_center_page {
    margin-top: 40px;
}

.personal_cabinet {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.user_personal_content {
    position: relative;
    height: max-content;
}
.user_info_container {
    display: none;
    flex-direction: column;
}

.user_info_container.active {
    display: flex;
}

.favorites_page_container {
    display: none;
}

.favorites_page_container.active {
    display: flex;
    flex-direction: column;
}

.my_offers_container {
    display: none;
}

.my_offers_container.active {
   display: flex;
}
.consultant_container {
    display: none;
}

.consultant_container.active {
    display: flex;

}


@media (max-width: 768px) {
    .wrapper_center_page {
        margin-top: 0px;
    }
    .user_personal_content {
        margin-left: 0px;
    }
}



