.breadcrumbsblack_content {
    left: 0px;
    padding-left: 30px;
    color: white;
    display: flex;
    height: 70px;
    align-items: center;
}

.breadcrumbsblack_item_content {
    display: flex;
    padding-right: 40px;
    position: relative;
    cursor: pointer;
}
.breadcrumbsblack_item_content a {
    text-decoration: none;
    font-weight: 400;
    color: #000000FF;
    letter-spacing: 0.03em;
    padding-bottom: 2px;
    border-bottom: 1px solid transparent;

}
.breadcrumbsblack_item_content:last-child a {
    font-weight: 600;
}

.breadcrumbsblack_item_content:first-child {
    margin-left: 0px;
    text-transform: uppercase;
}

.breadcrumbsblack_item_content:before {
    content: "";
    height: 1px;
    width: 7px;
    background-color: #000000FF;
    position: absolute;
    right: 15px;
    top: 10px;
    transform: rotate(-45deg);
}
.breadcrumbsblack_item_content:after {
    content: "";
    height: 1px;
    width: 7px;
    background-color: #000000FF;
    position: absolute;
    right: 15px;
    top: 5px;
    transform: rotate(45deg);
}
.breadcrumbsblack_item_content:last-child:after{
    display: none;
}
.breadcrumbsblack_item_content:last-child:before{
    display: none;
}
.breadcrumbsblack_item_content a:hover {
    border-bottom: 1px solid #1A1A1A;
    padding-bottom: 2px;
    transition: border-bottom 0.8s ease 0s;
}