.adm_page_fqa {
    padding: 20px;
}

.contacts_fqa_title {
    display: flex;
    width: 100%;
    height: 76px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}

.content_manager_fqa {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.left_block_seo_fqa {
    max-width: 50%;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.fields_seo_fqa {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.fqa_field_name {
    font-weight: 600;
    font-size: 12px;
}
.fqa_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.image_adm_fqa {
    position: relative;
    display: flex;
    width: 300px;
    max-height: 250px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 10px;
}
.questions_wrapper {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.add_new_question {
    display: flex;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 150px;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.add_new_question:hover {
    color: white;
    background: black;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.one_question_fqa {
    display: flex;
    flex-direction: column;
}
.fields_fqa_wrap {
    display: flex;
    margin-top: 20px;
}
.fields_fqa {
    width: 100%;
}
.label_fqa {
    font-weight: 600;
    font-size: 12px;
}
.field_text_fqa {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.field_text_fqa.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
}
.del_fqa {
    margin: 10px;
    cursor: pointer;
}
.feedback_block {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
}

.save_page_fqa {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 42px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
}
.save_page_fqa:hover {
    color: white;
    background-color: black;
    transition: color 0.8s ease 0s, background-color 0.8s ease 0s;
}
.save_page_fqa.active {
    animation: trigerFqaManager 2s ease infinite;
}


@keyframes trigerFqaManager {
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}

