.SuportLine {
    width: 100%;
    height: 30px;
}
.hpage_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.home_page_title {
    color: transparent;
    position: absolute;
    z-index: 0;
}
.main_wrapper {
    width: 100%;
}