.mainScreen {
  padding-bottom: 50px;
  background-color: #000000;
  margin-bottom: 60px;

  @media (max-width: 1024px) {
    padding-bottom: 60px;
  }

  @media (max-width: 768px) {
    padding-bottom: 30px;
    margin-bottom: 44px;
  }
}

.subtitle {
  color: #1a1a1a;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 0;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
  }

  @media (max-width: 768px) {
    margin-bottom: 26px;
    font-size: 24px;
  }
}

.title {
  text-transform: uppercase;
  margin-bottom: 24px;
  color: #fff;
  font-size: 140px;
  font-style: italic;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.4px;
  font-family: Druk Cyr;

  @media (max-width: 1024px) {
    gap: 15px;
    font-size: 120px;
  }

  @media (max-width: 768px) {
    margin-bottom: 36px;
    gap: 0;
    line-height: 80%; /* 96px */
  }

  span {
    color: #d3ff24;
    letter-spacing: 1.4px;
    font-family: Druk Cyr;
  }

  svg {
    @media (max-width: 1024px) {
      width: 90px;
      height: auto;
      display: inline-block;
    }
  }
}

.text {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-bottom: 45px;
  max-width: 832px;

  @media (max-width: 1024px) {
    max-width: 600px;
  }

  @media (max-width: 768px) {
    margin-bottom: 35px;
    font-size: 14px;
  }
}

.container {
  padding-inline: 40px;

  @media (max-width: 1024px) {
    padding-inline: 40px;
  }

  @media (max-width: 768px) {
    padding-inline: 20px;
  }
}

.becomeMember {
  background: #d3ff24;
  color: #000000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-block: 14px 15px;
  min-width: 326px;
  cursor: pointer;
  transition: 0.3s;

  @media (hover: hover) {
    &:hover {
      background-color: #fff;
    }
  }

  @media (max-width: 1024px) {
  }

  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
  }
}

.infoWrapper {
  margin-top: 81px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1px;

  @media (max-width: 1024px) {
    margin-top: 90px;
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    margin-top: 70px;
  }
}

.infoItem {
  box-shadow: 0 0 0 1.5px #ffffff;
  padding: 20px;
  min-height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    min-height: 194px;
  }

  @media (max-width: 768px) {
  }

  &:last-child {
    @media (max-width: 768px) {
      p {
        font-size: 24px;
        max-width: 200px;
      }
    }
  }

  p {
    margin-top: 0;
    max-width: 301px;
    color: #fff;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    position: relative;
    z-index: 1;

    @media (max-width: 1024px) {
      br {
        display: none;
      }
    }

    @media (max-width: 768px) {
      font-size: 32px;

      br {
        display: block;
      }
    }

    span {
      display: block;
      margin-top: 15px;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 26px */

      @media (max-width: 1024px) {
        margin-top: 9px;
      }

      @media (max-width: 768px) {
        margin-top: 13px;
        font-size: 16px;
      }
    }
  }

  img {
    position: absolute;
    pointer-events: none;
    width: 60.09%;
    top: 0;
    right: 0;
    height: auto;

    @media (max-width: 1024px) {
      width: 258px;
    }

    @media (max-width: 768px) {
      width: 221px;
    }
  }
}

.typeSection {
  margin-bottom: 120px;

  @media (max-width: 1024px) {
    margin-bottom: 100px;
  }

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
}

.typeWrapper {
  margin-bottom: 60px;
  display: grid;
  gap: 1px;

  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.typeButton {
  border: 1px solid #1a1a1a;
  padding: 14px 33px;
  color: #1a1a1a;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-inline: auto;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s;

  @media (hover: hover) {
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.type {
  display: flex;
  box-shadow: 0 0 0 1px #000000;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    box-shadow: 0 1px 0 0 #000000, 0 -1px 0 0 #000000;
    padding: 14px 0 30px;
  }

  &:nth-child(1) {
    .typeLevel {
      flex-basis: 287px;

      @media (max-width: 1300px) {
        flex-basis: auto;
      }
    }
  }
  &:nth-child(2) {
    .typeLevel {
      flex-basis: 287px;

      @media (max-width: 1300px) {
        flex-basis: auto;
      }
    }
  }
  &:nth-child(3) {
    .typeLevel {
      flex-basis: 437px;

      @media (max-width: 1300px) {
        flex-basis: auto;
      }
    }
  }

  &ImgWrapper {
    flex-basis: 323px;
    flex-shrink: 0;
    box-shadow: 1px 0 0 0 #000000;

    @media (max-width: 1300px) {
      position: relative;
    }

    @media (max-width: 768px) {
      box-shadow: none;
      flex-basis: auto;
      width: fit-content;
      max-width: 100%;
      margin: 0 auto 20px;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;

      @media (max-width: 768px) {
        box-shadow: 0 0 0 1px #000000;
      }
    }

    svg {
      position: absolute;
      bottom: 16px;
      right: 24px;
      width: 274px;
      height: auto;
      display: block;

      @media (max-width: 1300px) {
        bottom: 6px;
        right: 16px;
        width: 183px;
      }

      @media (max-width: 768px) {
        right: -19px;
        bottom: -48px;
      }
    }
  }

  &Content {
    flex-grow: 1;
    padding: 25px 31px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1300px) {
      padding: 18px 24px 26px;
    }

    @media (max-width: 768px) {
      display: contents;
    }
  }

  &Top {
    display: flex;
    width: 100%;
    gap: 28px;
    flex-wrap: wrap;

    @media (max-width: 1300px) {
      flex-direction: column;
    }

    @media (max-width: 768px) {
      display: contents;
    }

    &Title {
      display: block;
      margin-bottom: 18px;
      margin-top: 0;
      color: #1a1a1a;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 18px */

      @media (max-width: 1300px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }

  &Level {
    @media (max-width: 768px) {
      order: -1;
      margin-bottom: 20px;
    }

    svg {
      @media (max-width: 1300px) {
        display: block;
        width: 44px;
        height: auto;
      }
    }

    p {
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #1a1a1a;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 44px */
      text-transform: uppercase;

      @media (max-width: 1300px) {
        font-size: 36px;
      }
    }
  }

  &Amount {
    @media (max-width: 768px) {
      margin-bottom: 22px;
    }

    p {
      margin: 0;
      color: #1a1a1a;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 44px */
      text-transform: uppercase;
      opacity: 0.4;

      @media (max-width: 1300px) {
        font-size: 30px;
      }
    }
  }

  &Center {
    max-width: 591px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: 1300px) {
      max-width: 314px;
    }

    @media (max-width: 768px) {
      margin-bottom: 22px;
      max-width: 314px;
      display: grid;
      gap: 59px;
      grid-template-columns: 98px 1fr;
    }

    span {
      color: #1a1a1a;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1.76px;
      display: block;

      @media (max-width: 1300px) {
        font-size: 30px;
        letter-spacing: 1.2px;
      }
    }

    p {
      margin-bottom: 0;
      margin-top: 1px;
      color: #1a1a1a;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 26px */

      @media (max-width: 1300px) {
        margin-top: 4px;
        font-size: 16px;
      }
    }
  }

  &Bottom {
    svg {
      @media (max-width: 1300px) {
        display: block;
        height: auto;
        width: 30px;
      }
    }
    p {
      max-width: 212px;
      margin-bottom: 0;
      margin-top: 11px;
      color: #1a1a1a;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 26px */

      @media (max-width: 1300px) {
        margin-top: 8px;
        max-width: 297px;
        font-size: 16px;
      }
    }
  }
}

.receiving {
  &Section {
    margin-bottom: 120px;

    @media (max-width: 1024px) {
      margin-bottom: 100px;
    }

    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
  }

  &Wrapper {
    display: flex;
    align-items: stretch;
    gap: 32px;

    @media (max-width: 1305px) {
      flex-direction: column;
      gap: 36px;
    }

    @media (max-width: 1024px) {
    }

    @media (max-width: 768px) {
      gap: 30px;
    }
  }

  &Content {
    flex-basis: 664px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 1305px) {
      flex-basis: auto;
    }
  }

  &Img {
    flex-grow: 1;
    flex-basis: 664px;

    @media (max-width: 1305px) {
      flex-basis: auto;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 1000px;
    }
  }

  &Item {
    padding-top: 38px;
    border-top: 1.5px solid #1a1a1a;

    @media (max-width: 1305px) {
      padding-bottom: 52px;
    }

    @media (max-width: 768px) {
      padding-top: 28px;
      padding-bottom: 32px;
    }

    img {
      pointer-events: none;
      position: absolute;
      bottom: -91px;
      right: 45px;
      width: 214px;
      height: auto;

      @media (max-width: 1305px) {
        right: 75px;
      }

      @media (max-width: 768px) {
        transform: rotate(10deg);
        width: 169px;
        bottom: -53px;
        right: 28px;
      }
    }

    b {
      display: flex;
      gap: 11px;
      align-items: center;
      margin-bottom: 32px;
      color: #1a1a1a;
      font-size: 54px;
      font-style: normal;
      font-weight: 500;
      line-height: 90%; /* 48.6px */
      letter-spacing: -1.08px;

      @media (max-width: 768px) {
        font-size: 48px;
        letter-spacing: -0.96px;
      }

      svg {
        flex-shrink: 0;
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
      color: #1a1a1a;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 23.4px */

      @media (max-width: 768px) {
        font-size: 16px;
      }

      small {
        font-size: 46px;
        font-style: normal;
        font-weight: 500;
        line-height: 90%;
        letter-spacing: -0.92px;

        @media (max-width: 768px) {
          font-size: 40px;
          letter-spacing: -0.8px;
        }
      }
    }
  }

  &App {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 108px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 9px;
      margin-top: 37px;
    }

    svg {
      width: auto;
      height: auto;
      display: block;

      @media (max-width: 768px) {
        &:nth-child(1) {
          width: 141px;
        }

        &:nth-child(2) {
          width: 131px;
        }
      }
    }
  }

  &Row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &:last-child {
      display: block;
      padding-bottom: 57px;
      border-bottom: 1.5px solid #1a1a1a;
      overflow: hidden;
      position: relative;

      @media (max-width: 1305px) {
        padding-bottom: 5px;
      }

      @media (max-width: 768px) {
        padding-bottom: 140px;
      }
    }
  }

  &Button {
    margin-top: 60px;
    color: #fff;
    background-color: #000000;

    @media (max-width: 1024px) {
      margin-top: 50px;
    }

    @media (max-width: 768px) {
      margin-top: 30px;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }
}

.questions {
  &Section {
    margin-bottom: 165px;

    @media (max-width: 1024px) {
      margin-bottom: 140px;
    }

    @media (max-width: 768px) {
      margin-bottom: 100px;
    }
  }

  &Wrapper {
    display: flex;
    justify-content: space-between;
    gap: 32px;

    @media (max-width: 1024px) {
      gap: 80px;
      flex-direction: column;
    }

    @media (max-width: 768px) {
      gap: 60px;
    }
  }

  &List {
    flex-basis: 785px;

    @media (max-width: 1024px) {
      flex-basis: auto;
    }
  }
}

.contacts {
  flex-basis: 434px;

  @media (max-width: 1024px) {
    flex-basis: auto;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 1024px) {
      gap: 16px;
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  h3 {
    margin-top: 0;
    margin-bottom: 33px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }

  p {
    margin-top: 0;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin-bottom: 4px;
  }

  a {
    color: #1a1a1a;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: block;
    text-decoration: none;
  }
}
