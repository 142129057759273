.content {
    padding: 30px 29px 17px;
}

.section {
    margin-bottom: 40px;
}

.section.sectionColors {
    padding-bottom: 18px;
    border-bottom: 2px solid #000000;
    margin-bottom: 18px;
}

.section:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}

.sectionTitle {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 30px;
}

.colors {
    list-style: none;
    padding: 4px;
    margin: -4px;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 140px 140px 140px;
    justify-content: space-between;
    gap: 24px 9px;
    max-height: 198px;
}

.colors::-webkit-scrollbar {
    background: #F4F4F4;
    width: 10px;
}
.colors::-webkit-scrollbar-thumb {
    background: #000000;
}

.colorLabel {
    display: flex;
    align-items: center;
    gap: 11px;
    cursor: pointer;
}

.colorInput {
    position: absolute;
    visibility: hidden;
}

.colorInput:checked ~ .colorContent {
    color: #000;
    text-decoration: underline;
}

.colorInput:checked ~ .colorControl {
    box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 3px var(--background);
}

.colorControl {
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(39, 39, 39, 0.4);
    flex-shrink: 0;
    background: var(--background, #FFFFFF);
    background-position: center;
    background-size: cover;

    transition: .2s ease-in-out;
}

.colorContent {
    color: rgba(0, 0, 0, 0.70);
    font-size: 13.76px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    word-break: break-word;
    transition: .2s ease-in-out;
}

.sizes {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    list-style: none;
}

.sizesLabel {
    cursor: pointer;
}

.sizesInput {
    position: absolute;
    visibility: hidden;
}

.sizesInput:checked ~ .sizesControl {
    color: #FFFFFF;
    border-color: #000000;
    background: #000000;
}

.sizesControl {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border: 1px solid #828282;
    
    transition: .2s ease-in-out;
}

.price {
    margin-top: -14px;
    padding: 7px;
    overflow: hidden;
}

.priceTrack {
    height: 1px;
    background: #1A1A1A;
    width: 100%;
}

.priceThumb {
    background: #1A1A1A;
    width: 13px;
    height: 13px;
    border-radius: 50%;
}

.priceLabel {
    position: absolute;
    bottom: 100%;
    white-space: nowrap;

    color: rgba(0, 0, 0, 0.70);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.trends {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.trendsLabel {
    cursor: pointer;
}

.trendsInput {
    position: absolute;
    visibility: hidden;
}

.trendsInput:checked ~ .trendsControl {
    color: #FFFFFF;
    border-color: #000000;
    background: #000000;
}

.trendsControl {
    padding: 0 19px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #808080;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border: 1px solid #828282;

    transition: .2s ease-in-out;
}

.acceptBtn {
    cursor: pointer;
    padding: 0;
    margin: 40px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #1A1A1A;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    height: 50px;
}

.acceptBtn:disabled {
    cursor: default;
    opacity: 0.7;
    transition: opacity 0.4s ease 0s;
    -webkit-user-select: none;
    user-select: none;
}

.priceInputs {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.priceInput {
    position: relative;
}

.priceInputLabel {
    font-size: 12px;
    display: block;
}

.priceInputField {
    display: block;
    height: 30px;
    font-size: 16px;
    border-bottom: 1px solid #828282;
    transition: .3s;
}

.priceInputField:focus {
    border-color: #000000;
}

.priceInputsDeliver {
    flex-shrink: 0;
    margin: 0 16px;
}

@media all and (max-width: 1024px) {
    .content {
        padding: 30px 31px 17px 29px;
    }
}

@media all and (max-width: 768px) {
    .priceInputField {
        width: 100px;
    }

    .content {
        padding: 20px 27px 19px;
    }
    
    .colors {
        grid-template-columns: 1fr 1fr;
    }
    
    .section {
        margin-bottom: 40px;
    }

    .section.sectionColors {
        padding-bottom: 20px;
        border-width: 1px;
        margin-bottom: 20px;
    }

    .sectionTitle {
        margin-bottom: 20px;
        font-size: 18px;
    }

    .sizes {
        gap: 20px;
    }

    .price {
        margin-top: 45px;
    }

    .priceLabel {
        font-size: 12px;
    }

    .acceptBtn {
        margin-top: 24px;
    }

    .trendsControl {
        height: 37px;
        padding: 0 9px;
        font-size: 12px;
    }
}