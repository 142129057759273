.wrapper_status_adm_page {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 20px;
}
.status_adm_title {
    margin-top: 15px;
    margin-left: 10px;
    font-weight: 600;
    font-size: 22px;
}
.status_adm_page_work_area {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    display: flex;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    flex-direction: column;
}
.input_wrapper_status {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.status_page_heading_input {
    display: flex;
    font-weight: 600;
}
.input_status_page {
    width: 400px;
    height: 30px;
    border: 1px solid black;
    padding-left: 10px;
    margin-top: 10px;
    color: black;
    font-weight: 600;
}
.server_status_response {
    display: flex;
    height: 40px;
    align-items: center;
    font-weight: 500;
}
.save_status_page_btn {
    text-transform: uppercase;
    width: 160px;
    height: 30px;
    border: 1px solid black;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}
.save_status_page_btn:hover {
    color: white;
    background-color: black;
}