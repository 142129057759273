.personal_controll_bar{
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 300px;
    width: 100%;
    /*align-items: flex-end;*/
    position: relative;
}

.support_fix_controll_bar {
    display: flex;
    flex-direction: column;
    height: max-content;
    /*width: 100%;*/
    /*align-items: flex-end;*/
    position: sticky;
    top: 115px;
}




.user_item_controll_bar {
    /*text-transform: uppercase;*/
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /*text-align: right;*/
    color: #000000;
    padding: 8px 0px;
    cursor: pointer;
    text-decoration: unset;
    display: flex;
}
/*.user_item_controll_bar.chat:hover {*/
/*    font-weight: 700;*/
/*    transition: font-weight 0.3s ease 0s;*/
/*}*/
.user_item_controll_bar.active {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    padding-right: 26px;
    position: relative;
    width: max-content;
}

.user_pages_controller {
    margin-top: 235px;
    position: relative;
}


.user_item_controll_bar.disabled {
    opacity: 0.1;
    pointer-events: none;
    user-select: none;
    padding-right: 0px!important;
}



/*.user_item_controll_bar.active:after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 17px;*/
/*    right: 10px;*/
/*    width: 2px;*/
/*    height: 13px;*/
/*    transform: rotate(45deg);*/
/*    background: black;*/
/*    border-radius: 10px;*/
/*}*/

.user_item_controll_bar.active:before {
    content: "";
    position: absolute;
    top: 14px;
    right: 10px;
    width: 2px;
    height: 13px;
    transform: rotate(90deg);
    background: black;
}
.exit_wrapper_usercabinet {
    display: flex;
    align-items: center;
    margin-top: 70px;
    /*cursor: pointer;*/
}

.exit_text_usercabinet {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #000000;
    margin-right: 20px;
}


/*@media (max-width: 1024px) {*/
/*    .personal_controll_bar {*/
/*       max-width: unset;*/
/*        align-items: flex-start;*/
/*    }*/
/*    .fio_container_controll_bar {*/
/*        left: 0;*/
/*        right: unset;*/
/*        align-items: flex-start;*/
/*    }*/
/*    .user_pages_controller {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        align-items: flex-start;*/
/*    }*/

/*}*/


@media (max-width: 1024px) {
    .user_pages_controller {
        margin-top: 150px;
    }
    .personal_controll_bar {
        max-width: 200px;
    }
    .user_item_controll_bar {
        font-size: 14px;
    }

}