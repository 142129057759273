.adv_wrapper_item {
    max-width: 291px;
    display: flex;
    align-items: center;
    margin-top: 41px;
    padding: 10px;
}
.adv_wrapper_item:hover {
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 14px 0px rgba(34, 60, 80, 0.1) inset;
    -moz-box-shadow: 0px 0px 14px 0px rgba(34, 60, 80, 0.1) inset;
    box-shadow: 0px 0px 14px 0px rgba(34, 60, 80, 0.1) inset;
}
adv_wrapper_item img {
    width: 80px;
    height: 80px;
}
.adv_wrapper_item span {
    display: flex;
    margin-left: 20px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
.link_adv {
    text-decoration: none;
}
.link_adv.non{
    user-select: none;
    pointer-events: none;
}

@media (max-width: 1024px) {
    .adv_wrapper_item {
        max-width: 280px;
        height: auto;
        margin-top: 30px;
        padding: 0px;
        justify-content: space-between;
    }
    .adv_wrapper_item img {
        max-width: 40px;
        width: 100%;
    }
    .adv_wrapper_item span {
        margin-left: 12px;
        text-align: start;
    }
}

@media (max-width: 768px) {
    .adv_wrapper_item {
        margin-top: 18px;
        align-items: flex-end;
        justify-content: flex-start;
    }
    .adv_wrapper_item span {
        margin-left: 15px;
    }

}
@media (max-width: 320px) {
    .adv_wrapper_item {
        max-width: 233px;
        margin-top: 30px;
    }
}