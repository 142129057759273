.page_about {
    width: 100%;
}
.about_content_wrapper {
    max-width: 1920px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}
.big_block {
    position: relative;
    display: flex;
}
.big_block.end {
    flex-direction: column;
    justify-content: flex-end;
    background: linear-gradient(199.83deg, #F9F9FB 15.76%, #F8F8FA 86.75%);
    height: 1025px;
}
.big_block.three {
    background: linear-gradient(180deg, #FCFCFE 7.9%, #F8F8FA 100%);
    justify-content: center;
}
.big_block.finish {
    background: #0A0B0D;
}
.big_block_background {
    position: absolute;
    max-width: max-content;
    width: 100%;
    z-index: 0;
    object-fit: contain;
    height: 100%;
}
.big_block_photo{
    max-width: max-content;
    height: auto;
    object-fit: contain;
}
.container_title_and_text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 40px 40px 60px 40px;
    position: relative;
}
.about_title_img {
    display: flex;
    width: 100%;
}
.big_block_text_wrapper {
    max-width: 648px;
    width: 100%;
    margin-top: 40px;
}
.title_about {
    font-weight: 500;
    font-size: 36px;
    line-height: 150%;
    color: #1A1A1A;
}
.title_about.new_size {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #000000;
}
.text_about{
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #1A1A1A;
    margin-top: 18px;
    white-space: pre-line;
}
.text_about.new_size {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.text_about.grey {
    color: #7C7C7C;
}
.small_block_wrapper {
    display: flex;
}
.small_block_wrapper.reflect {
    flex-direction: row-reverse;
}
.small_left_block {
    padding: 60px 40px;
    width: 50%;
}
.small_right_block {
    width: 50%;
    display: flex;
}
.small_block_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.big_block_center_text {
    width: 100%;
    display: flex;
}
.big_block_text_center {
    max-width: 342px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
}
.large_block_center {
    display: flex;
    max-width: 1030px;
    width: 100%;
    justify-content: flex-end;
}
.finish_block_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.about_new_logo {
    font-family: 'Druk Cyr';
    font-style: italic;
    font-weight: 700;
    font-size: 120.332px;
    line-height: 153px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.about_button_catalog {
    max-width: 182px;
    width: 100%;
    padding: 14px;
    border: 1px solid #757575;
    color: white;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about_button_catalog:hover {
    background: white;
    color: black;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
.big_block_finish_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}


@media (max-width: 1024px) {
    .big_block.end {
        height: 538px;
    }
    .container_title_and_text {
        padding: 85px 40px;
    }
    .big_block_text_wrapper {
        max-width: 422px;
    }
    .title_about {
        font-size: 24px;
        line-height: 150%;
    }
    .text_about {
        font-size: 12px;
        line-height: 150%;
        margin-top: 10px;
    }
    .small_left_block {
        padding: 20px 40px;
    }
    .large_block_center {
        height: 538px;
        max-width: 675px;
    }
    .big_block_text_center {
        margin-left: 20px;
        max-width: 251px;
    }
    .title_about.new_size {
        font-size: 24px;
        line-height: 31px;
    }
    .text_about.new_size{
        font-size: 12px;
        line-height: 16px;
    }
    .big_block.finish {
        height: 538px;
    }
    .about_new_logo {
        font-size: 106px;
        line-height: 135px;
    }
}

@media (max-width: 768px) {
    .big_block.end {
        /*height: 660px;*/
        height: auto;
    }
    .big_block_background {
        position: relative;
    }
    .container_title_and_text {
        padding: 20px;
        background: white;
        position: relative;
        align-items: flex-start;
    }
    .about_title_img {
        top: -115px;
        left: 0;
        position: absolute;
        padding: 20px;
    }
    .big_block_text_wrapper {
        margin-top: 0px;
    }
    .big_block_background {
        height: auto;
    }
    .title_about {
        font-size: 18px;
        line-height: 150%;
    }
    .small_block_wrapper {
        flex-direction: column-reverse;
    }
    .small_right_block {
        width: 100%;
    }
    .small_block_img {
        height: auto;
        object-fit: contain;
    }
    .small_left_block {
        padding: 20px;
        width: 100%;
    }
    .title_about{
        font-size: 18px;
        line-height: 23px;
    }
    .small_block_wrapper.reflect {
        flex-direction: column-reverse;
    }
    .large_block_center {
        flex-direction: column;
        height: auto;
    }
    .big_block_photo {
        max-height: 519px;
        width: auto;
        height: 100%;
        margin: 0 auto;
    }
    .big_block_text_center {
        margin-left: 0px;
        padding: 20px;
        max-width: 100%;
    }
    .text_about.grey {
        font-size: 16px;
        line-height: 21px;
    }
    .title_about.new_size {
        font-size: 18px;
        line-height: 23px;
    }
    .text_about.new_size {
        font-size: 16px;
        line-height: 21px;
    }
    .finish_block_wrapper {
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    .big_block_photo.finish {
        max-width: 230px;
        height: auto;
        position: absolute;
        bottom: 0;
    }
    .big_block_finish_text {
        padding: 20px;
    }
    .about_new_logo {
        font-size: 100px;
        line-height: 130px;
    }
    .about_button_catalog {
        margin-top: 20px;
    }
}