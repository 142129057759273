.block_trends {
    width: 100%;
}
.control_unit {
    padding: 0px 40px;
}
.title_block_stock {
    margin-top: 60px;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    display: flex;
    color: #000000;
}
.switch_and_arrows {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.stock_switch {
    display: flex;
    max-width: max-content;
    justify-content: space-between;
    border-bottom: 0.5px solid #959595;
}
.switch_trends {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    cursor: pointer;
}
.switch_trends.active {
    font-weight: 500;
    color: #1A1A1A;
    padding-bottom: 10px;
    border-bottom: 2px solid black;
}
.switch_trends.trends {
    margin-left: 30px;
}
.arrows_switch {
    display: flex;
    max-width: 60px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}
.trends-slider {
    margin-top: 18px;
    display: flex;
    flex-direction: column-reverse;
}
.trends-slider .swiper-slide {
    width: max-content;
}
.trends-slider .swiper-scrollbar {
    margin-top: 25px;
    position: static;
    background: #cecece;
    height: 3px;
    width: auto;
    border-radius: 0;
}
.trends-slider .swiper-scrollbar-drag {
    background: rgb(25,25,25);
    border-radius: 0;
}
.scroll_block_sale {
    margin-top: 18px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 25px;
    display: flex;
    scroll-behavior: smooth;
}
.scroll_block_sale::-webkit-scrollbar {
    height: 3px;
}
.scroll_block_sale::-webkit-scrollbar-track {
    background: #cecece;
    border: 1px solid transparent;
    background-clip: content-box;   /* THIS IS IMPORTANT */
}
.scroll_block_sale::-webkit-scrollbar-thumb {
    background: rgb(25,25,25);
    border: 1px solid rgb(0, 0, 0);
}
.link_trading {
    text-decoration: none;
}
.link_trading:hover .name_action {
    text-decoration: underline;
    transition: text-decoration 0.4s ease 0s;
}
.link_trading.non{
    user-select: none;
    pointer-events: none;
}
.one_block_action {
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
}
.img_action {
    display: flex;
}
.img_action img {
    width: 574px;
    height: 231px;
    object-fit: cover;
}
.name_action {
    margin-top: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    max-width: 350px;
}

@media (max-width: 1024px) {
    .title_block_stock {
        font-size: 18px;
        line-height: 23px;
        margin-top: 60px;
    }
    .switch_and_arrows {
        margin-top: 20px;
    }
    .switch_trends {
        font-size: 14px;
        line-height: 18px;
    }
    .scroll_block_sale {
        margin-top: 20px;
    }
    .trends-slider {
        margin-top: 20px;
    }
}


@media (max-width: 768px) {
    .scroll_block_sale {
        margin-top: 15px;
    }
    .trends-slider {
        margin-top: 15px;
    }
    .title_block_stock {
        margin-top: 40px;
    }
    .control_unit {
        padding: 0px 20px;
    }
    .img_action img {
        width: 332px;
        height: 173px;
    }
    .one_block_action {
        margin: 0px 10px;
    }
    .name_action {
        font-size: 14px;
        line-height: 18px;
    }
}