.dragDrop_preview {
    width: 100%;
    height: 140px;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgb(74, 72, 72);
    border-radius: 5px;
    position: relative;
    padding: 10px 0;
}

.drop_area {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(172, 171, 171, 0.2);
}
.drop_unactive_area {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(218, 216, 216, 0.05);
    position: relative;

}
.prev_photo_adm {
    max-width: 150px;
    height: 100%;
    position: relative;
    margin-left: 10px;

}
.prev_photo_adm  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}
.inp_file {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    display: flex;
    max-width: 100%;
    top: 0px;
    left: 0px;
}