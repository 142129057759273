.birthDayWrapper {
    margin-top: 25px;
}
.title_birthday_lk {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.error_birthDay {
    /*height: 20px;*/
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #CE3F3F;
    font-size: 11px;
    /*text-transform: uppercase;*/
    font-weight: 600;
    letter-spacing: 0.03em;
}
.birthDayWrapper .user_birthday.user_field {
    margin-top: 7px;
    /*height: 40px;*/
    padding: 5px;
}


.birthDayWrapper.error  .user_birthday{
    border-color: #CE3F3F;
    transition: border-color 0.4s ease 0s;
}

