.leftStaticMenu {
    width: 300px;
    /*background-color: salmon;*/
    height: 100%;

    margin-top: 30px;
    padding-top: 20px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;


}

.menu_controller {
    display: flex;
    padding: 10px 25px;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    color: black;
}

.menu_controller:hover {
    background-color: #bcbcbc4f;
}
.menu_controller.black {
    background-color: black;
    color: white;
}
.menu_controller.black:hover {
    background-color: black;
    color: white;
}
.menu_controller.black svg path {
 fill: white;
}

.menu_controller svg {
    max-height: 20px;
    max-width: 20px;
    width: 20px;
}
.menu_controller svg path {
    fill: gray;
}
.menu_controller_text {
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;

}
.pages_wrap_content {
    display: flex;
    flex-direction: column;
    padding: 0px 25px;
    background-color: whitesmoke;
    opacity: 0;
    height: 0px;
    overflow: hidden;
}
.pages_wrap_content.active {
    height: auto;
    opacity: 1;
    overflow: auto;
    transition: height 0.3s ease 0s, opacity 0.4s ease 0.3s;
}
.one_item_content_page {
    display: flex;

    padding: 5px 0px;
    margin-left: 30px;
    align-items: center;
    text-decoration: none;
    color: black;

}
.one_item_content_page svg {
    max-width: 20px;
}

