.smartbanner {
    z-index: -999;
    position: fixed;
    opacity: 0;
    height: 100px;
}

header {
    & .smartbanner {
        position: relative;
        background-color: #fff;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        z-index: 999;
        opacity: 1;
    }

    & .smartbanner {
        display: flex;
        align-items: center;
        padding: 16px 16px 20px 12px;
        height: auto;

        &.smartbanner {
            &--android {
                box-shadow: none;

                .smartbanner {
                    &__button {
                        position: static;
                        padding: 6px 12px 8px;
                        background-color: #000;
                        color: #fff;
                        transform: translateY(0%);
                        flex-shrink: 0;
                        margin-top: 2px;

                        &__label {
                            line-height: 1.3;
                            font-weight: 500;
                            font-size: 12px;
                            padding: 0;
                        }
                    }

                    &__exit {
                        position: static;
                        transform: translateY(0);
                        background-color: #fff0;
                        border-radius: 0;
                        width: auto;
                        height: auto;
                        padding: 3px;
                        box-shadow: none;

                        &::after,
                        &::before {
                            content: none;
                        }

                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        &__icon {
            width: 60px;
            height: 60px;
            position: static;
            flex-shrink: 0;
            background-position: center;

            &:not(:last-child) {
                margin-right: 12px;
            }
        }

        &__button {
            position: static;
            padding: 6px 12px 8px;
            background-color: #000;
            color: #fff;
            transform: translateY(0%);
            margin-top: 2px;
            flex-shrink: 0;
            text-decoration: none;

            &__label {
                line-height: 1.3;
                font-weight: 500;
                font-size: 12px;
                padding: 0;
            }
        }

        &__exit {
            position: static;
            transform: translateY(0);
            background-color: #fff0;
            border-radius: 0;
            width: auto;
            height: auto;
            padding: 3px;
            box-shadow: none;

            &:not(:last-child) {
                margin-right: 8px;
            }

            &::after,
            &::before {
                content: none;
            }
        }

        &__info {
            position: static;
            flex-grow: 1;

            &__title {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                line-height: 1.3;

                &-text {
                    line-height: 1.3;
                    font-weight: 500;
                    letter-spacing: 0.04em;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }

                &-star {
                    opacity: .5;

                    &:not(:last-child) {
                        margin-right: 4px;
                    }
                }

                &-rating {
                    opacity: .5;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.04em;
                }
            }

            &__details {
                font-size: 10px;
                line-height: 1.3;
                letter-spacing: 0.04em;
                opacity: .5;
            }
        }
    }
}