.view_controller {
    display: flex;

}

.style_view {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.style_item {
    width: 20px;
    height: 8px;
    margin-top: 4px;
    background: #1A1A1A;
    opacity: 0.3;
}
.style_view.active .style_item {
    opacity: 1;
    transition: 0.4s ease 0s;
}

.style_item:first-child {
    margin-top: 0px;
}



.default_view {
    height: 20px;
    width: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
}
.coub_view {
    width: 8px;
    height: 8px;
    background: #1A1A1A;
    opacity: 0.3;
}
.default_view.active .coub_view {
    opacity: 1;
}

.coub_view:nth-child(3) {
    margin-top: 4px;
}
.coub_view:nth-child(4) {
    margin-top: 4px;
}


.wrapper_control_view_button {
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
}
.wrapper_control_view_button:hover {
    background: #0b0b0b1a;
    transition: background 0.4s ease 0s;
}


.small_view_btn {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.small_view_item {
    width: 5px;
    height: 5px;
    background: #1A1A1A;
    opacity: 0.3;
}

.wrapper_control_view_button.active .small_view_item {
    opacity: 1;
}


.small_center_controller {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5px;
    margin-top: 2.5px;
}

@media (max-width: 768px) {
    .wrapper_control_view_button {
        padding: 5px;
    }
    .coub_view {
        width: 20px;
        height: 20px;
    }
    .small_view_btn {
        align-items: center;
    }
    .small_view_item {
        width: 8px;
        height: 8px;
    }
}


