.pagination {
    display: flex;
    justify-content: space-between;
    min-width: 400px;
    margin-top: 35px;
}

.pagination__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    width: 100vw;
    height: 100vw;
    /*max-width: 30px;*/
    /*max-height: 30px;*/
    max-width: 20px;
    max-height: 20px;
    list-style: none;
    border-radius: 50%;
    cursor: pointer;
    margin: 0 5px;
}
.pagination__btn.pagination-prev, .pagination__btn.pagination-next {
    max-width: 70px;
}

.pagination__btn span{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #1A1A1A;
}


/*.pagination__btn.current {*/
/*    background-color: #000000;*/
/*    color: #fff;*/
/*}*/
.pagination__btn.current {
    color: #000000;
}
.non_click_pagination {
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
}

@media (max-width: 768px) {
    .pagination {
        min-width: 100px;
    }
    .pagination__btn.pagination-prev, .pagination__btn.pagination-next {
        max-width: 20px;
    }
}