.page_look_detail {
    width: 100%;
    height: 100%;
}
.wrap_details_content {

}
.one_look_details {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.name_look {
    font-weight: 600;
    font-size: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1A1A1A;
    margin-top: 60px;
}
.description_look {
    white-space: pre-line;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1A1A1A;
    max-width: 764px;
    margin-top: 20px;
}
.things_image {
    max-width: 1162px;
    width: 100%;
    margin-top: 60px;
}
.thing_image_horizontal {
    position: relative;
}
.thing_image_horizontal svg {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.thing_image_horizontal svg:hover {
    border: 2px solid black;
    border-radius: 50%;
    transition: border 0.4s ease 0s;
}
.to_product_page {
    text-decoration: none;
}
.thing_image_horizontal img{
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thing_image_double {
    display: flex;
    width: 100%;
}
.one_block_photo {
    position: relative;
    width: 50%;
}
.one_block_photo svg {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.one_block_photo svg:hover {
    border: 2px solid black;
    border-radius: 50%;
    transition: border 0.4s ease 0s;
}
.one_block_photo img{
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
}



@media (max-width: 1440px) {
    .one_look_details {

    }

}

@media (max-width: 1024px) {
    .page_look_detail {
        height: auto;
    }
    .one_look_details {
        padding: 0px 20px;
    }
    .name_look {
        margin-top: 20px;
        font-size: 24px;
    }
    .description_look {
        margin-top: 18px;
        font-size: 14px;
        line-height: 18px;
    }
    .thing_image_horizontal svg {
        width: 24px;
        height: 24px;
    }
    .one_block_photo svg {
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 768px) {
    .name_look {
        margin-top: 0px;
        font-size: 18px;
    }
    .description_look {
        margin-top: 10px;
        font-size: 12px;
        line-height: 16px;
    }
    .things_image {
        margin-top: 20px;
    }
    .thing_image_horizontal svg {
        top: 10px;
        right: 10px;
    }
    .one_block_photo svg {
        top: 10px;
        right: 10px;
    }
    .thing_image_double {
        flex-direction: column;
    }
    .one_block_photo {
        width: 100%;
    }
}