
.sub_Form_button {
    width: 100%;
    background: #000000;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    transition: transform 0.2s ease 0s;
}
.sub_Form_button.unactive {
    background: #757575;
    transition: background 0.4s ease 0s;
    user-select: none;
}
.sub_Form_button:hover {
    transform: scale(1.03);
    transition: transform 0.2s ease 0s;
}
.sub_Form_button.unactive:hover {
    transform: scale(1);
    transition: transform 0.2s ease 0s;
}

@media (max-width: 768px) {
    .sub_Form_button {
        margin-top: 15px;
    }
}
@media (max-width: 428px) {
    .sub_Form_button {
        margin-top: 0px;
        max-width: unset;
        min-height: 44px;
    }
}