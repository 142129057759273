
.delivery_methods_order_container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 40px;
}
.one_delivery_method_table {
    display: flex;
    /* width: 240px; */
    max-width: 230px;
    width: 100%;
    /*justify-content: center;*/
    /*align-items: center;*/
    flex-direction: column;
    padding: 10px;
    /* height: 120px; */
    min-height: 142px;
    /* height: 100%; */
    border: 2px solid #E0E0E0;
    margin: 0px 20px 20px 0px;
    /* margin-left: 20px; */
    cursor: pointer;
}
/*.one_delivery_method_table:first-child {*/
/*    margin-left: 0px;*/
/*}*/

.one_delivery_method_table:hover {
    border-color: #1A1A1A;
    transition: border-color 0.4s ease 0s;
}

.one_delivery_method_table.active {
    border-color: #1A1A1A;
    transition: border-color 0.4s ease 0s;
}
.one_delivery_title {
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}
.delivery_info_wrapper {
    display: flex;
    height: 100%;
}
.active_delivery_info_text {
    display: flex;
    flex-direction: column;
    /*margin-top: 60px;*/
    height: 100%;
    justify-content: space-between;
}
.active_delivery_first_text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin-top: 10px;
}
.active_delivery_price_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    margin-top: 10px;
    display: flex;
}

.delivery_error_validator {
    display: flex;
    height: 30px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #CE3F3F;
}

.sdec_active_pvz_info {
    font-weight: 400;
    margin-top: 10px;
    font-size: 14px;
}

@media (max-width: 1024px) {
    .delivery_methods_order_container {
        margin-left: 30px;
    }
}
@media (max-width: 768px) {
    .delivery_methods_order_container {
        margin-left: 0px;
        margin-top: 10px;
    }
    .one_delivery_method_table {
        margin: 10px 0px 0px 0px;
    }
    .delivery_error_validator {
        height: auto;
    }
}
@media (max-width: 428px) {


}