.basket_page_right_order {
    /*min-height: 482px;*/
    /*max-width: 528px;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: margin-top 0s ease 0s;
    position: sticky;
    top: 120px;
    height: max-content;
    /*margin-top: 40px;*/
    border: 2px solid black;
}

.basket_page_heading_right_container {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 22px;
}

.change_basket_go_prev_step {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    order: 1;
    cursor: pointer;
    position: relative;
text-decoration: underline;
opacity: 0.5;
}
.bonus_check {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /*text-align: center;*/
    display: flex;
    align-items: center;
}

.bonus_check span{
    margin-left: 15px;
    text-align: left;
}
.change_basket_go_prev_step:after {
    position: absolute;
    display: flex;
    width: 100%;
    height: 1px;
    content: "";
    background-color: transparent;
    bottom: -3px;
    left: 0px;
}

.change_basket_go_prev_step:hover:after {
    background-color: #757575;
    transition: background-color 0.4s ease 0s;
}
.basket_floating_step {
    padding: 40px 22px;
    border-top: 2px solid black;
}

.basket_page_product_counter_line {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.basket_page_all_order_price {
    margin-top: 31px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    align-items: center;
}

.basket_page_price_string {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
}
.basket_page_result_price {
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: #757575;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.basket_page_result_price_grey {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.04em;
    color: #000000;
}
.basket_floating_next_step {
    padding: 40px 22px;
    border-top: 2px solid black;
}
.basket_page_go_order_page {
    display: flex;
    width: 100%;
    height: 50px;
    background: #1A1A1A;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}

.basket_page_go_order_page.disable {
  background-color: gray;
    pointer-events: none;
    user-select: none;
}
.basket_page_order_atention_text {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    max-width: 450px;
    margin: 0 auto;
    margin-top: 18px;
}
.basket_page_order_atention_text a {
    text-decoration: none;
    font-weight: 700;
    color: black;
    opacity: 0.5;
}
.basket_page_order_atention_text span {
    opacity: 0.5;
}
.basket_page_order_atention_text span.gray {
    font-weight: 700;
}
.basket_page_order_atention_text a:active {
    color: black;
}
.basket_page_order_atention_text a:hover {
    opacity: 1;
}
.basket_page_all_order_delivery_top_line {
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
}
.basket_page_all_order_label_name {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
}
.basket_page_all_order_delivery_bottom_line {
    display: flex;
    width: 100%;
    height: 15px;
    margin-top: 6px;
}
.negative_price_row {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
    opacity: 0.51;
    margin-left: auto;
}
.avoid_call {
    margin-top: 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /*text-align: center;*/
    color: #C7C7C7;
    display: flex;
    align-items: center;
}
.avoid_call span{
    color: black;
    margin-left: 15px;
    text-align: left;
}

@media (max-width: 1024px) {
    .basket_page_heading_right_container {
        font-size: 24px;
        line-height: 31px;
    }
    .basket_page_result_price {
        letter-spacing: 0.04em;
        line-height: 39px;
    }
    .basket_page_order_atention_text {
        max-width: 100%;
    }
}
@media (max-width: 768px) {
    .basket_page_heading_right_container {
        padding: 20px;
        font-size: 18px;
        line-height: 23px;
    }
    .change_basket_go_prev_step {
        font-size: 12px;
        line-height: 16px;
    }
    .basket_floating_step {
        padding: 20px;
    }
    .basket_page_product_counter_line {
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_all_order_price {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_price_string {
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_all_order_delivery_top_line {
        margin-top: 20px;
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_result_price {
        font-size: 16px;
        line-height: 21px;
    }
    .basket_page_result_price_grey {
        font-size: 16px;
        line-height: 21px;
    }
}



@media (max-width: 768px) {
    .basket_floating_next_step {
        padding: 20px;
    }
}




