.user_page {
    width: 100%;
    height: 100%;
    position: relative;
}

.wrapper_center_page {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 40px;
    display: flex;
}
.container_background_relative {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.not_authorized_container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: max-content;
    cursor: pointer;
}
.background_exit {
    position: absolute;
    opacity: 0.5;
    width: 100%;
    object-fit: cover;
    height: 100%;
    z-index: -1;
}
.entrance_text_usercabinet {
    max-width: 164px;
    width: 100%;
    height: 49px;
    padding: 14px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    border: 1px solid black;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}
.entrance_text_usercabinet:hover {
    background: white;
    color: #000000;
    transition: color 0.4s ease 0s, background 0.4s ease 0s;
}
/*.entrance_user_block {*/
/*    width: 25px;*/
/*    height: 24px;*/
/*    cursor: pointer;*/
/*}*/
/*.entrance_user_block svg {*/
/*    cursor: pointer;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*}*/
.not_authorized_button {
    padding: 21px 64px;
    border: 1px solid transparent;
    background: black;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    margin-top: 40px;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}
.not_authorized_button:hover {
    border: 1px solid black;
    background-color: white;
    color: black;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}



.wrapper_favorite_product{
    width: 100%;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.favorite_product_child {

}
.title_favorite {
    /*width: 100%;*/
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    display: flex;
    padding: 25px 45px;
    /*box-shadow: 1px 0 0 0 #000, 0 1px 0 0 #000, 1px 1px 0 0 #000, 1px 0 0 0 #000 inset, 0 1px 0 0 #000 inset;*/
}

.products_wrapper.standart.favorite {
    margin-top: 0px;
}

.main.user_page {
    display: flex;
    flex-direction: column;
}
.user_personal_content {
    /*margin-left: 186px;*/
    width: 100%;

}
.user_heading {
    font-weight: bold;
    font-size: 36px;
    color: #1A1A1A;
}
.get_sale_user {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    display: flex;
    margin-top: 40px;
}

.form_user_info_wrapper {
    max-width: 520px;
    width: 100%;
}

.user_field {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    border: 1px solid #D7D6D6;
    margin-top: 7px;
    font-size: 17px;
    letter-spacing: 0.03em;
    font-weight: 500;
    padding: 5px;
}

.user_field:focus-visible {
    outline: none;
}

.user_field::placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #757575;
}

.gender_select {
    /*height: 40px;*/
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: space-between;*/

}
.title_gender_lk {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.contain_variants_gender {
    cursor: pointer;
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
}
.gender_select_elements {
    display: flex;
    margin-right: 24px;
    margin-top: 5px;
}
.gender_select_elements:last-child {
    margin-right: 0px;
}
.circle_select {
    width: 20px;
    height: 20px;
    border: 1px solid #000000;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circle_black {
    background: #1A1A1A;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    opacity: 0;
    trasition: opacity 0.4s ease 0s;
}
.circle_select.active .circle_black {
    opacity: 1;
    transition: opacity 0.4s ease 0s;
}

.gender_name {
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    color: #1A1A1A;
}
.user_phonenumber.user_field {
    /*border-bottom: unset;*/
    pointer-events: none;
    margin-top: 0px;
    /*margin-left: 21px;*/
    height: 44px;
    border: 1px solid #D7D6D6;
    padding: 5px;
}
.user_phonenumber.user_field {
    color: #000000;
    opacity: 0.5;
    font-weight: 400;
    font-size: 16px;
}

.user_phone_wrapper {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    user-select: none;
    margin-top: 40px;
    flex-direction: column;
}
.title_name_fields{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.title_name_fields.non_active {
    color: #757575;
    transition: color 0.4s ease 0s;
}
.phone_mask_and_flag {
    display: flex;
    align-items: center;
    margin-top: 7px;
}

.change_number_parents {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
.change_number {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    cursor: pointer;
    position: relative;
}
.change_number:after {
    position: absolute;
    content: "";
    height: 1px;
    bottom: 0;
    right: 0;
    width: 100%;
    background: black;
}
.city_user_wrapper {
    margin-top: 40px;
}
.your_market_wrapper {
    margin-top: 40px;
}

.save_changes {
    /*max-width: 363px;*/
    width: 100%;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    /*text-transform: uppercase;*/
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1A1A1A;
    margin-top: 10px;
    cursor: pointer;
}
.button_exit_lk {
    margin-top: 18px;
}

.main.user_page .wrapper_center_page {
    padding-bottom: 40px;
}


.logo_fio_bar {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.user_logo_image {
    max-width: 150px;
    min-height: 150px;
    margin-right:40px;
}
.contain_fio_and_bonus {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 10px;
}
.fio_container_controll_bar {
    /*position: absolute;*/
    /*right: 0px;*/
    /*bottom: -10px;*/
    /*height: 200px;*/
    display: flex;
    /*flex-direction: column;*/
    /*align-items: flex-end;*/
    /*justify-content: flex-end;*/
}

.user_fio_item {
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    /*text-align: right;*/
    letter-spacing: 0.04em;
    color: #1A1A1A;
    /*margin-top: 5px;*/
    margin-right: 15px;
}

.bon_cart_info {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    opacity: 0.3;
    margin-top: 10px;
}
.favorite_error {
    padding: 10px;
    color: #CE3F3F;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    width: 100%;
    display: flex;
}
@media (max-width: 1024px) {
    .user_personal_content {
        margin-left: 20px;
    }
    .user_page {
        height: auto;
    }
    /*.main.user_page {*/
    /*    height: auto;*/
    /*}*/
}
@media (max-width: 768px) {
    .wrapper_center_page {
        padding: 10px 20px;
    }
    .wrapper_favorite_product {
        margin-left: 0px;
    }
    .user_fio_item {
        font-size: 24px;
        line-height: 31px;
    }
    .get_sale_user {
        font-size: 14px;
        line-height: 18px;
    }
    .gender_select_elements {
        margin-right: 15px;
    }
    .change_number_parents {
        margin-top: 10px;
    }
    .change_number {
        font-size: 12px;
        line-height: 16px;
    }
    .form_user_info_wrapper {
        max-width: 100%;
    }

    .container_background_relative {
        flex-direction: column;
        align-items: center;
    }


    .wrapper_center_page {
        justify-content: center;
    }
    .entrance_text_usercabinet {
        max-width: 100%;
    }

    .products_wrapper.standart.favorite {
        margin-top: 10px;
        padding: 0px;
    }
    .title_favorite {
        box-shadow: unset;
        padding: 0px 10px;
        font-size: 18px;
        line-height: 23px;
    }
    .not_authorized_container {
        margin-top: 40px;
        width: 100%;
    }

}
