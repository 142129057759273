.main.admin_dashboard {
    display: flex;
    flex-direction: column;
}
.header_admin_top_area {
    display: flex;
    width: 100%;
    padding: 5px 10px;
    height: 100px;
    border:1px solid #e1e3e5;
    align-items: center;
    justify-content: space-between;
    box-shadow: 1px 10px 8px -8px rgba(34, 60, 80, 0.07);

}
.go_site_back {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.06em;
    text-decoration: none;
    color: black;
    padding: 5px 15px;
    display: flex;
    border-radius: 5px;

    align-items: center;
    justify-content: center;
}
.go_site_back:hover {
    background-color: #9993;
    transition: background-color 0.4s ease 0s;
}
.go_site_back a {
    text-decoration: none;
    color: black;
}
.user_container_admin {
    display: flex;
    align-items: center;
    padding: 0px 15px;
}
.adm_panel_user_info {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.name_user_admin {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.06em;
    text-decoration: none;
    color: black;
    padding: 0px 10px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    user-select: none;


}

.circle_sumb_user {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0c0c0c5e;
    border-radius: 50%;
    color: white;
    font-weight: 600;
    letter-spacing: 0.03em;

}


.Work_Admin_Space {
    height: 100%;
    background-color: #f6f6f7;
    display: flex;
    width: 100%;
}


.routeWorkSpace {
    width: 100%;
}

.reboot_btn {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 11px;
    border: 1px solid black;
    cursor: pointer;
    margin-left: 20px;
}

.reboot_btn:hover {
    background-color: black;
    color: white;
}
