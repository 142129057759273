.wrapper_page_shop {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}
.workspace_area_shop {
    height: 100%;
    width: 100%;
    padding: 18px;
    display: flex;
    flex-direction: column;
}
.adm_shop_title {
    display: flex;
    width: 100%;
    height: 76px;
    padding: 15px 20px;
    margin-top: 20px;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.03em;
}
.content_manager_shop {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}
.left_wrapper_shop {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.left_container_shop {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}
.shop_field_label_name {
    font-weight: 600;
    font-size: 12px;
}
.shop_field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9cccf;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 5px;
    border-radius: 5px;
    margin-top: 5px;
}
.shop_field.textarea {
    resize: none;
    height: 100px;
    margin-right: 10px;
}
.save_button_shop_content {
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    height: 42px;
    background-color: #fff;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    border: 1px solid black;
}
.save_button_shop_content:hover {
    color: white;
    background-color: black;
    transition: color 0.8s ease 0s, background-color 0.8s ease 0s;
}


.save_button_shop_content.trig {
    animation: trigerSaveShop 2s ease infinite;
}

@keyframes trigerSaveShop{
    0% {
        background: white;
        color: black;
    }
    50% {
        background: black;
        color: white;
    }
    100% {
        background: white;
        color: black;
    }

}



.shop_container_buttons {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.preview_img_shop {
    width: 100%;
    display: flex;
    justify-content: center;
}
.preview_img_shop .preview_container_img {
    max-width: 300px;
}

.add_new_shop_btn {
    display: flex;
    padding: 5px 10px;
    border: 1px solid black;
    font-size: 14px;
    background: white;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    max-width: 200px;
    height: 42px;
    align-items: center;
    margin-right: 20px;
    margin-left: 10px;
}
.add_new_shop_btn:hover {
    color: white;
    background: black;
}
.shop_wrapper_content {
    display: flex;
    flex-wrap: wrap;
}
.container_cards_shop {
    background-color: #fff;
    box-shadow: 0 0 5px rgb(23 24 24 / 5%), 0 1px 2px rgb(0 0 0 / 15%);
    border-radius: 10px;
    height: auto;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
}
.shop_city_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.wrap_create_shop {
    width: 100%;
}
.right_container_city {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    width: 100%;
}
.add_city_shop {
    width: 100%;
}
.error_text {
    font-weight: 500;
    color: #CE3F3F;
    font-size: 14px;
    height: 30px;
}