.search_page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.search_product_list {
    max-width: 1920px;
    width: 100%;
    padding: 0px 40px 40px 40px;
}


.non_result_search {
    font-size: 14px;
    color: #CE3F3F;
    font-weight: 500;
}

.download_more_products {
    display: flex;
    max-width: 182px;
    width: 100%;
    min-height: 50px;
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
    color: #1A1A1A;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #1A1A1A;
    text-decoration: none;
}
.download_more_products:hover {
    background-color: black;
    color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}


@media (max-width: 768px) {
    .search_product_list {
        padding: 0px;
    }
}
