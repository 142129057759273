@import url(../fonts/Montserrat/stylesheet.css);
@import url(../fonts/IBMPlexMono/stylesheet.css);
@import url(../fonts/DrukCyr/stylesheet.css);


img{
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.smartbanner {
    z-index: 999;
    position: fixed;
}

button,
input,
optgroup,
select,
textarea {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    color: inherit;
    letter-spacing: inherit;
    outline: 0;
    outline-offset: 0;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'IBM Plex Mono';
    /*font-family: 'Montserrat';*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}


p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    font-weight: 400;
}

span.goNewLine {
    display: block;
}

html {
    /*height: 100%;*/
    min-height: 100vh;
}
body {
    height: 100%;
    margin: 0 auto;
    min-height: 100vh;
}

#root {
    display: flex;
    justify-content: center;
    width: 100%;
    /*height: 100%;*/
    min-height: 100vh;
}
.App {
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

div.main {
    /*flex: 1 0 auto;*/
    display: flex;
    height: 100%;
}


.captcha {
    max-width: 477px;
    margin-top: 10px;
}
.digi-dolyame-scheme__details {
    display: none;
}
jdiv {
    z-index: 8!important;
}
/*
iframe:not([allowpaymentrequest]) {
   display: none;
}
*/

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 768px){
    select:focus, textarea:focus, input:focus {
        font-size: 16px;
    }
}
