.basket_page {
    /* display: flex; */
    margin-top: 52px;
    height: 100%;
    flex-direction: column;
    width: 100%;
    padding-bottom: 40px;
    position: relative;
}
.container_basket_page{
    display: flex;
    gap: 40px;  
      padding: 0px 40px;
width: 100%;
}

.content_basket_page_wrapper {
    display: flex;
    /* flex-flow: column wrap; */
    /*height: 100%;*/
    width: 100%;

    /*margin-top: 52px;*/
}
.basket_page_main_block{
     border: 2px solid black;
}
.basket_one_step {
    /*display: flex;*/
    margin: 0 auto;
   
    max-width: 1600px;
    width: 100%;
}
.basket_modal{
    top: 0;
    left: -15px;
    right: 0;
    bottom: 0;
position: fixed;
z-index: 99;
 background: rgba(0, 0, 0, 0.3);
transform: translateX(100%);
transition: transform 0.3s;
}
.basket_modal.active{
    transform: translateX(0%);
}
.basket_modal_container{
    width: 559px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 40px;
    right: 40px;bottom: 40px;
    border: 1.5px solid rgb(0, 0, 0);
    color: #000;
}
.basket_modal_header{
    border-bottom:  1.5px solid rgb(0, 0, 0); 
    height: 62px;
    display: flex;
    align-items: center;
}
.basket_modal_header_close{
    display: inline-block;
    margin-left:  auto ;
    margin-right: 24px;

}
.basket_modal_body{
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.basket_modal_body span{
display: inline-block;
margin-bottom: 56px;
font-size: 24px;
font-weight: 500;
line-height: 31px;
}

.basket_modal_body button{
    margin-top: 8px;
}
.basket_modal_btn{
    width: 361px;
height: 49px;
}
.basket_modal_btn_black{
    background-color: #000;
    color: #fff;
}
.basket_modal_btn_white{
    border: 1px solid #000;
}
.basket_one_step_disabled {
    margin: 40px auto;
    border: 2px solid black;
    max-width: 1600px;
    width: 100%;
}
.delate_wrapper {
    margin-top: auto;
    margin-left: auto;
}
.wrapper_container_limiter {
    max-width: 1860px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.basket_wrapper_for_items {
    max-width: 1233px;
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #000000;
    /*box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);*/
    display: flex;
    flex-direction: column;
    /*padding: 38px 40px;*/
}
.heading_basket_page {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #1A1A1A;
    padding: 18px 40px;
    display: flex;
    position: relative;
    align-items: center;
}

.basket_kontroll_step_buttons {
    height: 36px;
    display: flex;
    width: 36px;
    min-width: 36px;
    position: relative;
    cursor: pointer;
}

.basket_title_text {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
}
.basket_kontroll_step_buttons.left {
    display: flex;
}
.basket_kontroll_step_buttons.left:after {
    position: absolute;
    top: 5px;
    left: 16px;
    width: 2px;
    height: 15px;
    background-color: black;
    content: "";
    transform: rotate(45deg);
}
.basket_kontroll_step_buttons.left:before {
    position: absolute;
    top: 15px;
    left: 16px;
    width: 2px;
    height: 15px;
    background-color: black;
    content: "";
    transform: rotate(-45deg);
}
.basket_kontroll_step_buttons.right {
    display: flex;
    margin-left: auto;
}
.basket_kontroll_step_buttons.right:after {
    position: absolute;
    top: 5px;
    left: 16px;
    width: 2px;
    height: 15px;
    background-color: black;
    content: "";
    transform: rotate(-45deg);
}
.basket_kontroll_step_buttons.right:before {
    position: absolute;
    top: 15px;
    left: 16px;
    width: 2px;
    height: 15px;
    background-color: black;
    content: "";
    transform: rotate(45deg);
}

.basket_kontroll_step_buttons:hover {
    background-color: black;
}
.basket_kontroll_step_buttons:hover:after {
    background-color: white;
}
.basket_kontroll_step_buttons:hover:before {
    background-color: white;
}

.basket_count_items_and_clear_line {
    display: flex;
    width: 100%;
    padding: 22px 40px;
}
.basket_page_counter {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-right: auto;
}
.basket_page_count_number {
    margin-left: 22px;
    display: flex;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.basket_page_clear_basket_wrapper{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
    margin-right: 40px;
}
.basket_page_clear_basket_wrapper svg{
    stroke: #000;
    opacity: 0.5;
}
.basket_page_clear_basket {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #000000;
    opacity: 0.5;
    display: flex;
    position: relative;
    width: auto;
    cursor: pointer;
   

}
.basket_page_clear_basket:hover {
    opacity: 1;
}
.basket_page_clear_basket svg{
    stroke:"#1A1A1A";
}
.basket_page_clear_basket:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: 1px;
    width: 100%;
    opacity: 0.5;
    background-color: #000000;
}

.basket_page_checkbox{
    cursor: pointer;
    display: flex;
    gap: 11px;
    position: relative;
}
.basket_page_checkbox_center{
    align-items: center;
}
.basket_page_checkbox input{
    width: 1px;
    height: 1px;
    opacity: 1;
    position: absolute;
    display: none;
}
.basket_page_checkbox input:checked + .basket_page_fake_checkbox::after{
    position: absolute;
    width: 16px;
    height: 16px;
    background: url(../../../public/files/images/check_box.svg);
    top: -2px;
    left: -2px;
    content: '';

}
.basket_page_fake_checkbox{
    width: 18px;
    height: 18px;
    border: 2px solid #000;
    position: relative;
    border-radius: 2px;

}

.basket_page_slider_block{
    border: 1.5px solid #000;
}
 


.basket_page_slider_header{
    height: 64px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1.5px solid #000;
   
}
.basket_page_slider_buttons{

    display: flex;
    gap:32px;
    align-items: center;
}
.basket_page_slider_buttons button{
    cursor: pointer;
}

.basket_page_slider_body{
    display: flex;
}
.basket_page_slide + .basket_page_slide{
    border-left: 1.5px solid #000;
}
.basket_page_slide{
    flex: 0 0 283px;
    border-left: 0.75px solid #000;
    border-right: 0.75px solid #000;
    position: relative;
}
.basket_page_slider_body .swiper-slide{
    display: flex;
    width: 283px !important;
}

.basket_page_slider_like{
    position: absolute;
    top: 26px;
    right: 22px;
}
.basket_page_slider_image{
    height: 423px;
}
.basket_page_slider_image img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 420px;
}
.basket_page_slider_content{
    border-top: 1.5px solid #000;
    padding: 6px 10px 18px 10px;

    font-size: 14px;
/* font-weight: 400; */
line-height: 18px;
}
.basket_page_slider_name{
    max-width: 220px;
    display: inline-block;
    text-decoration: none;
    color: #000;
}

.basket_page_slider_row{
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}

.basket_page_slider_colors{
    display: flex;
    gap: 10px;
}
.basket_page_slider_color{
    display: inline-block;
    width: 10px;
    height: 10px;

}
.basket_page_slider_color.green{
    background: #33F552;
}
.basket_page_slider_color.blue{
    background: #0017E2;
}
.basket_page_slider_color.black{
    background: #000;
}
.basket_page_for_product_list {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.basket_page_one_item_container {
    display: flex;
    min-height: 224px;
    width: 100%;
    /*margin-top: 40px;*/
    /*padding-top: 40px;*/
    padding: 16px 24px 16px 16px;
    border-top: 2px solid #000000;

}
.basket_page_image_preview {
    /*height: 184px;*/
    /*min-height: 334px;*/
    /*width: 138px;*/
    max-width: 223px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-left: 11px;
    position: relative;
}

.basket_page_image_like{
    position: absolute;
    top: 10px;
    right: 10px;
}
.basket_page_image_preview img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /*object-fit: contain;*/
}
.checkout_basket_page_wrapper{
    border: 1.5px solid rgb(0, 0, 0);
    max-width: 468px;
}

.checkout_basket_page_header{
    font-size: 18px;
font-weight: 500;
line-height: 23px;
    border-bottom: 1.5px solid rgb(0, 0, 0);
    padding: 20.5px 24px;
}
.checkout_basket_page_body{
padding: 20.5px 24px 24px 24px;
}

.checkout_basket_page_bonus{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 16px;
line-height: 21px;
 color: #757575;
}

.checkout_basket_page_login{
    font-size: 16px;
font-weight: 500;
line-height: 21px;
margin-bottom: 32px;
}
.checkout_basket_page_login a{
    color: #000;
}

.checkout_basket_page_bonus_block{
    display: flex;
    align-items: center;
    gap: 12px;
   
}
.checkout_basket_page_bonuses{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.checkout_basket_page_checkbox{
    position: relative;
}
.checkout_basket_page_checkbox input{
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
}

.basket_btn_disable{
    background: rgb(0, 0, 0);
opacity: 0.5;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
font-size: 16px;
font-weight: 500;

flex: 1;
max-width: 100%;
width: 420px;
line-height: 21px;
margin: 24px;
}
.basket_page_one_item_content_section {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 40px;
}
.basket_page_content_text_information {
    max-width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.disabled_red {
    color: #CE3F3F;
}
.basket_page_one_disabled_item_container {
    display: flex;
    flex-direction: column;
    min-height: 224px;
    width: 100%;
    /*margin-top: 40px;*/
    /*padding-top: 40px;*/
    padding: 18px 40px;
    border-top: 2px solid #000000;

}
.basket_page_content_text_information_disabled {
    max-width: 330px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.basket_page_disabled_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #CE3F3F;
    margin-top: 18px;
}
.basket_page_disable_text_container {
    display: flex;
    justify-content: start;
    flex-flow: column;
    width: 100%;
}
.basket_page_disable_content_container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}
.basket_page_notification_button {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    max-width: 483px;
}
.basket_page_disabled_name_container {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    /*min-height: 44px;*/
    display: flex;
}
.basket_page_disabled_info_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #CE3F3F;
    margin-top: 18px;
}
.basket_page_color_container {
    display: flex;
}
.basket_page_count_controller_and_price_wrapper {
    display: flex;
    padding-left: 20px;
    /*max-width: 534px;*/
    /*width: 100%;*/
    justify-content: flex-end;
}
.basket_page_price_string.old_price {
    margin-top: 6px;
    margin-right: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: line-through;
    color: #D7D7D7;
}
.basket_page_content_line_size.counter {
    display: flex;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    align-items: center;
    margin-top: 18px;
}
.count_one_product_controller_basket_page {
    display: flex;
    /*height: 38px;*/
    height: 25px;
    /*width: 137px;*/
    width: 91px;
    margin-left: 20px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    border-radius: 2px;
}
.count_one_item_controller_basket_page {
    display: flex;
    height: 100%;
    width: 33.33%;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color 0.4s ease 0s;
    position: relative;
}
.count_one_item_controller_basket_page.min {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.count_one_item_controller_basket_page.max {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.count_one_item_controller_basket_page:hover {
    background-color: black;
}
.count_one_item_controller_basket_page.count {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    user-select: none;
}
.count_one_item_controller_basket_page.count:hover {
    background-color: white;
}


.count_one_item_controller_basket_page.min:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 7px;
    background-color: #8d8d8d;
    right: 10px;
    top: 6px;
    border-radius: 2px;
    transform: rotate(45deg);
}
.count_one_item_controller_basket_page.min:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 7px;
    background-color: #8d8d8d;
    right: 10px;
    top: 11px;
    border-radius: 2px;
    transform: rotate(-45deg);
}
.count_one_item_controller_basket_page.max:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 7px;
    background-color: #8d8d8d;
    left: 10px;
    top: 6px;
    border-radius: 2px;
    transform: rotate(135deg);
}
.count_one_item_controller_basket_page.max:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 7px;
    background-color: #8d8d8d;
    left: 10px;
    top: 11px;
    border-radius: 2px;
    transform: rotate(-135deg);
}
.count_one_item_controller_basket_page:hover:after {
    background-color: white;
    transition: background-color 0.4s ease 0s;

}
.count_one_item_controller_basket_page:hover:before {
    background-color: white;
    transition: background-color 0.4s ease 0s;

}
.basket_page_name_container {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    /*min-height: 44px;*/
    display: flex;
}
.basket_page_articul_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin-top: 18px;
}
.basket_page_color_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 18px;
}
.basket_page_color_props {
    display: flex;
    margin-left: 40px;
}
.basket_page_size_container {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 18px;
    display: flex;
}
.basket_page_size_props {
    display: flex;
    margin-left: 25px;
}
.basket_page_price_and_delete_wrapper {
    display: flex;
    /*height: 100%;*/
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}
.basket_page_price_wrapper {
    display: flex;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #1A1A1A;
    align-items: flex-start;
    white-space: nowrap;
}
.basket_page_trash_container {
    /*width: 22px;*/
    /*height: 22px;*/
}
.wrapper_trash_basket {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    position: relative;
}
.wrapper_trash_basket:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -1px;
    height: 1px;
    width: 100%;
    background-color: #000000;
}
.basket_page_trash_container:hover {
    color: #000000;
    opacity: 0.5;
    transition: color 0.4s ease 0s;
}
.basket_controll_third_step_wrapper {
    padding: 0px 60px 40px 40px;
}
.basket_controll_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.basket_controll_wrapper.two_step {
    max-width: 501px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
}
.basket_controll_next_step {
    max-width: 501px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    background: #1A1A1A;
    color: white;
    padding: 12px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
}
.basket_controll_next_step.unactive {
    opacity: 0.7;
    transition: opacity 0.4s ease 0s;
    -webkit-user-select: none;
    user-select: none;
}
.basket_controll_back_step {
    max-width: 501px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    background: #ffffff;
    color: #000000;
    padding: 12px;
    text-align: center;
    margin-top: 40px;
    border: 1px solid black;
    cursor: pointer;
}
/*.basket_controll_next_step:hover {*/
/*    opacity: 1;*/
/*    transition: opacity 0.4s ease 0s;*/
/*}*/
.basket_controll_next_step.left {
    max-width: 114px;
    background: white;
    color: black;
    border: 1px solid black;
}
.basket_controll_next_step.right {
    max-width: 114px;
    background: black;
    color: white;
    border: 1px solid black;
}


.basket_boneses_control {
    margin-top: 40px;
    /*position: relative;*/
    display: flex;
    max-width: 515px;
    min-height: 482px;
    width: 100%;
    height: 100%;

    border: 1px solid black;
}

.basket_page_right_column {
    margin-left: 40px;
    /*position: relative;*/
    display: flex;
    flex-direction: column;
    max-width: 515px;
    min-height: 482px;
    width: 100%;
    height: 100%;
}
.one_step_price_wrapper {

}
.controll_indicator_wrapper {
    padding: 40px;
    border-top: 2px solid #000000;
}
.basket_and_order_controll_indicator {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /*margin-top: 72px;*/
    position: relative;
}

.indicator_one_item_blob {
    display: flex;
    width: 18px;
    min-width: 18px;
    height: 18px;
    background: #D9D9D9;
    border-radius: 50%;
    z-index: 1;
}

.indicator_one_item_blob.active {
    background: #1A1A1A;
}
.indicator_one_item_blob_wrapper {
    display: flex;
}

.emptiness_container {
    width: 100%;
    display: flex;
}
.unifying_line {
    position: absolute;
    top: 9px;
    width: 100%;
    height: 2px;
    background-color: #D9D9D9;
    z-index: 0;
}


.one_controll_indicator_item:last-child .emptiness_container.right {
    background-color: white;
    z-index: 1;
}



.one_controll_indicator_item:nth-child(2) .emptiness_container.left {
    background-color: white;
    z-index: 1;
}

.basket_and_order_container_wrappers {
    display: flex;
    gap: 40px;
    width: 100% ;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    flex: 0 0 100%;
justify-content: center;
}

.basket_and_order_column{
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 852px;
    
}

.indicator_one_item_name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-top: 5px;
}
.order_delivery_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    /*margin-top: 90px;*/
    padding: 40px 60px 40px 40px;
}
.order_delivery_heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}

.order_delivery_city_wrapper {
    display: flex;
    margin-top: 37px;
}

.order_delivery_static_field_name {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    max-width: 160px;
    width: 100%;
}
.order_delivery_static_city_name {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
    cursor: default;
    margin-left: 40px;
}

.order_delivery_change_city_button_wrapper {
    display: flex;
}

.order_delivery_change_city_button {
    display: flex;
    width: 198px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #000000;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
    margin-left: 200px;
    margin-top: 10px;
}

.order_delivery_change_city_button:hover {
    background-color: black;
    color: white;
    border-color: white;
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s, border-color 0.4s ease 0s;
}

.basket_special_fucntional_modal {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    max-width: 1760px;
    right: -260%;
    top: 0px;
    z-index: 10;
    transition: right 1s ease 0s;
    justify-content: flex-end;
    pointer-events:  none;
    padding: 40px;
}
.container_to_switch_modal_state {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 657px;
    /*min-width: 657px;*/
    background-color: #FFFFFF;
    border: 2px solid black;
    top: 0px;
    z-index: 10;
    transition: right 1s ease 0s;
    /*padding: 30px;*/
    flex-direction: column;
    pointer-events: auto;
}

.basket_special_fucntional_modal.active {
    right: 0px;
    transition: right 1s ease 0s;
}
.basket_functional_modal_for_close {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 20px 30px;
    border-bottom: 2px solid black;
}
.basket_order_fucntional_exit_div {
    width: 25px;
    height: 25px;
    display: flex;
    position: relative;
}

.basket_order_fucntional_exit_div:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 25px;
    transform: rotate(45deg);
    left: 12px;
    top: 0px;
    background-color: black;

}
.basket_order_fucntional_exit_div:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 25px;
    transform: rotate(-45deg);
    left: 12px;
    top: 0px;
    background-color: black;
}
.basket_order_fucntional_exit_div:hover {
    cursor: pointer;
}

.city_changer_function_modal_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    padding: 0px 36px;
}
.functional_modal_support_wrapper {
    display: flex;
    align-items: center;
    height: 29px;
    width: 100%;
    position: relative;
}
.functional_modal_support_wrapper .bar_icon_wrapper.fill {
    max-height: 24px;
    max-width: 24px;
    margin-right: 18px;
}
.functional_modal_support_wrapper:after {
    content: "";
    position: absolute;
    display: flex;
    width: 100%;
    height: 1px;
    background-color: #000000;
    bottom: -18px;
}

.search_city_input_fuctional_modal {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    color: #000000;
}
.search_city_input_fuctional_modal::placeholder {
    color: #000000;
}
.favorite_citys_functional_modal {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 55px;
    flex-direction: column;
}
.we_can_take_city {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.citys_map_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 24px;
}
.one_city_functional_modal {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-top: 16px;
    display: flex;
    width: max-content;
    cursor: pointer;
    position: relative;
}
.one_city_functional_modal:after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0px;
    width: 105%;
    height: 1px;
    background-color: transparent;
}
.one_city_functional_modal:hover:after {
    background-color: black;
    transition: background-color 0.4s ease 0s;

}

.alternative_city_order {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    margin: 0 auto;
    margin-top: 90px;
    font-weight: 500;
    text-align: center;
    flex-direction: column;
}
.alternative_city_order span {
    max-width: 490px;
}

.alternative_city_order.active {
    height: auto;
    overflow: auto;
    opacity: 1;
    transition: height 0s ease 0s, opacity 0.4s ease 0.2s;
}


.order_response_city_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}
.order_modal_one_city_item {
    margin-top: 3px;
    display: flex;
    width: 100%;
    min-height: 30px;
    align-items: center;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    cursor: pointer;
}

.basket_page_name_container{
    margin-bottom: 8px;
}

.order_modal_one_city_item_name {
    display: flex;
    height: 100%;
    width: max-content;
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-top: 16px;
}
.order_modal_one_city_item_name:after {
    content: "";
    position: absolute;
    bottom: 7px;
    width: 100%;
    height: 1px;
    background-color: transparent;
}

.order_modal_one_city_item:hover .order_modal_one_city_item_name:after {
    background-color: black;
    transition: background-color 0.2s ease 0s;
}

.wrapper_fon_non_find_city {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;
    height: 40px;
    align-items: center;

}

.custom_city_input {
    height: 100%;
    display: flex;
    border-bottom: 1px solid black;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.custom_city_set_new_city {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    height: 100%;
    background: #1A1A1A;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}
.delivery_response_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.delivery_response_list {
    display: flex;
}
.delivery_response_heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    max-width: 160px;
    width: 100%;
}
.delivery_response_no_delivery_info {
    display: flex;
    /*margin-top: 40px;*/
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #CE3F3F;
}
.city_error_validator {
    display: flex;
    min-height: 18px;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: #CE3F3F;
}
.map_container_sdek {
    width: 100%;
    height: 100%;
    display: flex;
}
.map_container_for_switch {
    display: flex;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    filter: grayscale(100%);
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    background: white;
}
#map {
    width: 100%;
    height: 100%;
}
.modal_state_choose_pvz {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 18px 40px;
    /*background-color: pink;*/
    min-height: 350px;
}

.modal_state_choose_pvz_tabs {
    display: none;
}

.modal_state_choose_pvz_heading {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #1A1A1A;
    text-align: center;
    display: flex;
    width: 100%;
    /*margin-top: 35px;*/
    justify-content: flex-start;
}
.modal_satate_choose_pvz_city_info {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
    margin-top: 20px;
}
.choose_office_pvz_list {
    display: flex;
    width: 100%;
    /*height: 100%;*/
    flex-direction: column;
    margin-top: 40px;
    height: auto;
    /*max-height: 700px;*/
    overflow-y: scroll;
    padding-right: 34px;
}
.choose_office_pvz_list::-webkit-scrollbar {
    background-color: #F4F4F4;
    width: 10px;
    /*border-radius: 10px;*/
}
.choose_office_pvz_list::-webkit-scrollbar-thumb {
    background-color: #000000;
    /*border-radius: 10px;*/
}

.one_office_pvz_choose {
    width: 100%;
    display: flex;
    /*border-bottom: 1px solid #CFCFCF;*/
    padding: 12px 0px;
    cursor: pointer;
    color: #000000;

}

.one_office_pvz_choose:hover {
    border-color: black;
    transition: border-color 0.4s ease 0s;
}

.one_office_pvz_right_content {
    display: flex;
    flex-grow: 1;
    height: 100%;
    flex-direction: column;
    margin-left: 16px;
    border-bottom: 2px solid black;
    padding-bottom: 24px;
}
.one_office_pvz_small_heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
}
.one_office_pvz_address {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #626262;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.pvz_work_time_heading {
    display: flex;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #626262;
    display: flex;
    align-items: center;
}
.one_office_time {
    /*max-width: 145px;*/
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #626262;
    margin-top: 5px;
}
.one_office_pvz_active_check {
    display: flex;
    width: 22px;
}
.one_office_pvz_circle {
    display: flex;
    width: 22px;
    height: 22px;
    border: 1px solid #B6B6B6;
    margin-top: 2px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
}


.active_pvz_circle {
    display: flex;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 50%;
}
.one_office_pvz_choose:hover .active_pvz_circle {
    background-color: gray;
}
.one_office_pvz_choose.active .active_pvz_circle {
    background-color: black;
}

.one_office_pvz_support_price_wrapper {
    display: flex;
    flex-direction: column;
}
.one_office_pvz_price {
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #626262;
}

.select_this_pvz {
    background: #1A1A1A;
    display: flex;
    width: 100%;
    /*height: 50px;*/
    min-height: 50px;
    margin: 0 auto;
    max-width: 520px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 32px;
}

.select_this_pvz:hover {
    border: 1px solid black;
    background-color: white;
    color: black;

}

.select_this_pvz.disactive {
    pointer-events: none;
    user-select: none;
    background-color: lightgrey;
    color: gray;
}

.basket_page_container_price_and_basket {
    display: flex;
    width: 100%;
}

.basket_non_product {
    max-width: 576px;
    padding: 60px 28px;
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}
.title_basket_non_product {
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #1A1A1A;
}
.basket_non_product svg {
    margin-top: 10px;
}
.text_basket_non_product {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    margin-top: 18px;
}
.button_basket_non_product {
    max-width: 162px;
    width: 100%;
    padding: 14px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    color: #1A1A1A;
    border: 1px solid black;
    margin-top: 18px;
}
.button_basket_non_product:hover {
    color: white;
    background: black;
    transition: color 0.4s ease 0s, background  0.4s ease 0s;
}

.basket_mobile_block{
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    border-top: 1px solid rgb(117, 117, 117);
    z-index: 8;
    padding: 12px 11px 27px 12px ;
}
.basket_mobile_block.hide{
    display: none;
}
.basket_mobile_block .basket_btn_disable{
    margin: 12px 0;
    width: 100%;
    flex: 0 0 100%;
    max-width: none;
}
.basket_mobile_block .one_step_go_order_page {
    margin: 12px 0;
    max-width: none;
    width: 100%;
    flex: 0 0 100%;
}

.basket_mobile_block_first{
width: 100%;

}
.basket_mobile_block_first.hide{
  display: none;
    }
.basket_mobile_block_second{
    display: none;
}
.basket_mobile_block_second.active{
    display: flex;
flex-direction: column;
}
.basket_mobile_block_buttons{
    display: flex;
    gap: 12px;
    margin-top: 24px;
}

.basket_mobile_block_back{

height: 44px;
width: 44px;
border: 1.5px solid rgb(26, 26, 26);
}
.basket_mobile_block_second .wrapper_for_fly_basket_promo{
    max-width: none;
} 

.basket_mobile_block_second .checkout_basket_page_body{
    padding: 0;
}
.basket_mobile_block_second{
    width: 100%;
    padding: 10px;
}
.basket_mobile_block_more{
    background-color: #000;
    color: #fff;
    font-weight: 500;
line-height: 18px;
flex: 1;
}
.basket_mobile_block_button{
    font-size: 14px;
font-weight: 500;
line-height: 18px;
display: flex;
gap: 16px;
align-items: center;
}
.basket_mobile_block_button svg{
    width: 7px;height: 12px;
}
@media (max-width: 1440px) {
    .basket_one_step {
        max-width: 1123px;
    }
    .basket_and_order_column{
        max-width: 700px;
    }
    .basket_one_step_disabled {
        max-width: 1123px;
    }
     .basket_page_one_item_content_section{
    margin-left: 10px;
   }
   .basket_page_image_preview {
    max-width: 190px;
   }
   .basket_page_count_controller_and_price_wrapper{
    padding-left: 0;
   }
   .basket_page_content_text_information {
    max-width: 250px;
   }
}
@media (max-width: 1240px) {
    .checkout_basket_page_wrapper {
        max-width: 400px;
    }
    
   
    .basket_btn_disable{
      
        max-width: 350px ;
    }
    .basket_and_order_column{
        max-width: 600px;
    }
    .basket_page_right_column{
        max-width: 400px;

    }
    .one_step_go_order_page{
        gap:4px;
        flex-wrap: wrap;
    }
    .one_step_split_row {
        gap:10px;
        flex-wrap: wrap;
    }
    .basket_page_price_wrapper{
        font-size: 18px;
    }
}
@media (max-width: 1024px) {
    .basket_page {
        height: auto;
    }
    .basket_btn_disable{
      
        max-width: 300px ;
    }
    .checkout_basket_page_wrapper {
        max-width: 343px;
    }
    .basket_page_one_disabled_item_container {
        padding: 18px 18px 18px 40px;
    }
    .delate_wrapper {
        margin-top: 32px;
    }
    .basket_one_step_disabled {
        max-width: 551px;
    }
    .basket_page_notification_button {
        max-width: 289px;
    }
    .basket_one_step {
        max-width: 100%;
    }
    .basket_and_order_column{
        /* max-width: 100%; */
    }
    .basket_page_counter {
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_clear_basket {
        font-size: 14px;
        line-height: 18px;
    }
 
    .basket_page_image_preview {
        max-width: 150px;
        /*min-height: 225px;*/
    }
    .basket_page_one_item_content_section  {
        margin-left: 18px;
    }
    .basket_page_articul_container {
        margin-top: 10px;
        font-size: 12px;
    }
    .basket_page_disabled_info_container{
        margin-top: 10px;
        font-size: 12px;
    }
    .basket_page_price_wrapper {
        margin-top: 18px;
    }
    .basket_page_price_string.old_price {
        font-size: 12px;
        line-height: 16px;
        margin-top: 0px;
    }
    .basket_page_old_price {
        font-size: 16px;
        line-height: 21px;
    }
    .basket_page_content_line_size.counter {
        margin-top: 25px;
        font-size: 12px;
        line-height: 16px;
    }
    .count_one_item_controller_basket_page.count {
        font-size: 10px;
        line-height: 14px;
    }
    .basket_page_color_container {
        font-size: 12px;
        line-height: 16px;
    }
    .basket_page_size_container {
        font-size: 12px;
        line-height: 16px;
    }
    .basket_page_price_and_delete_wrapper {
        justify-content: flex-end;
    }
    .wrapper_container_limiter {
        flex-direction: column;
    }
    .basket_page_right_column {
        margin-left: 0px;
        max-width: 100%;
        min-height: auto;
    }
    .basket_wrapper_for_items {
        margin-top: 40px;
    }
    .heading_basket_page {
        font-size: 24px;
        line-height: 31px;
    }
    .basket_title_text {
        margin: 0px;
        justify-content: flex-start;
    }
    .order_delivery_wrapper {
        padding: 0px 40px 40px 40px;
    }
    .basket_controll_next_step {
        max-width: 100%;
    }
    .basket_special_fucntional_modal {
        justify-content: center;
        flex-direction: column-reverse;
        align-items: center;
    }
    .container_to_switch_modal_state {
        max-width: 100%;
    }
    .map_container_for_switch {
        border-top: 0px solid transparent;
        border-right: 2px solid black;
    }
    .basket_controll_wrapper.two_step {
        flex-direction: column-reverse;
        margin: 0 auto;
        max-width: 100%;
    }
    .basket_controll_next_step.left, .basket_controll_next_step.right {
        max-width: 100%;
    }
    .order_delivery_static_city_name {
        margin-left: 30px;
    }
    .order_delivery_change_city_button {
        margin-left: 190px;
    }
    .one_delivery_method_table {
        margin: 0px 10px 10px 0px;
    }
    .city_changer_function_modal_wrapper {
        margin-top: 0px;
        padding: 18px 40px;
    }
    .choose_office_pvz_list {
        max-height: 220px;
        max-width: 100%;
    }
    .basket_controll_back_step {
        max-width: 100%;
    }
    .count_one_item_controller_basket_page:hover {
        background-color: white;
    }
    .count_one_item_controller_basket_page.min:after {
        background-color: #8d8d8d;
    }
    .count_one_item_controller_basket_page.min:before {
        background-color: #8d8d8d;
    }
    .count_one_item_controller_basket_page.max:after {
        background-color: #8d8d8d;
    }
    .count_one_item_controller_basket_page.max:before {
        background-color: #8d8d8d;
    }

}
@media (max-width: 998px) {
    .basket_and_order_container_wrappers {
        flex-wrap: wrap;
    }
    .basket_page_right_column{
        margin-top: 40px;
    }
    .container_basket_page{
        padding: 0 20px;
    }
        .checkout_basket_page_wrapper {
      max-width: none;
        width: 100%;
       
    }
    .basket_and_order_column{
        width: 100%;
        max-width: none;
    }
}
@media (max-width: 768px) {
    .title_basket_non_product {
        font-size: 24px;
    }
    .basket_modal_container{
        right: 13px !important;
        height: 301px;
        top: calc(50% - 301px);

        left: 13px;
        width: 100%;
    }
    .basket_modal_header{
        border: none;
        height: 44px;
    }
    .basket_modal_header img{
        height: 12px;
        width: 12px;
    }
    .basket_page_counter{
        flex: 0 0 100%;
    }
    .basket_non_product svg {
        margin-top: 17px;
    }
    .text_basket_non_product {
        margin-top: 20px;
        font-size: 12px;
        line-height: 16px;
    }
    .button_basket_non_product {
        margin-top: 28px;
    }
    .map_container_for_switch {
        border: 0;
    }
    .select_this_pvz {
        width: 100%;
        max-width: 100%;
    }
    .basket_count_items_and_clear_line {
        padding: 8px 16px;
        gap: 11px;
        flex-wrap: wrap;
    }
    .basket_page_checkbox_center {
        margin-left: auto;
    }
    .basket_page_clear_basket_wrapper{
        margin: 0;
    }
    .basket_page_slider_block{
        display: none;
    }
    .basket_page_counter {
        font-size: 16px;
        line-height: 21px;
    }
    .basket_page_one_disabled_item_container {
        padding: 20px 10px;
    }
    .container_basket_page{
        padding: 0px;
    }
    .basket_page_image_preview {
        margin: 0;
        max-width: 110px;
        /*min-height: 162px;*/
    }
    .basket_page_one_item_content_section {
        margin-left: 10px;
    }
    .basket_page_content_text_information {
        justify-content: center;
        max-width: 100%;
    }
    .content_basket_page_wrapper {
        padding: 0px 20px;
    }
    .basket_page_count_controller {

        display: flex;
        align-items: center;
    }
    .basket_page_content_line_size.counter {
        margin-top: 0px;
    }
    .basket_page_trash_container {
        margin-left: 10px;
    }
    .basket_page_name_container {
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_disabled_name_container {
        font-size: 14px;
        line-height: 18px; 
    }
    .basket_page_disabled_info_container{
        font-size: 12px;
        line-height: 16px;
    }
    .basket_page_articul_container {
        font-size: 12px;
        line-height: 16px;
        margin-top: 0px;
    }
    .basket_mobile_block{
        display: flex;
    }
    .basket_page_price_wrapper {
        margin-top: 10px;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_old_price {
        font-size: 14px;
        line-height: 18px;
    }
    .basket_page_price_string.old_price {
        margin-left: 25px;
        margin-right: 0px;
    }
    .basket_page_one_item_checkbox{
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .basket_page_color_container {
        margin-top: 10px;
    }
    .basket_page_size_container {
        margin-top: 10px;
    }
    .basket_page_count_controller {
        margin-top: 10px;
        flex-direction: row-reverse;
        padding-left: 30px;
        gap: 10px;
    }

    .modal_state_choose_pvz_tabs {
        display: flex;
    }
    .modal_state_choose_pvz_tab {
        outline: none;
        padding: 0;
        margin: 0;
        border: 0;
        background: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 0;
        font-weight: 500;
        border-bottom: 2px solid transparent;
        color: #1A1A1A;
        opacity: 0.5;
        transition: .3s;
        cursor: pointer;
    }
    .modal_state_choose_pvz_tab:hover,
    .modal_state_choose_pvz_tab.active {
        border-color: #1A1A1A;
        opacity: 1;
    }
    .count_one_product_controller_basket_page {
        width: 75px;
        margin-left: 10px;
    }
    .wrapper_trash_basket {
        font-size: 10px;
        line-height: 13px;
    }
    .name_line_basket_page {
        max-width: 55px;
        width: 100%;
    }
    .heading_basket_page {
        padding: 20px;
        font-size: 18px;
        line-height: 23px;
    }
    .controll_indicator_wrapper {
        padding: 20px;
    }
    .basket_page_image_like {
        top: 150px;
        right: -35px;
        z-index: 8;
    }
    .order_delivery_wrapper {
        padding: 5px 20px 40px 20px;
    }
    .order_delivery_city_wrapper {
        margin-top: 40px;
        flex-direction: column;
    }
    .order_delivery_static_city_name {
        margin-left: 0px;
        margin-top: 10px;
    }
    .order_delivery_change_city_button {
        margin-left: 0px;
        margin-top: 18px;
    }
    .delivery_response_no_delivery_info {
        text-align: start;
    }
    .basket_special_fucntional_modal {
        padding: 5px 20px;
    }
    .basket_functional_modal_for_close {
        padding: 20px;
    }
    .basket_order_fucntional_exit_div {
        width: 15px;
        height: 15px;
    }
    .basket_order_fucntional_exit_div:after {
        height: 15px;
        left: 6px;
    }
    .basket_order_fucntional_exit_div:before {
        height: 15px;
        left: 6px;
    }
    .search_city_input_fuctional_modal {
        font-size: 24px;
        line-height: 31px;
    }
    .functional_modal_support_wrapper .bar_icon_wrapper.fill {
        max-width: 20px;
        max-height: 20px;
        margin-right: 0px;
    }

    .functional_modal_support_wrapper .bar_icon_wrapper.fill svg{
        max-width: 20px;
        max-height: 20px;
    }
    .functional_modal_support_wrapper:after {
        bottom: -20px;
    }
    .favorite_citys_functional_modal {
        margin-top: 40px;
    }
    .delivery_response_list {
        flex-direction: column;
    }
    .modal_state_choose_pvz_heading {
        font-size: 16px;
        line-height: 21px;
    }
    .modal_state_choose_pvz {
        padding: 20px;
    }
    .modal_satate_choose_pvz_city_info {
        margin-top: 0px;
    }
    .choose_office_pvz_list {
        margin-top: 20px;
        padding-right: 5px;
    }
    .choose_office_pvz_list {
        max-height: 100%;
    }
    .basket_controll_third_step_wrapper {
        padding: 5px 20px 40px 20px;
    }
    .basket_page_one_item_container {
        position: relative;
        padding: 16px;
    }
    .first_basket_order_user_fields_wrapper {
        flex-direction: column;
    }
    .basket_controll_next_step{
        margin-top: 20px;
    }
    .one_office_pvz_right_content {
        padding-bottom: 0px;
    }
    .wrapper_container_limiter.finish_step {
        flex-direction: column;
    }
    .basket_page_right_column {
        margin-top: 40px;
    }
    .container_to_switch_modal_state {
        height: 85%;
    }
    .checkout_basket_page_wrapper{
        display: none;
    }
    .checkout_basket_page_wrapper.active{
        display: block;
    }
    .basket_btn_disable{
        max-width: none;
        width: 93%;
        
    }
}

@media (max-width: 446px)  {
    .basket_page_notification_button {
        align-self: center;
        max-width: 100%;
        /* max-width: 289px; */
        width: 100%;
    }
    .one_step_dot{
        display: none;
    }
    .one_step_go_order_page{
        justify-content: center !important;
    }
    .basket_btn_disable{
        width: 85%;
    }
}

@media (max-width: 380px)  {
    .basket_page_one_item_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .basket_page_disable_content_container {
        flex-direction: column;
        align-items: center;
    }
    .basket_page_one_disabled_item_container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .basket_page_image_preview {
        max-width: 200px;
        width: 100%;
        
        min-height: auto;
        display: flex;
    }
    .basket_page_container_price_and_basket {
        margin-top: 10px;
    }
    .basket_page_one_item_content_section {
        margin-left: 0px;
    }
    .delate_wrapper {
        margin-top: auto;
    }
    .wrapper_price_basket {}
}
