.find_user_by_number_container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
}

.red_valid_text {
    font-size: 12px;
    text-transform: uppercase;
    color: #CE3F3F;
    font-weight: 600;
}
.gray_valid_text {
    color: gray;
    font-weight: 600;
    font-size: 10px;
    margin-top: 5px;
}
.exist_get_me_sms {
    margin-top: 10px;
    padding: 7px 10px;
    display: flex;
    border: 1px solid black;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    width: max-content;
    cursor: pointer;
    font-size: 12px;
}
.exist_get_me_sms_unactive {
    margin-top: 10px;
    padding: 7px 10px;
    display: flex;
    border: 1px solid black;
    color: black;
    background-color:gray;
    text-transform: uppercase;
    font-weight: 600;
    width: max-content;
    font-size: 12px;

}
.exist_get_me_sms:hover {
    background-color: black;
    color: white;
}
div .exist_auth_sms_code {
    margin-top: 5px;
}
.wrapper_for_auth_field {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.get_user_new_sms {
    color: gray;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    pointer-events: none;
}
.get_user_new_sms.active {
    color: black;
    pointer-events: auto;
}

.error_message_exist_user {
    font-size: 12px;
    text-transform: uppercase;
    color: #CE3F3F;
    font-weight: 600;
    margin-top: 10px;
}
.wrapper_for_exist_user_notificator {
    display: flex;
    flex-direction: column;
}
.auth_exist_number_confirm {
    font-size: 12px;
    text-transform: uppercase;
    color: green;
    font-weight: 600;
    margin-top: 10px;
}