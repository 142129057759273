.one_product_page_wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding-bottom: 40px;
}
.page_one_product_wrapper_for_components {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}
.product_page_content_container {
    display: flex;
    width: 100%;
    height: auto;
    margin: 0 auto;
    justify-content: center;
    position: relative;
}
.back_button_product_wrapper {
    display: flex;
    justify-content: flex-start;
}
.back_button_product {
    padding: 10px;
    font-size: 16px;
    height: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.back_button_product span {
    margin-left: 10px;
}
.container_slider_sticky {
    max-width: 950px;
    height: max-content;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    /*justify-content: flex-start;*/
}

.product_page_first_image_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*height: 100%;*/
    padding: 0px 10px;
}
.product_img_wrapper {
    position: relative;
    cursor: zoom-in;
}
.product_page_first_image_container img {
    display: flex;
    max-width: 950px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    /*max-height: 987px;*/
}
.product-modal {
    height: 100%;
}
.product-modal__window {
    background-color: #FFFFFF;
    position: relative;
    z-index: 1;
    padding: 0;
}
.product-modal__img {
    display: block;
    max-width: 75%;
    margin-inline: auto;
    width: 100%;
    height: auto;
}
.product-modal__navigation {
}
.product-modal__navigation .swiper-button-prev {
    position: fixed;
}
.product-modal__navigation .swiper-button-next {
    position: fixed;
}
.product-modal__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 0;
    max-width: 100%;
    position: relative;
}
.product-modal__wrapper .swiper-container {
    height: 100%;
    width: 100%;
    padding-inline: 75px;
}
.product-modal__wrapper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
.product-modal__wrapper .swiper-button-prev,
.product-modal__wrapper .swiper-button-next {
    color: currentColor;
}
.product-modal__wrapper .swiper-button-prev::after,
.product-modal__wrapper .swiper-button-next::after {
    font-size: 24px;
}
.product-modal__close {
    display: block;
    position: fixed;
    padding: 16px;
    z-index: 2;
    right: 0;
    top: 0;
    cursor: pointer;
    outline: none;
}
.product-modal__close svg {
    display: block;
    width: 24px;
    height: 24px;
}
@media (max-width: 768px) {
    .product-modal__img {
        max-width: 100%;
    }
    .product-modal__wrapper .swiper-container {
        padding-inline: 32px;
    }
}
.product_img_array {
    display: flex;
    flex-direction: column;
    /*background: #E69191;*/
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.one_img_product {
    width: 110px;
    height: 110px;;
    padding: 5px;
    cursor: pointer;
}
.one_img_product img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid transparent;
    padding: 1px;
}
.one_img_product.active img{
    border: 1px solid black;
    transition: border 0.4s ease 0s;
}

.product_page_right_content_container {
    display: flex;
    max-width: 912px;
    width: 50%;
    padding: 0px 30px;
    flex-direction: column;
}
.like_and_support_call {
    width: 100%;
    display: flex;
    height: 30px;
    margin-top: 42px;
    width: 100%;
}
.product_page_like_wrapper {
    /*width: 27px;*/
    /*height: 30px;*/
    padding: 18px;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0px;
}
.product_page_call_svg_wrapper {
    width: 27px;
    height: 30px;
    display: flex;
}
.product_page_call_manager {
    display: flex;
    margin-left: 55px;
    cursor: pointer;
    width: max-content;
}

.call_manager_text {
    display: flex;
    margin-left: 28px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #000000;
    align-items: center;
    position: relative;
}
.call_manager_text:after {
    position: absolute;
    content: "";
    bottom: 2px;
    left: 0px;
    height: 1px;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.4s ease 0s;
}
.product_page_call_manager:hover .call_manager_text:after {
    background-color: black;
    transition: background-color 0.4s ease 0s;
}
.product_page_h1_container {
    display: flex;
    width: 100%;
    /*min-height: 88px;*/
    /*margin-top: 43px;*/
}
.page_product_heading {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.04em;
    color: #1A1A1A;
}
.page_product_atricul {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #757575;
    margin-top: 10px;
    display: flex;
}
.one_product_page_price_line {
    display: flex;
    /*height: 44px;*/
    align-items: center;
    margin-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #000000;
}

.standard_price.old_price {
    text-decoration-line: line-through;
    margin-left: 40px;
    color: #D7D7D7;
    font-weight: 300;
    font-size: 30px;
}

.one_page_content_selectors_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
}
.description_product_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.product_page_shield_child_product {
    display: flex;
    min-height: 57px;
    width: 100%;
    border-top:  2px solid #1A1A1A;
    flex-direction: column;
}
.product_page_shield_child_product:last-child {
    border-bottom: 2px solid #1A1A1A;
}
.product_page_shield_child_product:first-child {
    border-top:  2px solid transparent;
}

.product_page_shield_name {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 55px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 5px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.arrow_to_controll_shield {
    display: flex;
    height: 100%;
    width: 50px;
    position: relative;
}
.product_page_shield_child_product .arrow_to_controll_shield:after {
    position: absolute;
    content: "";
    width: 14px;
    height: 2px;;
    background-color: #1A1A1A;
    top: 11px;
    left: 18px;
    border-radius: 3px;
    transform: rotate(90deg);
    opacity: 1;
    transition: opacity 0.2s ease 0.1s;
}
.product_page_shield_child_product .arrow_to_controll_shield:before {
    position: absolute;
    content: "";
    width: 14px;
    height: 2px;
    background-color: #1A1A1A;
    top: 11px;
    left: 18px;
    transform: rotate(0deg);
}

.product_page_shield_child_product.active .arrow_to_controll_shield:after {
    position: absolute;
    content: "";
    opacity: 0;
    transition: opacity 0.2s ease 0.1s;
}
.product_page_shield_child_product.active .arrow_to_controll_shield:before {
    position: absolute;
    content: "";
    width: 14px;
    height: 2px;
    background-color: #1A1A1A;
    top: 10px;
    left: 18px;
    transform: rotate(0deg);
}



.product_page_child_items_container {
    height:  0px;
    display: flex;
    width: 100%;
    opacity: 0;
    padding: 0px 5px;
    transition: height 0.3s ease 0.3s, opacity 0s ease 0s,padding 0.3s ease 0s;
    white-space: pre-line;
    pointer-events: none;
}
.product_page_shield_child_product.active .product_page_child_items_container {
    padding: 0px 5px 18px 5px;
    height: auto;
    display: flex;
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease 0.1s, height 0.2s ease 0s,padding 0.3s ease 0s;
    pointer-events: auto;
    flex-direction: column;
}
.product_page_wrapper_for_sostav {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    overflow-x: scroll;
}

/* SCROLLBAR */
/* Let's get this party started */
.product_page_wrapper_for_sostav::-webkit-scrollbar {
    height: 3px;
}
/* Track */
.product_page_wrapper_for_sostav::-webkit-scrollbar-track {
    background: #cecece;
    border: 1px solid transparent;
    background-clip: content-box;   /* THIS IS IMPORTANT */
}
/* Handle */
.product_page_wrapper_for_sostav::-webkit-scrollbar-thumb {
    background: rgb(25,25,25);
    border: 1px solid rgb(0, 0, 0);
}


.product_page_one_sostav {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    padding-bottom: 18px;
}
.product_page_one_sostav span {
    white-space: nowrap;
}

.product_page_description_product_text {
    display: flex;
    width: 100%;
    height: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    white-space: pre-line;
}
.obmer_wrapper_product_page {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 163px;
    /*flex-direction: column;*/
    justify-content: space-between;
    /*padding-right: 55px;*/
}

.product_page_size_items {
    display: flex;
    height: 100%;
    /*overflow-x: scroll;*/
    padding-right: 16px;
    width: 100%;
    flex-direction: column;
    padding-bottom: 25px;
}
/* SCROLLBAR */
/* Let's get this party started */
/*.product_page_size_items::-webkit-scrollbar {*/
/*    height: 3px;*/
/*}*/
/* Track */
/*.product_page_size_items::-webkit-scrollbar-track {*/
/*    background: #cecece;*/
/*    border: 1px solid transparent;*/
/*    background-clip: content-box;   !* THIS IS IMPORTANT *!*/
/*}*/
/* Handle */
/*.product_page_size_items::-webkit-scrollbar-thumb {*/
/*    background: rgb(25,25,25);*/
/*    border: 1px solid rgb(0, 0, 0);*/
/*}*/


.product_page_size_list {
    display: flex;
    margin-top: 10px;
    /*width: max-content;*/
    max-width: 300px;
    flex-wrap: wrap;
    /*border: 1px solid black;*/
}
.product_page_obmer_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}

.product_page_size_button {
    display: flex;
    /*width: 100%;*/
    width: 55px;
    /*padding: 8px 10px;*/
    padding: 5px;
    min-height: 35px;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #424242;
    background: #FFFFFF;
    border: 1px solid #000000;
    /*border-radius: 50%;*/
    cursor: pointer;
    transition: background-color 0.2s ease 0s, color 0.4s ease 0s;
    /*margin-left: 18px;*/
    /*margin-right: 18px;*/
    /*margin-top: 10px;*/
    /*Чтобы ONESIZE не вылазил за блок*/
    overflow: hidden;
}
.product_page_size_button:first-child {
    margin-left: 0px;
}
.product_page_size_button:hover {
    background-color: black;
    color: white;
    border-color: black;
    transition: background-color 0.2s ease 0s, color 0.4s ease 0s;
}
.product_page_size_button.active {
    background-color: black;
    color: white;
    border-color: black;
    transition: background-color 0.2s ease 0s, color 0.4s ease 0s;
}
.one_column_obmer {
    /*border-right: 1px solid black;*/
    margin-right: 20px;
    margin-top: 10px;
}
/*.one_column_obmer:last-child{*/
/*    border-right: 1px solid transparent;*/
/*}*/
.obmer_meaning {
    width: 100%;
    height: 38px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-top: 1px solid #F5F5F5;
}
.obmer_meaning:last-child {
    height: 54px;
}
.icon_for_call {
    display: flex;
    width: 35px;
    height: 35px;
}
.wrapper_call_container {
    display: flex;
    width: 200px;
}
.one_call_element_wrapper {
    display: flex;
    width: max-content;
    text-decoration: none;
    color: black;
    cursor: pointer;
    margin-left: 10px;
}
.one_call_element_wrapper:first-child {
    margin-left: 0px;
}
.one_call_element_text {
    display: flex;
    margin-left: 20px;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    height: 100%;
    align-items: center;
}
.obmer_name {
    height: 38px;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 6px 21px;
    border-top: 1px solid #F5F5F5;
}
.obmer_name.one_line {
    height: 47px;
    border-top: 1px solid transparent;
}
.obmer_name:last-child {
    height: 54px;
}
.content_selection_size {
    display: flex;
    flex-direction: column;
    /*max-width: 376px;*/
    width: 100%;
    margin-top: 18px;
}
.content_selection_size:first-child {
    margin-top: 0px;
}
.description_size_model {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.product_page_obmer_size_params {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.product_page_obmer_size_params:first-child {
    margin-top: 0px;
}
.to_bottom_border_obmer {
    border-bottom: 1px solid #CFCFCF;
    display: flex;
    flex-grow: 1;
    margin: 0px 8px;
}
.product_page_landrys_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
}
.product_page_one_landry {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    margin-top: 5px;
    opacity: 0.5;
    cursor: pointer;
}
.product_page_one_landry.active {
    opacity: 1;
}

.product_page_one_landry_text {
    pointer-events: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #1A1A1A;
    display: flex;
    background-color: white;
    margin-top: 10px;
}
/*.product_page_one_landry:hover .product_page_one_landry_text {*/
/*    opacity: 1;*/
/*    z-index: 1;*/
/*}*/
.page_product_color_changer_wrapper {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.page_product_name_field {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}

.page_product_color_changer {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
}
.product_page_color_circle {
    display: flex;
    width: 28px;
    height: 28px;
    cursor: pointer;
    background-position: center;
    border: 1px solid #717171;
}
.product_page_color_circle.active {
    width: 29px;
    height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.14);
}

.link {
    margin-top: 20px;
    color: #000000;

}

.link:hover {
    font-weight: 500;
   }
/*.one_product_color_border {*/
/*    margin-left: 25px;*/
/*}*/
/*.one_product_color_border.active {*/
/*    border: 1px solid black;*/
/*    padding: 1px;*/
/*}*/
/*.one_product_color_border:first-child {*/
/*    margin-left: 0px;*/
/*}*/

.one_product_color_wrapper {
    display: flex;
    width: 32px;
    height: 32px;
    /*border: 2px solid rgba(211, 211, 211, 0.34);*/
    align-items: center;
    justify-content: center;
    margin-right: 25px;
    margin-top: 10px;
}
.one_product_color_wrapper.active {
    border: 1px solid black;
    padding: 1px;
    transition: border 0.8s ease 0s;
}
.one_product_color_wrapper.active .product_page_color_circle.active {
    border: unset;
}

.page_product_size_wrapper {
    margin-top: 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.page_product_size_name_line {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.page_product_size_name {
    font-weight: 600;
}
.page_product_sizes_container {
    display: flex;
    /*margin-top: 5px;*/
    flex-wrap: wrap;
}
.page_product_size_one_item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
    margin-top: 10px;
    cursor: pointer;
    user-select: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid transparent;
}


.page_product_size_one_item.not_available {
    text-align: center;
    text-decoration-line: line-through;
    text-transform: uppercase;
    color: #808080;
}

.page_product_size_one_item.active {
    border-bottom: 1px solid black;
    color: #000000;
    transition: border-bottom 0.2s ease 0s, color 0.2s ease 0s;
}
.product_page_controll_buttons_offer_and_basket {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    max-width: 483px;
}
.product_page_add_to_basket {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 239px;
    width: 100%;
    min-height: 49px;
    height: 100%;
    background: #1A1A1A;
    cursor: pointer;
    border: 1px solid white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px;
}
.product_page_add_to_basket:hover {
    background-color: white;
    border-color: black;
    color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;

}
.product_page_add_to_basket.disactive {
    background-color: lightgrey;
    pointer-events: none;
    user-select: none;
}
.product_page_add_to_basket.notification_button {
    max-width: 410px;
    min-height: 50px;
}


.product_page_go_offer {
    display: flex;
    max-width: 201px;
    width: 100%;
    min-height: 49px;
    height: 100%;
    border: 1px solid #424242;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
    padding: 10px;
}

.product_page_go_offer:hover {
    color:white;
    background-color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
}
.product_page_horizontal_photo {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
}
.product_page_horizontal_photo img {
    display: flex;
    width: 100%;
    height: auto;
}
.product_page_other_image_container {
    max-width: 1920px;
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product_page_one_other_image {
    width: calc(50% - 10px);
    margin-top: 20px;
}
.product_page_one_other_image img {
    width: 100%;
}

.one_product_page_pre_order {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 410px;
    height: 58px;
    background: #1A1A1A;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    /*text-transform: uppercase;*/
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid white;
}
.one_product_page_pre_order:hover {
    background-color: white;
    border-color: black;
    color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
}
.pre_order_modal_for_new_order {
    display: flex;
    width: 100%;
    max-width: 540px;
    /*height: 95%;*/
    min-height: 500px;
    background-color: white;
    position: fixed;
    opacity: 0;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border: 1px solid black;
}
.pre_order_finally_button {
    display: flex;
    height: 50px;
    font-weight: 500;
    font-size: 13px;
    /*text-transform: uppercase;*/
    color: white;
    background-color: black;
    border: 1px solid black;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}
.pre_order_finally_valid_text {
    display: flex;
    height: 40px;
    width: 100%;
    align-items: center;
    color: #CE3F3F;
    font-weight: 500;
}
.pre_order_finally_button:hover {
    background-color: white;
    color: black;
    transition: color 0.4s ease 0s, background-color 0.4s ease 0s;
}


.pre_order_modal_for_new_order.active {
    z-index: 10;
    opacity: 1;
    transition: opacity 1s ease 0s;
    box-shadow: 0 10px 25px rgb(0 0 0 / 15%);
}
.pre_order_modal_wrapper_for_content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    border: 1px solid black;
}
.pre_order_modal_wrapper_for_content.non_border {
    border: 1px solid transparent;
}
.pre_order_modal_heading_line_and_exit {
    display: flex;
    padding: 18px 40px;
}

.order_modal_heading_text {
    display: flex;
    flex-grow: 1;
    /*height: 30px;*/
    text-align: center;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.03em;
}
.order_modal_exit_div {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    margin-left: auto;
}
.order_controll_line {
    display: flex;
    width: 100%;
    margin-top: 20px;
}

.order_modal_exit_div:after {
    content: "";
    width: 30px;
    height: 2px;
    position: absolute;
    left: 0px;
    top: 15px;
    transform: rotate(45deg);
    background-color: black;
}

.order_modal_exit_div:before {
    content: "";
    width: 30px;
    height: 2px;
    position: absolute;
    left: 0px;
    top: 15px;
    transform: rotate(-45deg);
    background-color: black;
}

.relative_container_for_pre_order {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    border-top: 1px solid black;
}
.relative_container_for_pre_order.non_border {
    border-top: 1px solid transparent;
}

.modal_content_pre_order {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 18px 40px;
}

.modal_content_pre_order_heading_line {
    text-decoration: none;
    display: flex;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    max-width: 372px;
    color: gray;
}



.modal_content_pre_order_wrapper_for_row {
    display: flex;
    width: 100%;
    /*height: 100%;*/
    margin-top: 30px;
    /*max-height: 200px;*/
}

.modal_content_pre_order_img_container {
    display: flex;
    width: 140px;
    height: 200px;
}

.modal_content_pre_order_img_container img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.modal_content_pre_order_wrapper_for_column_content {
    display: flex;
    margin-left: 35px;
    flex-direction: column;
    justify-content: center;
    /*height: 200px;*/
}

.modal_content_pre_order_name_line {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.modal_content_pre_order_color_wrapper {
    display: flex;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-left: 20px;

}
.modal_content_pre_order__color_circle {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.modal_content_pre_order_color_name {
    display: flex;
    margin-top: 15px;
    align-items: center;

}

.modal_content_pre_order_color_name:last-child{
    margin-top:  auto;
}
.modal_content_pre_order_color_name  span{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}
.modal_content_pre_order_color_name .standard_price.old_price {
    font-size: 12px;
    margin-left: 18px;
}

.modal_content_pre_order_color_name  .modal_content_pre_order_black_span {
    font-weight: 400;
    font-size: 14px;
    margin-right: 15px;
}
.wrapper_for_mini_count_controller_wp {
    display: flex;
    height: 30px;
    width: 95px;
    margin-left: 20px;
    background: #FFFFFF;
    border: 1px solid #C9C9C9;
    /*border-radius: 5px;*/
}
.wrapper_for_mini_count_controller {
    display: flex;
    height: 100%;
    width: 33.33%;
    flex-grow: 1;
    cursor: pointer;
    transition: background-color 0.4s ease 0s;
    position: relative;
    justify-content: center;
    align-items: center;
}
.product_page_add_to_basket.sertificat {
    margin-top: 40px;
}
.wrapper_for_mini_count_controller.min {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.wrapper_for_mini_count_controller.min:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    right: 18px;
    top: 12px;
    border-radius: 2px;
    transform: rotate(-45deg);
}
.wrapper_for_mini_count_controller.min:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    right: 18px;
    top: 5px;
    border-radius: 2px;
    transform: rotate(45deg);
}
.wrapper_for_mini_count_controller.center {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    -webkit-user-select: none;
    user-select: none;
}



.wrapper_for_mini_count_controller.max {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.wrapper_for_mini_count_controller.max:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    left: 18px;
    top: 12px;
    border-radius: 2px;
    transform: rotate(-135deg);
}
.wrapper_for_mini_count_controller.max:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #8d8d8d;
    left: 18px;
    top: 5px;
    border-radius: 2px;
    transform: rotate(135deg);
}

.wrapper_for_mini_count_controller:hover {
    background-color: black;
    color: white;
}
.wrapper_for_mini_count_controller.center:hover {
    background-color: white;
    color: black;
}
.wrapper_for_mini_count_controller:hover:after{
    background-color: white;
}
.wrapper_for_mini_count_controller:hover:before{
    background-color: white;
}

.pre_order_fields_modal {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.pre_order_fields_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
}

.pre_order_fields_wrapper span{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #000000;
}
.pre_order_fields_wrapper input {
    display: flex;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: black;
}

.wrapper_for_counter {
    display: flex;
    align-items: center;
}

.pre_order_success {
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: 45px;
}
.slider_product_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    max-width: 1920px;
    width: 100%;
    padding: 0px 40px;
}
.switch_slider {
    display: flex;
    cursor: pointer;
    min-height: 35px;
}
.title_slider {
    display: flex;
    min-height: 35px;
}

.switch_slider.active {
    border-bottom: 1px solid #757575;;
    max-width: max-content;
}
.title_image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 30px;
}
.title_image span{
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /*text-align: right;*/
    color: #757575;
}
.title_image_switch {
    height: 1px;
    background: transparent;
    transition: background 0.4s ease 0s, height 0.4s ease 0s;
    display: flex;
}
.title_like{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.title_like span {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    /*text-align: right;*/
    color: #757575;
}

.title_like_switch {
    height: 1px;
    background: transparent;
    transition: background 0.4s ease 0s, height 0.4s ease 0s;
    display: flex;
}
.title_like.active span {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1A1A1A;
}
.title_like.active .title_like_switch{
    height: 2px;
    background: #000000;
    transition: background 0.4s ease 0s, height 0.4s ease 0s;
}

.title_image.active span{
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.title_image.active .title_image_switch{
    height: 2px;
    background: #000000;
    transition: background 0.4s ease 0s, height 0.4s ease 0s;
}

.modal_sertificate {
    display: flex;
    max-width: 911px;
    width: 100%;
    min-height: 572px;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 5000;
    transform: translate(-50%, -50%);
}
.sertificate_close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: -25px;
    top: -25px;
    cursor: pointer;
}
.sertificate_close_child {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.sertificate_close_child:after {
    content: " ";
    position: absolute;
    width: 15px;
    height: 2px;
    transform: rotate(45deg);
    background-color: #FFFFFF;
    top: 9px;
    right: 2px;
    border-radius: 10px;
}
.sertificate_close_child::before {
    content: " ";
    position: absolute;
    width: 15px;
    height: 2px;
    transform: rotate(-45deg);
    background-color: #FFFFFF;
    top: 9px;
    right: 2px;
    border-radius: 10px;
}
.modal_sertificate_no_pos {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}
.heading_sertificate_modal {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
    padding: 20px 40px;
    height: max-content;
    width: 100%;
    border-bottom: 1px solid #000000;
}
.cute_form_wrapper {
    max-width: 720px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 50px;
}
.cute_field_wrapper {
    margin-top: 20px;
}
.cute_field_notif {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    position: relative;
    height: 21px;
    font-weight: 500;
}
.red_cute {
    color:#FF6363;
    position: absolute;
    right: -9px;
    top:-4px;
}
.cute_input {
    height: 40px;
    width: 100%;
    margin-top: 7px;
    border: 1px solid #D7D6D6;
    padding-left: 20px;
}
.cute_input.anlg {
    margin-top: 0px;
}

.wrapper_for_cute_input {
    width: 100%;
    height: 40px;
    display: flex;
    max-height: 40px;
    margin-top: 7px;
}
.box_for_select_country {
    width: 40px;
    height: 40px;
    border: 1px solid #D7D6D6;
    margin-right: 3px;
    cursor: pointer;
    position: relative;
}
.current_cut_country {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper_for_w_selected {
    display: flex;
    position: absolute;
    width: 300px;
    height: 200px;
    flex-direction: column;
    overflow-y: scroll;
    background-color: white;
    border: 1px solid #D7D6D6;
    z-index: 1;
}

.cute_one_selector {
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 3px;
}
.cute_one_selector:hover {
    background-color: #999999;
    cursor: pointer;
}
.cute_one_selector span {
    margin-left: 10px;
    font-weight: 500;
}
.validator_cute_line {
    height: 30px;
    padding-left: 10px;
    color:#FF6363;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

}
.buy_sertificate_btn {
    max-width: 247px;
    min-height: 49px;
    background: #1A1A1A;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.buy_sertificate_btn:hover {
    color: black;
    background-color: white;
    border: 1px solid black;
}
.price_and_shares {
    display: flex;
    flex-direction: column;
}
.installment_wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;
    text-decoration: none;
}
.installment_child {
    display: flex;
    align-items: center;
    margin-left: 13px;
    text-decoration: none;
}
.installment_text {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #757575;
}
.installment_price {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #1A1A1A;
    margin-left: 10px;
}


@media (max-width: 1440px) {
    .page_one_product_wrapper_for_components {
        max-width: 1180px;
    }
    .product_page_first_image_container img {
        max-width: 510px;
    }
}

@media (max-width: 1024px) {
    .one_product_page_wrapper {
        height: auto;
    }

    .installment_text, .installment_price {
        font-size: 14px;
    }

    .product_page_content_container {
        flex-direction: column;
        align-items: center;
    }
    .container_slider_sticky {
        position: relative;
        max-width: 565px;
    }
    .product_page_right_content_container {
        max-width: 550px;
        padding: 0px 10px;
        width: 100%;
        margin-top: 40px;
    }
    .product_page_first_image_container img {

    }
    .one_img_product {
        width: 80px;
        height: 80px;
    }

    .page_product_heading {
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.04em;
    }
    .page_product_atricul {
        font-size: 12px;
        line-height: 16px;
    }
    .one_product_page_price_line {
        font-size: 24px;
        line-height: 31px;
        margin-top: 18px;
    }
    .one_product_page_price {
        font-size: 24px;
    }
    .standard_price.old_price {
        font-size: 18px;
        margin-left: 20px;
    }
    .description_product_wrapper {
        margin-top: 20px;
    }
    .obmer_wrapper_product_page {
        flex-direction: column;
    }
    .product_page_size_list {
        max-width: unset;
    }


    .modal_content_pre_order_wrapper_for_row {
        /*flex-direction: column;*/
    }
    .modal_content_pre_order_wrapper_for_column_content {
        margin-left: 10px;
    }
    .modal_content_pre_order_name_line{
        font-size: 16px;
    }
    .modal_content_pre_order_color_name {
        margin-top: 10px;
    }
    .modal_content_pre_order_color_name:last-child {
        margin-top: 18px;
    }

    .back_button_product_wrapper {
        width: 100%;
    }
    .back_button_product {
        padding: 10px 40px;
    }

    .modal_sertificate {
        width: 90%;
        min-height: 530px;
    }
    .heading_sertificate_modal {
        padding: 20px;
        font-size: 20px;
    }
    .cute_form_wrapper {
        padding: 40px 20px;
    }
    .buy_sertificate_btn {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .one_product_page_pre_order {
        width: 100%;
    }
    .container_slider_sticky {
        max-width: 100%;
        flex-direction: column;
    }
    .product_page_first_image_container {
        padding: 0px;
    }
    .product_img_wrapper {

    }
    .product_img_array {
        flex-direction: initial;
        margin-top: 15px;
    }

    .product_page_right_content_container {
        margin-top: 20px;
        padding: 0px 20px;
    }
    .page_product_heading {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.04em;
    }
    .one_product_page_price_line {
        font-size: 18px;
        line-height: 23px;
        margin-top: 10px;
    }
    .one_product_page_price {
        font-size: 18px;
    }
    .standard_price.old_price {
        font-size: 16px;
        margin-left: 20px;
    }
    .one_product_color_wrapper {
        margin-right: 15px;
    }
    .page_product_size_one_item {
        margin-right: 35px;
    }
    .product_page_shield_child_product.active .product_page_child_items_container {
        padding: 0px 0px 16px 0px;
    }
    .product_page_shield_name {
        padding: 16px 0px;
    }
    .product_page_description_product_text {
        font-size: 12px;
        line-height: 16px;
    }
    .product_page_wrapper_for_sostav {
        font-size: 12px;
        line-height: 16px;
    }
    .product_page_one_landry{
        margin-right: 15px;
    }
    .product_page_one_landry img {
        max-width: 20px;
    }
    .product_page_one_landry_text {
        font-size: 12px;
        line-height: 16px;
    }
    .product_page_obmer_text {
        font-size: 14px;
        line-height: 16px;
    }
    .product_page_obmer_size_params {
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
    }
    /*.product_page_size_button,*/
    .obmer_name.one_line,
    .obmer_name,
    .obmer_meaning{
        height: 35px;
        font-size: 12px;
    }
    .product_page_size_button {
        font-size: 12px;
        width: 45px;
    }
    .obmer_name:last-child, .obmer_meaning:last-child {
        height: 35px;
    }
    .product_page_controll_buttons_offer_and_basket {
        flex-direction: column;
        max-width: 100%;
    }
    .product_page_add_to_basket {
        max-width: 100%;
    }
    .product_page_go_offer {
        margin-left: 0px;
        margin-top: 20px;
        max-width: 100%;
    }

    .installment_text, .installment_price {
        font-size: 12px;
    }

    .pre_order_modal_for_new_order {
        width: 95%;
    }
    .pre_order_modal_wrapper_for_content {

    }
    .pre_order_modal_heading_line_and_exit {
        padding: 10px;
    }
    .modal_content_pre_order {
        padding: 10px;
    }
    .modal_content_pre_order_heading_line {
        font-size: 12px;
    }
    .modal_content_pre_order_wrapper_for_row {
        margin-top: 18px;
    }
    .modal_content_pre_order_name_line {
        font-size: 14px;
        line-height: 18px;
    }
    .modal_content_pre_order_color_name .modal_content_pre_order_black_span {
        font-size: 12px;
        line-height: 16px;
    }
    .modal_content_pre_order_color_name span {
        font-size: 12px;
        line-height: 16px;
    }
    .wrapper_for_mini_count_controller_wp {
        margin-left: 0px;
    }
    .wrapper_for_mini_count_controller_wp {
        height: 25px;
        width: 65px;
    }
    .wrapper_for_mini_count_controller.min:before {
        right: 10px;
        top: 10px;
    }
    .wrapper_for_mini_count_controller.min:after {
        right: 10px;
        top: 3px;
    }
    .wrapper_for_mini_count_controller.max:before {
        left: 10px;
        top: 10px;
    }
    .wrapper_for_mini_count_controller.max:after {
        left: 10px;
        top: 3px;
    }
    .modal_content_pre_order_color_name:last-child {
        margin-top: 10px;
    }
    .modal_content_pre_order_img_container {
        height: 130px;
    }
    .order_modal_heading_text {
        font-size: 18px;
    }
    .order_modal_exit_div {
        width: 20px;
        height: 20px;
    }
    .order_modal_exit_div:before {
        width: 15px;
        left: 3px;
        top: 10px;
    }
    .order_modal_exit_div:after {
        width: 15px;
        left: 3px;
        top: 10px;
    }
    .pre_order_fields_wrapper {
        margin-top: 10px;
    }
    .pre_order_fields_wrapper input {
        font-size: 14px;
        line-height: 16px;
    }
    .pre_order_finally_valid_text {
        font-size: 12px;
        height: 30px;
    }

    .back_button_product {
        padding: 10px 20px;
    }


    .slider_product_wrapper {
        padding: 0px 20px;
    }

    .switch_slider.active {
        flex-direction: column;
        border-bottom: unset;
    }
    .title_like {
        margin-top: 10px;
    }
    .title_like_switch, .title_image_switch {
        background: #757575;
    }
    .title_like.active .title_like_switch {
        height: 1px;
    }
    .title_image.active .title_image_switch{
        height: 1px;
    }
    .title_image, .title_like  {
        width: max-content;
    }

    .slider_product_wrapper {
        max-width: 550px;
    }

    .heading_sertificate_modal {
        font-size: 18px;
    }
    .cute_form_wrapper {
        padding: 20px;
    }
    .cute_notificator {
        font-size: 14px;
    }
    .sertificate_close {
        right: -15px;
        top: -20px;
    }

    .one_page_content_selectors_container {
        margin-top: 15px;
    }
    .price_and_shares {
        flex-direction: column;
    }
    .installment_wrapper {
        margin-left: 0px;
        margin-top: 10px;
    }
    .product_page_warning {
        font-size: 12px;
    }
    .installment_price {
        margin-left:0;
        margin-right: auto;
        text-align: left;
    }
    .installment_child {
        flex-direction: column;
    }

}



@media screen and (min-width: 960px){
    .back_button_product_wrapper {
        display: none;
    }
}

.widgetButton{
    position: fixed;
    right: 50px;
    bottom: 10px;
    padding: 15px 20px;
    border-radius: 20px;
    color: #fff;
    background: linear-gradient(90.39deg, #99ABFF -1.58%, #AB8DFF 28.39%, #FFA7DB 88.82%, #FFE4DB 118.15%);
    cursor: pointer;
    z-index: 100;
}

.widgetTarget {
    position: fixed;
    right: 50px;
    bottom: 80px;
    z-index: 10000;
}

@charset "UTF-8";
.snapy {
    --scale: 1px;
    --white: #ffffff;
    --black: #000000;
    --error: #FFDADA;
    --main-black: #171717;
    --icon-black: #1C1B1F;
    --light-grey: #E6E6E6;
    --gradient: linear-gradient(90deg, rgba(68, 188, 255, 0.32) -0.55%, rgba(68, 176, 255, 0.32) 22.86%, rgba(255, 68, 236, 0.32) 48.36%, rgba(255, 68, 236, 0.32) 73.33%, rgba(255, 103, 94, 0.32) 99.34%);
    --gradient-alert: linear-gradient(90deg, #44BCFF -0.55%, #44B0FF 22.86%, #FF44EC 48.36%, #FF44EC 73.33%, #FF675E 99.34%);
    --border-radius: 6px;
    font-family: Arial, Helvetica, sans-serif !important;
    overflow: hidden;
    display: none;
    max-width: 400px;
    min-width: 320px;
    position: relative;
    z-index: 20000;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    color: #000;
    height: 600px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.0980392157);
}
.snapy * {
    user-select: none;
    box-sizing: border-box;
}
.snapy button {
    border: none;
    cursor: pointer;
    background: none;
}
.snapy.is-open {
    display: block;
}
.snapy__content {
    position: absolute;
    top: 0vh;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.snapy__container {
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    color: #000;
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
}
.snapy__close {
    --close-line-width: 16px;
    --close-line-thickness: 2px;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
    color: currentColor;
    transition: opacity 0.3s;
}
.snapy__close::before {
    content: "";
    position: absolute;
    top: 4px;
    left: calc(50% - var(--close-line-width) * 0.5);
    width: var(--close-line-width);
    height: var(--close-line-thickness);
    border-radius: 20px;
    background-color: currentColor;
}
.snapy__close span {
    display: none;
}
.snapy__box {
    flex-grow: 1;
    display: flex;
    width: 100%;
    overflow: visible;
}
.snapy__box--start {
    overflow: hidden;
}
.snapy__box--start .snapy__wrap {
    overflow: hidden;
    position: relative;
}
.snapy__box--start .snapy__main {
    flex-grow: 1;
    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.snapy__box--start .snapy__image {
    /* overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    transition: aspect-ratio 0.3s, border-color 0.3s;
}
.snapy__box--start .snapy__image button {
    margin-top: auto;
}
.snapy__box--start .snapy__image.is-loading {
    pointer-events: none;
}
.snapy__box--choices-photo {
    overflow: hidden;
}
.snapy__box--choices-photo .snapy__wrap {
    flex-grow: 1;
    min-height: unset;
}
.snapy__box--choices-photo .snapy__main {
    flex-grow: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
}
.snapy__box--choices-photo .snapy__image {
    position: relative;
    flex-grow: 1;
}
.snapy__box--choices-photo .snapy__image img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.snapy img.snapy__empty {
    display: none;
    /* height: 100%;
    width: 100%;
    object-fit: cover; */
}
.snapy__empty-help {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    line-height: 1.1;
    font-weight: 500;
    text-align: left;
}
.snapy__empty-help span {
    position: absolute;
    padding: 6px 10px;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5333333333);
    border-radius: 4px;
}
.snapy__empty-help span:nth-of-type(1) {
    max-width: 140px;
    top: 20%;
    right: 10px;
}
.snapy__empty-help span:nth-of-type(2) {
    max-width: 100px;
    top: 40%;
    left: 20px;
}
.snapy__empty-help span:nth-of-type(3) {
    max-width: 120px;
    top: 70%;
    right: 20px;
}
.snapy__wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    text-align: center;
}
.snapy__heading {
    margin-bottom: 14px;
}
.snapy__heading .snapy__icon {
    margin-bottom: 6px;
    line-height: 1;
}
.snapy__title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
}
.snapy__main {
    margin: auto 0;
}
.snapy__controls {
    margin-top: 24px;
}
.snapy__icon {
    margin-bottom: 20px;
}
.snapy__loader {
    margin-top: 20px;
}
.snapy__image {
    line-height: 0;
    position: relative;
}
.snapy__image img {
    display: block;
    border-radius: var(--border-radius);
}
.snapy__text b {
    font-weight: 700;
}
.snapy__tab-container {
    flex-grow: 1;
    display: flex;
}
.snapy__tab-bar-wrapper {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2; */
    flex-shrink: 0;
    padding: 10px 20px 6px 20px;
}
.snapy__tab-header {
    text-align: center;
    font-size: 11px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 10px;
}
.snapy__tab-header a {
    color: #555;
}
.snapy__tab-bar {
    display: flex;
    padding: 12px 0;
}
.snapy__tab-btn .icon-shadow--alert {
    margin-left: 4px;
    display: none;
}
.snapy__tab-btn.is-notice .icon-shadow--alert {
    display: flex;
}
.snapy__tab-block {
    display: none;
    width: 100%;
}
.snapy__tab-block--room {
    align-items: flex-start;
}
.snapy__tab-block--room .snapy__list {
    flex-wrap: wrap;
}
.snapy__tab-block--room .snapy__item {
    height: 220px;
}
.snapy__tab-block.is-active {
    display: flex;
}
.snapy__list {
    width: 100%;
    --gap: 8px;
    display: flex;
    flex-flow: row wrap;
    gap: var(--gap);
}
.snapy__list::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-radius: 0px;
}
.snapy__list::-webkit-scrollbar-track {
    background: transparent;
}
.snapy__list::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 0px;
}
.snapy__item {
    cursor: pointer;
    position: relative;
    width: calc(50% - var(--gap) / 2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: border 0.3s;
    border: 2px solid #fff;
    background-color: #eee;
    /* &:only-child {
        aspect-ratio: 1 / 1; // TODO: для чего это?
    } */
}
.snapy__item .snapy-loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
}
.snapy__item.is-loading .snapy-loader {
    opacity: 1;
}
.snapy__item.is-loading .snapy__new-snap {
    opacity: 0;
}
.snapy__item .snapy__new-snap {
    display: none;
}
.snapy__item--new {
    border: 2px solid #aaa;
}
.snapy__item--new .snapy__new-snap {
    display: flex;
}
.snapy__item--new .snapy__image-card {
    display: none;
}
.snapy__new-snap {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 60px;
    font-weight: 300;
    color: #000;
    line-height: 1;
    flex-shrink: 0;
    flex-grow: 1;
    padding: 4px;
    transition: opacity 0.3s;
}
.snapy__new-snap img {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.snapy__new-snap-img {
    position: relative;
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 3px;
    overflow: hidden;
}
.snapy__new-snap-img img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.snapy__new-snap-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    min-height: 40px;
    background-color: var(--main-black);
    color: var(--white);
    border-radius: var(--border-radius);
    background-color: #000;
}
.snapy__image-card {
    line-height: 0;
    height: 100%;
    aspect-ratio: 4/7;
}
.snapy__image-card img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.snapy__profile {
    width: 100%;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
}
.snapy__profile .snapy__image {
    flex-grow: 1;
    position: relative;
}
.snapy__profile .snapy__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.snapy__previews {
    margin-top: 12px;
    display: flex;
    flex-flow: row nowrap;
}
.snapy__preview {
    margin-left: 12px;
    width: 33%;
    line-height: 0;
}
.snapy__preview:first-child {
    margin-left: 0;
}
.snapy__preview-btn {
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: opacity 0.3s;
}
.snapy__preview-btn.is-empty {
    display: none;
}
.snapy__preview-btn img {
    /* position: absolute;
    top: 0;
    left: 50%; */
    width: 100%;
    height: 100%;
    /* transform: translateX(-50%);
    max-width: unset;
    height: auto; */
    object-fit: cover;
    object-position: top;
}
.snapy__preview-btn::before, .snapy__preview-btn::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    border-radius: inherit;
}
.snapy__preview-btn::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 0 2px transparent;
    transition: box-shadow 0.3s;
}
.snapy__preview-btn::after {
    left: 50%;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.832031 0.5H24.832V24.5H0.832031V0.5Z' fill='black'/%3e%3cpath d='M10.382 15.65L18.857 7.175C19.057 6.975 19.2904 6.875 19.557 6.875C19.8237 6.875 20.057 6.975 20.257 7.175C20.457 7.375 20.557 7.6125 20.557 7.8875C20.557 8.1625 20.457 8.4 20.257 8.6L11.082 17.8C10.882 18 10.6487 18.1 10.382 18.1C10.1154 18.1 9.88203 18 9.68203 17.8L5.38203 13.5C5.18203 13.3 5.0862 13.0625 5.09453 12.7875C5.10286 12.5125 5.20703 12.275 5.40703 12.075C5.60703 11.875 5.84453 11.775 6.11953 11.775C6.39453 11.775 6.63203 11.875 6.83203 12.075L10.382 15.65Z' fill='white'/%3e%3c/svg%3e ");
    opacity: 0;
    transition: opacity 0.3s;
}
.snapy__preview-btn svg {
    display: none;
    margin: auto;
}
.snapy__preview-btn.is-add-new {
    color: var(--icon-black);
    display: block !important;
}
.snapy__preview-btn.is-add-new img {
    display: none;
}
.snapy__preview-btn.is-add-new svg {
    display: block;
}
.snapy__preview-btn.is-add-new::before {
    box-shadow: none;
    border: 1px dashed var(--black);
}
.snapy__preview-btn.is-active::before {
    box-shadow: inset 0 0 0 2px var(--black);
}
.snapy__btn-icon {
    position: absolute;
    bottom: 12px;
    right: 12px;
}
.snapy__subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-top: 12px;
}
.snapy__file {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    background-color: #aaa;
    cursor: pointer;
    border: none;
}
.snapy__upload-error {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    background-color: #fff;
    font-size: 11px;
    margin-top: 10px;
    text-align: center;
    color: #f00;
}
.snapy__upload-error:empty {
    margin-top: 0;
}
.snapy__upload-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    margin-bottom: 10px;
}
.snapy__upload-container img {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: translateX(-50%);
}
.snapy__upload-preview {
    display: none !important;
}
.snapy__delete-all {
    opacity: 0.5;
    font-size: 10px;
    margin-top: 14px;
}

@media (hover: hover) {
    .snapy[data-state=add-photo] .snapy__box--start .snapy__file:hover ~ button {
        opacity: 0.8;
    }
    .snapy__item:hover {
        border-color: #000;
    }
    .snapy__close:hover {
        opacity: 0.6;
    }
    .snapy__button:hover, .snapy__preview-btn:hover {
        opacity: 0.8;
    }
    .snapy__upload:hover {
        border-color: #5669f9;
    }
}
/* NEW STATES */
.snapy[data-state=add-photo] .snapy__empty {
    display: block !important;
}
.snapy[data-state=add-photo] .snapy__empty-help {
    display: block;
}
.snapy[data-state=add-photo] .snapy__box:not(.snapy__box--start) {
    display: none;
}
.snapy[data-state=add-photo] .snapy__box--start button span:last-child {
    display: none;
}
.snapy[data-state=confirm] .snapy__upload-preview {
    display: block !important;
}
.snapy[data-state=confirm] .snapy__upload-container {
    display: flex;
}
.snapy[data-state=confirm] .snapy__box:not(.snapy__box--start) {
    display: none;
}
.snapy[data-state=confirm] .snapy__box--start button {
    position: relative;
    z-index: 4;
}
.snapy[data-state=confirm] .snapy__box--start button span:first-child {
    display: none;
}
.snapy[data-state=confirm] .snapy__box--start .snapy__file {
    bottom: 50px;
}
.snapy[data-state=checking] .snapy__box:not(.snapy__box--checking) {
    display: none;
}
.snapy[data-state=checking] .snapy__box--checking .snapy__title span:not(:nth-child(1)) {
    display: none;
}
.snapy[data-state=checking] .snapy__box--checking .snapy__icon svg:not(:nth-child(1)) {
    display: none;
}
.snapy[data-state=checking] .snapy__box--checking .snapy__text span:not(:nth-child(1)) {
    display: none;
}
.snapy[data-state=checking] .snapy__box--checking .snapy__button {
    display: none;
}
.snapy[data-state=check-error] .snapy__box:not(.snapy__box--checking) {
    display: none;
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__title span:not(:nth-child(2)) {
    display: none;
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__icon {
    border-radius: 12px;
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__icon::after {
    background-image: none;
    background-color: var(--error);
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__icon svg:not(:nth-child(2)) {
    display: none;
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__text span:not(:nth-child(2)) {
    display: none;
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__button {
    display: block;
}
.snapy[data-state=check-error] .snapy__box--checking .snapy__loader {
    display: none;
}
.snapy[data-state=choices-photo] .snapy {
    /* &__tab-bar-wrapper {
        display: block;
    } */
}
.snapy[data-state=choices-photo] .snapy__box:not(.snapy__box--choices-photo) {
    display: none;
}
.snapy[data-state=choices-photo] .snapy__preview-btn.is-active::after {
    opacity: 1;
}
.snapy[data-state=generation] .snapy__box:not(.snapy__box--generation) {
    display: none;
}
.snapy[data-state=main] .snapy {
    /* &__tab-bar-wrapper {
        display: block;
    } */
}
.snapy[data-state=main] .snapy__box:not(.snapy__box--main) {
    display: none;
}

button.snapy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    background-color: var(--main-black);
    color: var(--white);
    border-radius: var(--border-radius);
    width: 100%;
    min-height: 40px;
    transition: opacity 0.3s;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    cursor: pointer;
}
button.snapy-button--shadow {
    padding: 10px 15px;
    background-color: transparent;
    color: var(--main-black);
    position: relative;
    z-index: 1;
}
button.snapy-button--shadow::before, button.snapy-button--shadow::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 0.3s;
}
button.snapy-button--shadow::before {
    background-color: var(--white);
    z-index: 1;
}
button.snapy-button--shadow::after {
    background-image: var(--gradient);
    --webkit-filter: blur(6px);
    filter: blur(6px);
    z-index: -1;
}
button.snapy-button--shadow > * {
    position: relative;
    z-index: 1;
}
button.snapy-button--shadow.is-active::before, button.snapy-button--shadow.is-active::after {
    opacity: 1;
}
button.snapy-button--border {
    color: #000;
    border: 1px solid #000;
    background: none;
}
button.snapy-button--icon {
    width: auto;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.16);
    color: var(--icon-black);
}

@media (hover: hover) {
    .snapy-button:hover {
        opacity: 0.8;
    }
}
/* .glow-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	padding: 8px;
	color: var(--icon-black);
	background-color: var(--white);
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		background-image: var(--gradient);
		background-position: 50% 50%;
		--webkit-filter: blur(6px);
		filter: blur(6px);
		border-radius: 6px;
	}
} */
.snapy-popup {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s;
    z-index: 99;
}
.snapy-popup--dialogue {
    z-index: 102;
}
.snapy-popup.is-open {
    opacity: 1;
    pointer-events: auto;
}
.snapy-popup__close {
    position: absolute;
    right: 12px;
    top: 12px;
    line-height: 0;
    z-index: 1;
    transition: opacity 0.3s;
}
.snapy-popup__box {
    position: relative;
    width: 100%;
    margin: auto;
    height: auto;
    background-color: var(--white);
    border-radius: var(--border-radius);
    padding: 24px 20px;
    max-width: 280px;
}
.snapy-popup__main {
    text-align: center;
}
.snapy-popup__icon {
    margin-bottom: 20px;
}
.snapy-popup__title {
    margin-bottom: 8px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
}
.snapy-popup__text {
    font-size: 15px;
}
.snapy-popup__controls {
    --gap: 8px;
    margin-top: 20px;
    display: flex;
    gap: var(--gap);
}
.snapy-popup__button {
    width: calc(50% - var(--gap) / 2);
}
.snapy-popup--full {
    background-color: transparent;
}
.snapy-popup--full .snapy-popup__box {
    margin: 0;
    height: 100%;
    max-width: 100%;
    padding: 0;
}
.snapy-popup--full .snapy-popup__main {
    height: 100%;
}

@media (hover: hover) {
    .snapy-popup__close:hover {
        opacity: 0.8;
    }
}
.snapy-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.snapy-loader__circle {
    border-radius: 50%;
    background-color: var(--main-black);
    width: 8px;
    height: 8px;
    position: relative;
    transform-origin: center;
    transition: transform;
    animation: circleMorph 2s infinite;
}
.snapy-loader__circle::before {
    content: "";
    display: block;
    border-radius: inherit;
    width: 4px;
    height: 4px;
    background-color: var(--white);
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform;
    animation: innerMorph 2s infinite;
}
.snapy-loader__circle:last-child {
    animation: circleMorphReverse 2s infinite;
}
.snapy-loader__circle:last-child::before {
    animation: innerMorphReverse 2s infinite;
}

@keyframes circleMorph {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}
@keyframes circleMorphReverse {
    0%, 100% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(1);
    }
}
@keyframes innerMorph {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(0);
    }
}
@keyframes innerMorphReverse {
    0%, 100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
.snapy-slider {
    position: relative;
    height: 100%;
}
.snapy-slider__list {
    height: 100%;
}
.snapy-slider__item {
    position: relative;
    height: 100%;
}
.snapy-slider__item .snapy__btn-icon {
    left: 12px;
    right: auto;
}
.snapy-slider__item img {
    border-radius: var(--border-radius);
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.snapy-slider__item .snapy-button {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.snapy-slider__download {
    position: absolute;
    right: 12px;
    bottom: 12px;
}
.snapy-slider__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
    transition: opacity 0.8s;
}
.snapy-slider__arrow--prev {
    margin-left: 16px;
    left: 0;
}
.snapy-slider__arrow--next {
    margin-right: 16px;
    right: 0;
}
.snapy-slider__arrow.is-hidden {
    display: none;
}

@media (hover: hover) {
    .snapy-slider__arrow:hover {
        opacity: 0.7;
    }
}